import { useEffect, useState } from 'react';
import { getAdvisorLastViewedAt } from '@/domains/core/advisor/api/lastview';

const LOCAL_STORAGE_KEY = 'careerAdvisorLastViewedAt';

export const useLastViewedAt = () => {
  const [lastViewedAt, setLastViewedAt] = useState<Date | null>(null);

  const loadLastViewedAt = async () => {
    const localStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (localStorageValue) {
      setLastViewedAt(new Date(localStorageValue));
      return;
    }

    const apiValue = await getAdvisorLastViewedAt();
    const apiDate = new Date(apiValue);
    setLastViewedAt(apiDate);
    localStorage.setItem(LOCAL_STORAGE_KEY, apiDate.toISOString());
  };

  useEffect(() => {
    loadLastViewedAt();
  }, []);

  return lastViewedAt;
};
