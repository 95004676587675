import { useState, useRef } from 'react';
import { Toast as ToastType } from '../types';

import Confetti from '../../confetti/confetti';

import './toast.scss';

export interface ToastItemProps {
  toast: ToastType;
  onClose: () => void;
}

const toastIcons: { [key: string]: string } = {
  success: 'bi bi-check-circle-fill',
  warning: 'bi bi-exclamation-triangle-fill',
  error: 'bi bi-exclamation-circle-fill',
  info: 'bi bi-info-circle-fill',
  quest: 'bi bi-rocket-takeoff',
  completed: 'bi bi-rocket-takeoff',
};
const TOAST_CLOSING_ANIMATION_DURATION = 630;

function Toast({
  toast, onClose,
}: ToastItemProps) {
  const [isActive, setIsActive] = useState(true);
  const parentRef = useRef(null);
  const handleOnClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsActive(false);
    setTimeout(() => {
      onClose();
    }, TOAST_CLOSING_ANIMATION_DURATION);
  };

  return (
    <div className={`toast ${isActive ? 'toast--active' : ''} ${toast.handleClick ? 'toast--clickable' : ''}`} ref={parentRef} onClick={toast.handleClick}>
      {toast.type === 'completed' && <Confetti fireCount={1} />}
      <div className="toast__button-close" onClick={handleOnClose}>&times;</div>
      <div className="toast__message">
        {toast.type in toastIcons && (<i className={`toast__icon toast__icon--${toast.type} ${toastIcons[toast.type]}`} />)}
        <p className="toast__title">{toast.message}</p>
        {toast.additionalMessage && (
          <div className="toast__additional-message">
            <p>{toast.additionalMessage}</p>
          </div>
        )}
      </div>
      <div className={`toast__progress-bar ${toast.autoClose ? `toast__progress-bar--active toast__progress-bar--mode-${toast.type}` : ''}`} />
    </div>
  );
}

export default Toast;
