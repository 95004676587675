import { useAuth0 } from '@auth0/auth0-react';
import {
  lazy,
  Suspense,
  useContext,
  useDeferredValue, useEffect, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { TableRow } from '@/components/table/table-types';

import './styles/contacts.scss';
import { useSelf } from '@/services/queries/user';
import { Contact, ContactApiStatus, ContactWithNetworkingData } from '@/domains/core/contact/types';
import { useNetworkingStatuses } from '@/services/hooks/use-networking-statuses';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { updateContactPositionInKanban } from '@/services/api/contact';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useGetContactsNoCache } from '@/services/queries/contact';
import { Loader } from '@/components/loader/loader';
import {
  contactAPIstatuses,
  contactKanbanColumns,
  contactKanbanStatuses,
  ContactStatus,
  filterPresavedContacts,
  getContactsKanbanLayout,
} from '@/domains/core/netwoking/constants/contacts-kanban';
import {
  ContactsTableColumns,
  ContactsTableColumnsWithoutStatuses, mapContacts,
} from '@/domains/core/netwoking/constants/contact-table';
import { ModalContext } from '@/components/modal/modal-provider';
import { ArchiveContactModal } from '@/domains/core/netwoking/components';
import { ContactsOverviewContent } from '@/domains/core/contact/components/contacts-kanban-container/contacts-kanban-container';

type ColumnName = typeof contactKanbanColumns[number];

type KanbanBoard = {
  [K in ColumnName]: ContactWithNetworkingData[];
};

const LazyEmptyContacts = lazy(() => import('@/domains/core/contact/components/empty-contacts-list/empty-contacts-list'));

const ContactsPage = () => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { trackEvent } = useAnalytics();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { data: contacts, isLoading: areContactsLoading, isRefetching: isRefetchingContacts } = useGetContactsNoCache();
  const [preparedContacts, setPreparedContacts] = useState<ContactWithNetworkingData[]>([]);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { addToast }: AddToastType = useContext(ToastContext);
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { data: self } = useSelf();
  const deferredQuery = useDeferredValue(searchQuery);
  const { mapAllConnectionStatuses, isLoading } = useNetworkingStatuses();
  const [kanbanLayout, setKanbanLayout] = useState<KanbanBoard | null>(null);
  const [nonPresavedContacts, setNonPresavedContacts] = useState<Contact[]>(filterPresavedContacts(contacts));

  const computeKanbanLayout = (allContacts: Contact[]) => {
    setKanbanLayout(getContactsKanbanLayout(allContacts));
  };

  const trackStatusChange = (status: ContactStatus, newStatus: ContactStatus) => {
    if (status === newStatus) {
      return;
    }

    if (newStatus !== 'Contact Saved') {
      trackEvent(`Kanban Contact moved to ${newStatus}`, user);
    }
  };

  const updateTilePositionOnKanban = (id: string, currentStatus: ContactStatus, newStatus: ContactStatus, position: number) => {
    const newKanban = { ...kanbanLayout };

    const contactCard = newKanban[currentStatus]?.find((contact: any) => contact.id === id);

    if (!contactCard) {
      return;
    }

    newKanban[currentStatus] = newKanban[currentStatus].filter((contact: any) => contact.id !== id);

    newKanban[newStatus].splice(position, 0, contactCard);

    setKanbanLayout(newKanban);
    trackStatusChange(currentStatus, newStatus);
    const droppedColumn = contactKanbanStatuses[newStatus];
    contactCard.kanban_position.status = droppedColumn;
  };

  const updatePreparedContactsAfterArchive = (id: string, newStatus: ContactApiStatus, dropPosition: number) => {
    setPreparedContacts(prevValue => prevValue.map((contact) => (contact.id === id ? ({ ...contact, kanban_position: { status: newStatus, row: dropPosition } }) : contact)));
  };

  const handleStatusUpdateResponse = (isSuccess: boolean, response: any, id: string, newStatus: ContactApiStatus, oldStatus: ContactStatus, oldKanban: any, dropPosition: number) => {
    const successToastMessage = newStatus === 'archive' ? 'Contact archived' : 'Contact status updated';
    const successAdditionalMessage = newStatus === 'archive' ? 'The contact has been successfully archived.' : 'The contact status has been successfully updated.';
    const errorToastMessage = newStatus === 'archive' ? 'Failed to archive contact' : 'Failed to update contact status';

    if (isSuccess) {
      addToast({
        type: 'success',
        message: successToastMessage,
        additionalMessage: successAdditionalMessage,
      });

      if (oldStatus === 'Archived' || newStatus === 'archive') {
        updatePreparedContactsAfterArchive(id, newStatus, dropPosition);
      }
    } else {
      addToast({
        type: 'error',
        message: errorToastMessage,
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update contact status',
        error: response?.error,
      });

      oldKanban[newStatus] = oldKanban[newStatus].filter((contact: any) => contact.id !== id);
      setKanbanLayout(oldKanban);
    }
  };

  const handleContactNewStatus = async (cardInfo: {
    id: string;
    status: ContactStatus;
    dropPosition: number;
  }, newStatus: ContactStatus) => {
    const { id, status, dropPosition } = cardInfo;
    const oldKanban = { ...kanbanLayout };

    updateTilePositionOnKanban(id, status, newStatus, dropPosition);
    trackStatusChange(status, newStatus);
    const droppedColumn = contactKanbanStatuses[newStatus];

    const response = await updateContactPositionInKanban(id, droppedColumn, dropPosition);

    handleStatusUpdateResponse(response && response.message === 'The position has been updated', response, id, droppedColumn, status, oldKanban, dropPosition);
    await queryClient.invalidateQueries(['applications']);
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  const archiveContact = async (id: string, currentStatus: ContactStatus, position: number, newStatus: ContactStatus) => {
    const oldKanban: any = { ...kanbanLayout };

    updateTilePositionOnKanban(id, currentStatus, newStatus, position);
    const droppedColumn = contactKanbanStatuses[newStatus];

    const response = await updateContactPositionInKanban(id, droppedColumn, position);
    handleStatusUpdateResponse(response && response.message === 'The position has been updated', response, id, droppedColumn, currentStatus, oldKanban, position);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  const handleArchiveContactConfirmation = (id: string, currentStatus: ContactStatus, position: number, rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveContact', 'true');
    }
    closeModal();
    archiveContact(id, currentStatus, position, 'Archived');
  };

  const requestArchiveConfirmation = (id: string, currentStatus: ContactStatus, position: number = 0) => {
    const rememberAction = localStorage.getItem('rememberArchiveContact');

    if (!rememberAction) {
      openModal(
        <ArchiveContactModal
          handleArchiveContact={(remember) => handleArchiveContactConfirmation(id, currentStatus, position, remember)}
          closeModal={closeModal}
        />,
      );
    } else {
      handleArchiveContactConfirmation(id, currentStatus, position, false);
    }
  };

  const handleCardStatusChange = async (cardInfo: {
    id: string;
    status: string;
    dropPosition: number;
  }, newStatus: string, archiveActionConfirmed: boolean = false) => {
    const { id, status, dropPosition } = cardInfo;

    if (status !== newStatus && newStatus === 'Archived' && !archiveActionConfirmed) {
      requestArchiveConfirmation(id, status, dropPosition);
      return;
    }

    handleContactNewStatus(cardInfo, newStatus);
  };

  const filterContacts = (queryString: string, contactsToFilter: ContactWithNetworkingData[] | undefined) => {
    const filteredResults = Array.isArray(contactsToFilter) ? contactsToFilter?.filter((contact) => {
      const fullName = `${contact.first_name} ${contact.last_name}`;
      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.company.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }) : [];
    const filteredNonArchived = filteredResults?.filter((contact) => contact.kanban_position.status !== 'archive');

    setTableData(mapContacts(filteredNonArchived || [], openTracker, requestArchiveConfirmation));
    computeKanbanLayout(filteredResults || []);
  };

  const processContactAction = (id: string, currentStatus: ContactApiStatus, isArchiveAction: boolean) => {
    if (isArchiveAction) {
      requestArchiveConfirmation(id, contactAPIstatuses[currentStatus]);
    } else {
      handleCardStatusChange({ id, status: contactAPIstatuses[currentStatus], dropPosition: 0 }, 'Contact Saved');
    }
  };

  const processContactsWithStatuses = async (allContacts: Contact[]) => {
    if (!self?.id) {
      return;
    }

    const contactsWithStatus = await mapAllConnectionStatuses(allContacts, self?.id);
    setPreparedContacts(contactsWithStatus);
  };

  useEffect(() => {
    const contactsToFilter = preparedContacts.length ? preparedContacts : contacts;
    if (deferredQuery) {
      filterContacts(deferredQuery.trim(), contactsToFilter);
    } else {
      filterContacts('', contactsToFilter);
    }
  }, [deferredQuery]);

  useEffect(() => {
    if (preparedContacts.length) {
      filterContacts(searchQuery, preparedContacts);
    }
  }, [preparedContacts]);

  useEffect(() => {
    if (self && !areContactsLoading && !isRefetchingContacts && contacts) {
      setNonPresavedContacts(filterPresavedContacts(contacts));
      filterContacts(searchQuery, contacts);
      processContactsWithStatuses(contacts);
    }
  }, [contacts?.length, self?.id, areContactsLoading, isRefetchingContacts]);

  if (areContactsLoading || !kanbanLayout) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="contacts-page" id="main">
      {Array.isArray(contacts) && kanbanLayout && nonPresavedContacts.length > 0 ? (
        <ContactsOverviewContent
          columns={contactKanbanColumns}
          layout={kanbanLayout}
          tableColumns={isLoading ? ContactsTableColumnsWithoutStatuses : ContactsTableColumns}
          handleSearchQueryChange={setSearchQuery}
          searchQuery={searchQuery}
          tableData={tableData}
          handleContactArchive={processContactAction}
          cardChangeHandler={handleCardStatusChange}
          isLoading={isLoading}
        />
      ) : (
        <Suspense fallback={<div id="loader"><Loader /></div>}>
          <LazyEmptyContacts />
        </Suspense>
      )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(ContactsPage);
