import './suggested-contacts-container.scss';
import { useNavigate } from 'react-router-dom';
import { SuggestedContactsEmpty } from '../suggested-contacts-empty/suggested-contacts-empty';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import { SuggestedContact } from '../suggested-contact/suggested-contact';
import { useSuggestedContacts } from '@/services/queries/contact';
import { Loader } from '@/components/loader/loader';

import type { SuggestedContactType } from '@/domains/core/contact/types/contact';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { saveContact } from '@/services/api/contact';
import { useRecombee } from '@/services/hooks/use-recombee';
import { useAnalytics } from '@/services/hooks/use-analytics';

export const SuggestedContactsContainer = () => {
  const { data: suggestedContacts, isLoading } = useSuggestedContacts();
  const { data: selfUniversity, isLoading: isSelfLoading } = useSelfUniversity();
  const { data: self } = useSelf();
  const { trackEvent } = useAnalytics();
  const { addBookmark, ITEM_TYPES } = useRecombee();

  const navigate = useNavigate();

  const handleReachOut = async (id: string) => {
    const suggestedContact = suggestedContacts?.find((contact: SuggestedContactType) => contact.contact_id === id);
    await saveContact(suggestedContact, suggestedContact.company_id);

    trackEvent('Saved Contact', {}, {
      source: 'Suggested Contacts Homepage',
    });

    if (self?.id) {
      await addBookmark(
        self.id,
        ITEM_TYPES.CONTACT,
        id,
        {
          company_name: suggestedContact.contact.current_position.company,
          job_title: suggestedContact.contact.current_position.title,
          languages: suggestedContact.raw_data.languages,
        },
      );
    }
    navigate(`/app/inbox/${suggestedContact.contact_id}`);
  };

  if (isLoading || isSelfLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }
  return (
    <div className="suggested-contacts-container">
      <div className="suggested-contacts-container__header">
        <h3 className="suggested-contacts-container__title">
          Suggested Contacts
        </h3>
        <TooltipIcon
          tooltipLabel="We found these based on your profile"
          tooltipPosition="top"
        />
      </div>
      {Array.isArray(suggestedContacts) && suggestedContacts.length > 0 ? (
        <div className="suggested-contacts-container__scroll-container">
          <div className="suggested-contacts-container__list">
            {Array.isArray(suggestedContacts) && suggestedContacts.map((suggestedContact: SuggestedContactType) => (
              <SuggestedContact
                key={suggestedContact.contact_id}
                id={suggestedContact.contact_id}
                name={`${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`}
                position={suggestedContact.contact.current_position.title}
                image={suggestedContact.contact.career_summary.basics.image}
                languages={suggestedContact.raw_data.languages}
                isAlumni={suggestedContact.match_criteria.includes('alumni')}
                companyLogo={suggestedContact.contact.current_position.company_logo_url}
                companyName={suggestedContact.contact.current_position.company}
                schoolName={selfUniversity?.name || ''}
                networkingStatusRate={suggestedContact.match_criteria.includes('desired_job') ? 1 : 0}
                handleMainButtonAction={(id) => handleReachOut(id)}
              />
            ))}
          </div>
        </div>
      ) : <SuggestedContactsEmpty />}

    </div>
  );
};
