import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@/components/loader/loader';

import '../styles/dashboard.scss';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { useAnalytics } from '@/services/hooks/use-analytics';

function WebinarPage() {
  const { webinarID } = useParams();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (user?.email && webinarID) {
      trackEvent('Attended Webinar', user, { webinarID });
    }
  }, [webinarID, user]);

  return (
    <div className="student-dashboard student-dashboard--form">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="webinar-wrapper webinar-wrapper--learning">
            <div className="webinar-container">
              <iframe
                src={`https://streamyard.com/e/${webinarID}`}
                className="webinar-iframe"
                allow="autoplay; fullscreen"
                title="Webinar"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(WebinarPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
