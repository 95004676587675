import {
  useState, useRef, useContext, useEffect,
} from 'react';
import DOMPurify from 'dompurify';
import { IconButton, Tooltip } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { InfoModal } from '@/domains/generic/modals';
import { Note } from '../../domains/core/company/types';
import { NoteInput } from '../note-input/note-input';
import './note-card.scss';
import { DefaultLogo } from '../default-logo/default-logo';

interface NoteProps {
  parentID: string;
  note: Note;
  handleDelete: (id: string) => void;
  handleEdit: (id: string, title: string, content: string, successCallback: () => void) => void;
}

export default function NoteCard({
  parentID,
  note,
  handleDelete,
  handleEdit,
}: NoteProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [isExtendable, setIsExtendable] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const titleRef = useRef<HTMLHeadingElement>(null);
  const noteTextRef = useRef<HTMLDivElement>(null);

  const editNote = () => {
    setIsEditing(true);
    setIsExtendable(false);
  };

  const toggleEditNode = () => {
    setIsEditing(false);
  };

  const handleNoteDelete = async (id: string) => {
    closeModal();
    handleDelete(id);
  };

  const handleDeleteNoteClick = (id: string) => {
    openModal(
      <InfoModal
        icon="bi bi-exclamation-diamond"
        title="Are you sure you want to delete this note?"
        buttonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        handleButtonClick={() => handleNoteDelete(id)}
        handleSecondaryButtonClick={closeModal}
        description="This action will delete it permanently."
        orientation="horizontal"
      />,
    );
  };

  const checkContentLength = () => {
    if (noteTextRef.current) {
      const { offsetHeight, scrollHeight } = noteTextRef.current;
      setIsExtendable(scrollHeight > offsetHeight);
    }
  };

  const handleUpdateNote = async (title: string, content: string, successCallback: () => void) => {
    toggleEditNode();
    closeModal();
    handleEdit(note.id, title, content, successCallback);
    checkContentLength();
  };

  const updateSilently = async (title: string, content: string) => {
    if (!title.trim().length || !content.trim().length) {
      return;
    }

    handleUpdateNote(title, content, () => {});
  };

  const tryUpdateNote = (title: string, content: string, successCallback: () => void) => {
    openModal(
      <InfoModal
        icon="bi bi-exclamation-diamond"
        title="Are you sure you want to update this note?"
        buttonLabel="Confirm"
        secondaryButtonLabel="Cancel"
        handleButtonClick={() => handleUpdateNote(title, content, successCallback)}
        handleSecondaryButtonClick={closeModal}
        description="Your previous version will be overwritten."
        orientation="horizontal"
      />,
    );
  };

  const toggleOpenCloseState = () => {
    if (!isExtendable && !isOpen) return;
    setIsOpen(!isOpen);
  };

  const handleTitleOverflow = () => {
    setIsOverflow(true);
  };

  const checkTitleOverflow = () => {
    const titleWidth = titleRef.current?.offsetWidth;
    const titleScrollWidth = titleRef.current?.scrollWidth;

    if (titleWidth != null && titleScrollWidth != null && titleScrollWidth > titleWidth) {
      handleTitleOverflow();
    } else {
      setIsOverflow(false);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkTitleOverflow);
    if (titleRef.current) {
      resizeObserver.observe(titleRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [titleRef.current]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkContentLength);

    if (noteTextRef.current) {
      resizeObserver.observe(noteTextRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [note.content, noteTextRef.current]);

  return (
    <Tooltip label="You cannot edit or delete notes that were not created by you." position="top" disableHoverListener={note.is_my_note || !!note.company_id || !!note.contact_id}>
      {isEditing ? (
        <NoteInput
          id={note.id}
          title={note.title}
          content={note.content}
          handleSave={tryUpdateNote}
          handleCancel={toggleEditNode}
          saveSilently={updateSilently}
          parentID={parentID}
        />
      ) : (
        <div className={`note ${note.is_ai_generated ? 'note--ai-generated' : ''}`}>
          <div className="note__header" onClick={toggleOpenCloseState}>
            {(isExtendable || isOpen) && (
              <div className="note__toggle">
                <i className={isOpen ? 'bi bi-caret-down-fill' : 'bi bi-caret-right-fill'} />
              </div>
            )}
            <div className="note__title-wrapper">
              <Tooltip label={note.title} position="top" withArrow disableHoverListener={!isOverflow}>
                <h4
                  ref={titleRef}
                  className={['note__title', !isExtendable && !isOpen ? 'note__title--without-toggle' : ''].join(' ')}
                >
                  {note.title}
                </h4>
              </Tooltip>
            </div>
            {note.advisor && (
              <div className="note__advisor-wrapper">
                <DefaultLogo
                  type="contact"
                  source={note.advisor?.profile_picture_url || ''}
                  name={`${note.advisor?.first_name} ${note.advisor?.last_name}`}
                  className="note__advisor-logo"
                />
                <div className="note__advisor-name">{`${note.advisor?.first_name} ${note.advisor?.last_name}`}</div>
              </div>
            )}
            <span className="note__date">
              {`Last edited: ${formatDateDeltaFromNow(note.updated_at)}`}
            </span>
          </div>
          <div
            ref={noteTextRef}
            className={!isOpen ? 'note__text' : 'note__text note__text--active'}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(note.content) }}
          />
          {(note.is_my_note || note.company_id || note.contact_id) && (
            <div className={`note__actions-list ${isOpen ? '' : 'note__actions-list--invisible'}`}>
              <Tooltip label="Delete" withArrow position="top">
                <IconButton
                  mode="rounded"
                  size="medium"
                  icon="bi bi-trash3"
                  outlined
                  isDangerous
                  handleClick={() => handleDeleteNoteClick(note.id)}
                />
              </Tooltip>
              {!note.is_ai_generated && (
                <Tooltip label="Edit" withArrow position="top">
                  <IconButton
                    mode="rounded"
                    size="medium"
                    icon="bi bi-pencil"
                    outlined
                    handleClick={editNote}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
      )}
    </Tooltip>
  );
}
