import {
  useCallback, useContext, useState,
} from 'react';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { sendMassEmail } from '@/domains/core/advisor/api/student';
import {
  KanbanBoardLayout, MiniAdvisor, StudentModel, StudentStatus,
} from '@/domains/core/advisor/types';
import { AdvisorKanbanBoard } from '../../components/kanban-board/kanban-board';
import { EmailBox } from '../../components/email-box/email-box';

type StudentKanbanProps = {
  columns: StudentStatus[];
  studentsData: StudentModel[];
  universityID: string;
  allAdvisors: MiniAdvisor[];
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  showSendButton?: boolean;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

const StudentKanban = ({
  columns,
  studentsData,
  universityID,
  allAdvisors,
  scrollContainerRef,
  onScroll,
  showSendButton,
}: StudentKanbanProps) => {
  const [isEmailBoxOpened, setIsEmailBoxOpened] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);
  const [sendMessageToColumn, setSendMessageToColumn] = useState<StudentStatus | null>(null);
  const transformDataToKanban = useCallback((s: StudentModel[]): KanbanBoardLayout => {
    const kanbanBoard = new Map<StudentStatus, StudentModel[]>();
    const sortedStudents = s.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName) || a.email.localeCompare(b.email));

    columns.forEach((column) => {
      const studentsPerColumn = sortedStudents.filter((student) => student.status === column);

      kanbanBoard.set(column, studentsPerColumn);
    });

    return kanbanBoard;
  }, [studentsData]);

  const handleSendMessagePerColumn = (column: StudentStatus) => {
    setIsEmailBoxOpened(true);
    setSendMessageToColumn(column);
  };

  const sendEmail = async (message: string, subject: string) => {
    addToast(
      {
        type: 'success',
        message: 'Success',
        additionalMessage: 'Your email has been sent.',
      },
    );
    const columnEmails = sendMessageToColumn ? studentsData.filter((student) => student.status === sendMessageToColumn).map((student) => student.email) : [];

    await sendMassEmail(message, subject, columnEmails);
    setIsEmailBoxOpened(false);
  };

  return (
    <>
      <AdvisorKanbanBoard
        messageButtonTooltip="Send email to all students in this category"
        sendMessagePerColumn={(column) => handleSendMessagePerColumn(column)}
        warningColumns={['Inactive']}
        layout={transformDataToKanban(studentsData)}
        universityId={universityID}
        allAdvisors={allAdvisors}
        scrollContainerRef={scrollContainerRef}
        onScroll={onScroll}
        showSendButton={showSendButton}
      />

      {isEmailBoxOpened && (
        <EmailBox
          handleSend={sendEmail}
          setIsBoxOpened={setIsEmailBoxOpened}
          column={sendMessageToColumn}
          showAITemplates
        />
      )}
    </>
  );
};

export default StudentKanban;
