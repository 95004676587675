import './anchor-button.scss';

type Props = {
  handleClick: () => void;
  text: string;
  icon: string;
};

export const AnchorButton = ({ handleClick, text, icon }: Props) => (
  <button type="button" className="anchor-button" onClick={handleClick}>
    <div className="anchor-button__backdrop" />
    <div className="anchor-button__content">
      <i className={`anchor-button__icon ${icon}`} />
      <div className="anchor-button__label">{text}</div>
      <i className={`anchor-button__icon ${icon}`} />
    </div>
  </button>
);
