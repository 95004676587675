import { useContext } from 'react';
import './tile-actions.scss';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ModalContext } from '@/components/modal/modal-provider';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ContactsSidebar } from '../../sidebars/contacts-sidebar/contacts-sidebar';
import { TileCta } from '../tile-cta/tile-cta';
import { InfoModal } from '../../modals';
import { changeApplicationStatus } from '@/services/api/application';
import { statuses } from '@/domains/core/tracker-data';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { CommentsHistorySidebar } from '../../sidebars';
import { CommentFromCA, CommentFromCAType } from '@/domains/core/advisor/types';
import { CommentPreviewBox } from '@/domains/core/advisor/components/comment-preview-box/comment-preview-box';
import { resolveComment } from '@/domains/core/advisor/api/comment';

type Props = {
  readOnly?: boolean;
  tileID: string;
  tileOrdering: number;
  companyName: string;
  companyID: string;
  studentID: string;
  comments: CommentFromCA[];
  countOfContacts: number;
  handleAddCommentClick?: () => void;
};

export const TileActions = ({
  readOnly = false,
  companyName,
  companyID,
  studentID,
  comments,
  tileID,
  tileOrdering,
  countOfContacts,
  handleAddCommentClick,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const navigate = useNavigate();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const queryClient = useQueryClient();

  const handleContactsIconClicked = () => {
    trackEvent('Overview Board: Contact Icon clicked', {});
    if (readOnly) {
      openSidebar(
        <ContactsSidebar
          companyName={companyName}
          companyID={companyID}
          closeSidebar={closeSidebar}
          userID={studentID}
        />,
      );
    } else {
      openTracker(companyID, 'Contacts');
    }
  };

  const handleResolveComment = async (commentID: string, commentType: CommentFromCAType): Promise<void> => {
    const response = await resolveComment(commentID, commentType);
    if (response) {
      queryClient.invalidateQueries(['studentComments']);
    }
  };

  const openCommentPreview = (comment: CommentFromCA) => {
    closeSidebar();
    openModal(
      <CommentPreviewBox
        companyName={companyName}
        companyLogo={comment.application?.company.logo_url}
        title={comment.application?.company.name || 'General Comment'}
        closeCommentBox={closeModal}
        commentText={comment.comment}
        id={comment.id}
        handleMarkAsResolved={(id) => handleResolveComment(id, comment.type)}
        isResolved={comment.resolved}
      />,
    );
  };

  const handleCommentsIconClick = () => {
    trackEvent('Overview Board: Comments Icon clicked', {});

    openSidebar(
      <CommentsHistorySidebar
        applicationID={tileID}
        title="Company Comments"
        subtitle={`All Comments made for ${companyName}`}
        onSeeCommentClick={openCommentPreview}
      />,
    );
  };

  const goToArchive = () => {
    closeModal();
    navigate('/app/archive');
  };

  const archiveCompany = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    closeModal();
    await changeApplicationStatus(tileID, statuses.Archived, tileOrdering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['dashboard-actions']);
    trackEvent('Overview Board: Company Archive', {});
    openModal(
      <InfoModal
        handleButtonClick={goToArchive}
        title="Company Archived!"
        description="The Company is now archived. You can find it in your Archive List."
        buttonLabel="Go to Archive List"
      />,
    );
  };

  const openAddJobModal = () => {
    trackEvent('Overview Board: Jobs Icon clicked', {});
    openTracker(companyID, 'Jobs');
  };

  const goToNotesTab = () => {
    trackEvent('Overview Board: Notes Icon clicked', {});
    openTracker(companyID, 'Notes');
  };

  const openAreYouSure = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle-fill"
          handleButtonClick={archiveCompany}
          handleSecondaryButtonClick={closeModal}
          title="Are you sure?"
          description="Archiving will stop your progress with this company and remove it from your Overview Board. It will be moved to your Archive List."
          buttonLabel="Archive"
          secondaryButtonLabel="Cancel"
          rememberText="Remember my choice"
          isButtonDanger
        />,
      );
    } else {
      archiveCompany(false);
    }
  };
  return (
    <div className="tile-actions">
      {!readOnly && (
      <TileCta
        label="View Notes"
        icon="bi bi-sticky"
        handleClick={goToNotesTab}
      />
      )}
      <TileCta
        label={readOnly ? 'View Contacts' : `You have ${countOfContacts} contact(s) at this company.`}
        icon="bi bi-people"
        handleClick={handleContactsIconClicked}
        counter={countOfContacts}
      />
      {!readOnly ? (
        <>
          {comments?.length > 0 && (
          <TileCta
            label="See Comments"
            icon="bi bi-chat-square-text"
            handleClick={handleCommentsIconClick}
            counter={comments.length}
          />
          )}
          <TileCta
            label="Save Job Posting"
            icon="bi bi-suitcase-lg"
            handleClick={openAddJobModal}
          />

          <TileCta
            label="Archive Company"
            icon="bi bi-archive"
            handleClick={openAreYouSure}
          />
        </>
      ) : (
        <TileCta
          label="View Comments"
          icon="bi bi-chat-square-text"
          handleClick={handleCommentsIconClick}
          counter={comments?.length || 0}
        />
      )}

      {handleAddCommentClick && (
        <TileCta
          label="Write a comment"
          icon="bi bi-plus-lg"
          handleClick={handleAddCommentClick}
        />
      )}
    </div>
  );
};
