import { Tile } from '@/domains/core/company/types';
import './industry-sidebar.scss';

type Props = {
  industry: string;
  closeSidebar: () => void;
  companies: Tile[];
  onClick: (id: string) => void;
};

export const IndustrySidebar = ({
  industry,
  closeSidebar,
  companies,
  onClick,
}: Props) => (
  <div className="industry-sidebar">
    <h2 className="industry-sidebar__title" onClick={closeSidebar}>
      {industry}
    </h2>
    <p className="industry-sidebar__subtitle">Companies with most student engagement.</p>
    <div className="industry-sidebar__companies">
      <div className="industry-sidebar__companies-headers">
        <div className="industry-sidebar__companies-head">Company</div>
        <div className="industry-sidebar__companies-head"># of Students</div>
      </div>
      <div className="industry-sidebar__companies-list">
        {companies.sort((a: any, b: any) => (parseInt(a.count, 10) > parseInt(b.count, 10) ? -1 : 1)).map((company: any) => (
          <div key={company.company_id} className="industry-sidebar__companies-item" onClick={() => onClick(company.company_id)}>
            <div className="industry-sidebar__companies-item-company">
              <img className="industry-sidebar__companies-item-logo" src={company.logo_url} alt="company logo" />
              <span className="industry-sidebar__companies-item-title">{company.company_name}</span>
            </div>
            <div className="industry-sidebar__companies-item-students">
              {company.count}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
