import {
  About,
  Benefits, CardWithBadges, CompanyMedia, CompanyProfileHeader, Faqs, IconButton, Locations,
} from '@careeros/coco';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import {
  ApiStatus,
  Company, CompanyCulture, CompanyEvents, CompanyFeatures, OverviewInformation, PremiumCompanyGeneralInformation, Team,
} from '@/domains/core/company/types';
import '../styles/company.scss';
import { TabsContent, TabsHeader } from '@/components/tabs';
import {
  addCompanyToFavorites, BasicCompanyJobsTab, CompanyStatusContainer, CompanyEventsContainer, CompanyCultureContainer, CompanyTeamsContainer,
} from '@/domains/core/company';
import { Contact } from '@/domains/core/contact/types';
import { ModalContext } from '@/components/modal/modal-provider';
import { useSelf } from '@/services/queries/user';
import { useRecombee } from '@/services/hooks/use-recombee';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';

type PremiumProfileProps = {
  company: Company;
  companyOverview: OverviewInformation;
  companyFeatures: CompanyFeatures;
  generalInformation: PremiumCompanyGeneralInformation;
  events: CompanyEvents[];
  culture: CompanyCulture;
  testimonials: any; // we dont know the response yet
  teams: Team[] | null;
  application: {
    application_id: string;
    application_status: ApiStatus;
  }
  contacts: Contact[];
  articlesUrlProfile?: string;
};

const mapsKey = import.meta.env.VITE_GOOGLE_MAPS_ID || '';
const DEFAULT_TABS = ['Overview'];

const PremiumCompanyProfile = ({
  companyOverview, company, companyFeatures, generalInformation, events, culture, testimonials, teams, application, contacts, articlesUrlProfile,
}: PremiumProfileProps) => {
  const [tabs, setTabs] = useState(DEFAULT_TABS);
  const { openModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const { data: self } = useSelf();
  const { addBookmark, ITEM_TYPES } = useRecombee();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    mission, vision, culture_description: cultureDesc, values,
    diversity_statistics: diversityStats, glassdoor_url: glassdoor,
    kununu_url: kununu, photos_videos: media,
  } = culture;

  const isEmptyOrHidden = (value: any, feature: boolean) => !value || !feature;

  const isCultureEmpty = isEmptyOrHidden(mission, companyFeatures.show_company_mission)
    && isEmptyOrHidden(vision, companyFeatures.show_company_vision)
    && isEmptyOrHidden(cultureDesc, companyFeatures.show_culture_description)
    && isEmptyOrHidden(values?.length, companyFeatures.show_company_values)
    && isEmptyOrHidden(diversityStats?.length, companyFeatures.show_diversity_statistics)
    && isEmptyOrHidden(glassdoor, companyFeatures.show_glassdoor)
    && isEmptyOrHidden(kununu, companyFeatures.show_kununu)
    && isEmptyOrHidden(media?.length, companyFeatures.show_culture_media);

  const openEvent = (id: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('eventID', id);
    newSearchParams.set('company-page-tab', 'Events'); // Ensure we're on Events tab
    setSearchParams(newSearchParams);
  };

  const closeEvent = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('eventID');
    // Keep the current tab
    setSearchParams(newSearchParams);
  };
  const revalidate = () => {
    queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['company', company.id]);
    queryClient.invalidateQueries(['company-profile', company.id]);
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  const goToContact = (contactID: string) => {
    navigate(`/app/inbox/${contactID}`);
  };

  const handleShareClick = () => {
    const companyName = generalInformation.linkedin_url.split('company/')[1];
    navigator.clipboard.writeText(`${window.location.origin}/company/${companyName}`);
    addToast({
      type: 'success',
      message: 'Company URL has been copied',
      additionalMessage: 'Share it with intrested parties.',
    });
  };

  const saveCompany = async () => {
    addCompanyToFavorites(company.id).finally(revalidate);
    if (self?.id) {
      await addBookmark(
        self.id,
        ITEM_TYPES.COMPANY,
        company.id,
        {
          location: company.hq_location,
          company_name: company.name,
          industry: company.industry,
          company_size: company.company_size,
          is_partner: true,
        },
      );
    }
  };

  useEffect(() => {
    const newTabs = [...DEFAULT_TABS];

    if (!isCultureEmpty) {
      newTabs.push('Culture');
    }
    if (testimonials) {
      newTabs.push('Testimonials');
    }
    if (companyFeatures.show_teams && teams && teams?.length > 0) {
      newTabs.push('Teams');
    }
    if (companyFeatures.show_events && events && events?.length > 0) {
      newTabs.push('Events');
    }
    if (articlesUrlProfile) {
      newTabs.push('Articles');
    }

    if (!newTabs.includes('Jobs')) {
      newTabs.push('Jobs');
    }

    setTabs(newTabs);
  }, [culture, testimonials, companyFeatures.show_teams, teams, events, articlesUrlProfile]);

  if (!company) {
    return (
      <>
        Company not found
      </>
    );
  }

  const showInfoSection = companyOverview.benefits_custom || (companyOverview.benefits && companyOverview.benefits.length > 0) || companyOverview.upload_photos_videos || companyOverview.office_locations || (companyOverview.faq_questions && companyOverview.faq_questions.length > 0);

  return (
    <>
      <CompanyProfileHeader
        name={company.name}
        logoImage={company.logo_url}
        location={company.hq_location}
        coverImage={company.bg_image_url || ''}
        isVerified
        headerControl={<IconButton mode="primary" outlined size="large" icon="bi bi-share" handleClick={handleShareClick} />}
      />
      <div className="company-page-premium__content">
        <div className="company-page-premium__tabs">
          <TabsHeader
            tabs={tabs}
            size="medium"
            withBorder
            defaultTab={searchParams.get('company-page-tab') || tabs[0]}
            containerName="company-page"
          />
        </div>
        <TabsContent>
          <div data-tab="Overview">
            <CompanyStatusContainer company={company} application={application} saveCompany={saveCompany} goToContact={goToContact} contacts={contacts} />
            <div className="company-page-premium__overview">
              {showInfoSection && (
                <section className="company-page-premium__info-section">
                  {(companyOverview.benefits_custom || companyOverview.benefits.length > 0) && companyFeatures.show_benefits
                    ? <Benefits badges={companyOverview.benefits} description={companyOverview.benefits_custom} />
                    : null}

                  {companyOverview.upload_photos_videos && companyFeatures.show_overview_media ? <CompanyMedia media={companyOverview.upload_photos_videos} openModal={openModal} /> : null}
                  {companyOverview.office_locations && companyFeatures.show_office_locations && <Locations locations={companyOverview.office_locations} googleMapsApiKey={mapsKey} />}
                  {companyFeatures.show_office_perks && <CardWithBadges title="Office Perks" description={companyOverview.office_perks_custom} badges={companyOverview.office_perks} />}
                  {companyOverview.faq_questions && companyOverview.faq_questions.length > 0 && companyFeatures.show_faq ? <Faqs faqs={companyOverview.faq_questions} companyName={company.name} /> : null}
                </section>
              )}
              <section className={`company-page-premium__about-section ${showInfoSection ? '' : 'company-page-premium__about-section--full'}`}>
                <About generalInformation={{
                  ...generalInformation, location: generalInformation.hq_location, size: generalInformation.company_size, description: generalInformation.overview, industry: generalInformation.careeros_industry,
                }}
                />
              </section>
            </div>
          </div>
          <div className="company-page__events" data-tab="Events">
            <CompanyEventsContainer
              events={events}
              eventID={searchParams.get('eventID')}
              companyName={company.name}
              companyLogo={company.logo_url}
              handleOpenEvent={openEvent}
              handleCloseEvent={closeEvent}
            />
          </div>
          <div className="company-page__articles" data-tab="Articles">
            <div className="articles-container">
              <iframe
                src={`${articlesUrlProfile}`}
                className="articles-iframe"
                title="Articles"
              />
            </div>
          </div>
          <div className="company-page__culture" data-tab="Culture">
            <CompanyCultureContainer culture={culture} features={companyFeatures} />
          </div>
          <div className="company-page__jobs company-page__jobs--premium" data-tab="Jobs">
            <BasicCompanyJobsTab // to decide if we reuse this or we have something else here
              companyID={company.id}
              isCompanyApplicationActive={!!company.application_status && company.application_status !== 'archive'}
              companyName={company.name}
              companyLogo={company.logo_url}
              companyLinkedIn={company.linkedin_url}
              updateTabCount={() => { }}
              applicationID={application.application_id}
            />
          </div>
          <div className="company-page__teams" data-tab="Teams">
            <CompanyTeamsContainer teams={teams || []} />
          </div>
        </TabsContent>

      </div>
    </>
  );
};

export default PremiumCompanyProfile;
