import { useLoaderData, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Company, CompanyResponse } from '@/domains/core/company/types';
import { Loader } from '@/components/loader/loader';
import './styles/company.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useGetSingleCompanyProfile } from '@/services/queries/company';
import BasicCompanyProfile from './company/basic-company';
import { withTabs } from '@/components/tabs';
import PremiumCompanyProfile from './company/premium-company';
import { useRecombee } from '@/services/hooks/use-recombee';
import { useSelf } from '@/services/queries/user';

function CompanyPage() {
  const { contacts } = useLoaderData() as CompanyResponse;
  const params = useParams();
  const { companyID } = params;
  const { data: companyInformation, isLoading: isCompanyLoading, isFetching: isCompanyFetching } = useGetSingleCompanyProfile(companyID, { enabled: !!companyID && companyID !== 'null' });
  const { data: self } = useSelf();
  const [company, setCompany] = useState<Company | null>(null);
  const [premium, setPremium] = useState(false);
  const [viewTracked, setViewTracked] = useState(false);
  const { addDetailView, ITEM_TYPES } = useRecombee();

  useEffect(() => {
    if (companyInformation && !isCompanyLoading && !isCompanyFetching) {
      setPremium(companyInformation.is_partner);
      console.log(companyInformation);
      const mappedCompany: Company = {
        id: companyID || '',
        name: companyInformation.general_information.name,
        overview: companyInformation.overview.overview || '',
        short_description: companyInformation.general_information.description,
        hq_location: companyInformation.general_information.hq_location,
        company_url: companyInformation.general_information.website_url,
        logo_url: companyInformation.general_information.logo_url,
        linkedin_url: companyInformation.general_information.linkedin_url,
        twitter_url: companyInformation.general_information.twitter_url || '',
        instagram_url: companyInformation.general_information.instagram_url,
        industry: companyInformation.general_information.careeros_industry,
        careeros_industry: companyInformation.general_information.careeros_industry,
        tags: companyInformation.general_information.tags || [],
        jobs: [], // Add jobs if available
        company_size: companyInformation.general_information.company_size,
        bg_image_url: companyInformation.general_information.bg_image_url,
        website_url: companyInformation.general_information.website_url,
      };
      setCompany(mappedCompany);
    }
  }, [companyInformation, isCompanyLoading, isCompanyFetching]);

  // Track view after company data is loaded
  useEffect(() => {
    const trackView = async () => {
      if (
        company
          && self?.id // Make sure we have a user ID
          && !viewTracked // Prevent duplicate tracking
          && !isCompanyLoading
          && !isCompanyFetching
      ) {
        const success = await addDetailView(
          self.id,
          ITEM_TYPES.COMPANY,
          company.id,
          {
            location: company.hq_location,
            company_name: company.name,
            industry: company.industry,
            company_size: company.company_size,
            is_partner: premium,
          },
        );

        if (success) {
          setViewTracked(true);
        }
      }
    };

    trackView();
  }, [
    company,
    self?.id,
    premium,
    viewTracked,
    addDetailView,
    ITEM_TYPES,
    isCompanyLoading,
    isCompanyFetching,
  ]);

  if (!company && (isCompanyFetching || isCompanyLoading)) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  if (!companyInformation || !company) {
    return (
      <>
        Company not found
      </>
    );
  }

  return (
    <div className={`company-page ${premium ? 'company-page--premium' : ''}`}>
      {premium ? (
        <PremiumCompanyProfile
          company={company}
          companyOverview={companyInformation.overview}
          companyFeatures={companyInformation.features}
          events={companyInformation.events}
          culture={companyInformation.culture}
          articlesUrlProfile={companyInformation.articles_url_profile}
          contacts={contacts}
          testimonials={null}
          teams={companyInformation.teams}
          generalInformation={companyInformation.general_information}
          application={companyInformation.application_info}
        />
      ) : (
        <BasicCompanyProfile
          contacts={contacts}
          company={company}
          applicationInfo={companyInformation.application_info}
          articlesUrlProfile={companyInformation.articles_url_profile}
        />
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(CompanyPage), {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
