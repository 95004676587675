import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from 'react-query';
import { Button } from '@careeros/coco';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { Checkbox } from '@/components/checkbox/checkbox';

import './styles/cohort-form.scss';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { identifyUserInCustomerIO, setUserInMixpanel, trackUser } from '@/services/helpers/user';
import { useGetCohortsByUniversityID } from '@/services/queries/student';
import { assignCareerAdvisorRole, enrollToCohort, updateFirstNameForUser } from '@/services/api/user';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { Input } from '@/components/input/input';
import { DropdownSearchStatic } from '@/components/dropdown-search-static/dropdown-search-static';
import { getUserRouteByRoleAndLatestUrl } from '@/services/helpers/login';

const CohortForm = () => {
  const navigate = useNavigate();
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const { data: cohorts, isLoading: areCohortsLoading } = useGetCohortsByUniversityID(university?.id || '', {
    enabled: !!university?.id, // Only fetch when a valid universityID is available
  });
  const { data: selfUser, isLoading: isSelfLoading } = useSelf();
  const { user } = useAuth0();
  const [selectedCohort, setSelectedCohort] = useState<{ name: string, id: string }>();
  const [isAdvisor, setIsAdvisor] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const [firstName, setFirstName] = useState('');
  const [formNeedsFirstName, setFormNeedsFirstName] = useState(false);

  const handleCohortSelect = (cohort: string, id: string) => {
    setSelectedCohort({ name: cohort, id });
    setIsWarningVisible(false);
  };

  const handleContinue = async () => {
    if (!user) return;
    if (!selectedCohort?.id && !isAdvisor) {
      setIsWarningVisible(true);
      addToast({
        type: 'error',
        message: 'Please select a cohort or select that you are a career advisor',
      });
      return;
    }

    if (formNeedsFirstName && !firstName) {
      addToast({
        type: 'error',
        message: 'Please enter your first name',
      });
      return;
    }

    if (formNeedsFirstName && firstName) {
      try {
        await updateFirstNameForUser(firstName);
      } catch (error) {
        trackEvent('Toast Error Shown', {}, {
          message: 'An error occurred while updating your first name',
          error,
        });
        Sentry.captureException(error);
      }
    }

    try {
      if (user) {
        trackUser({ ...user, role: isAdvisor ? 'Student' : 'CareerAdvisor', given_name: formNeedsFirstName ? firstName : user.given_name }, trackEvent, university?.name, selectedCohort?.name);
        setUserInMixpanel({ ...user, role: isAdvisor ? 'Student' : 'CareerAdvisor' }, university?.name, selectedCohort?.name);
        identifyUserInCustomerIO({ ...user, role: isAdvisor ? 'Student' : 'CareerAdvisor', given_name: formNeedsFirstName ? firstName : user.given_name }, university?.name, selectedCohort?.name);
        queryClient.invalidateQueries('user');
      }
      if (isAdvisor) {
        await assignCareerAdvisorRole();
        if (user) {
          trackUser({ ...user, role: 'CareerAdvisor', given_name: formNeedsFirstName ? firstName : user.given_name }, trackEvent, university?.name, selectedCohort?.name);
        }

        addToast({
          type: 'success',
          message: 'Success! We’ll get back to you.',
          additionalMessage: 'Your request to get career advisor insights was successful. We’ll notify you soon.',
        });
      } else {
        await enrollToCohort(selectedCohort?.id || '', university?.id || '');

        queryClient.invalidateQueries('user-university');

        addToast({
          type: 'success',
          message: 'Success! Your cohort was set.',
          additionalMessage: 'You can now access your dashboard and start exploring opportunities.',
        });
      }
    } catch (error) {
      addToast({
        type: 'error',
        message: 'An error occurred while setting your cohort.',
        autoClose: false,
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'An error occurred while setting your cohort',
        error,
      });
      Sentry.captureException(error);
    }
    navigate(getUserRouteByRoleAndLatestUrl(user));
  };

  useEffect(() => {
    if (isAdvisor) {
      setSelectedCohort(undefined);
      setIsWarningVisible(false);
    }
  }, [isAdvisor]);

  useEffect(() => {
    if (areCohortsLoading || isUniversityLoading || !user) {
      return;
    }
    if (cohorts && cohorts.length === 1) {
      setSelectedCohort({ name: cohorts[0].name, id: cohorts[0].id });
      return;
    }
    if ((!cohorts || cohorts.length === 0)) {
      navigate(getUserRouteByRoleAndLatestUrl(user));
    }
  }, [areCohortsLoading, cohorts, isUniversityLoading, user]);

  useEffect(() => {
    if ((!university || university?.name === 'Trial' || university?.name === 'Católica Lisbon School of Business') && !isUniversityLoading && !areCohortsLoading && user) {
      navigate(getUserRouteByRoleAndLatestUrl(user));
    }
    if (!isUniversityLoading && !areCohortsLoading && (university?.name === 'EBS Business School' || university?.name === 'ESSEC' || university?.name === 'Antwerp Management School' || university?.name === 'Bayes Business School' || university?.name === 'Berkeley Haas School of Business' || university?.name === 'UCLA Anderson School of Management' || university?.name === "Duke University's Fuqua School of Business")) {
      setFormNeedsFirstName(true);
    }
  }, [isUniversityLoading, areCohortsLoading]);

  useEffect(() => {
    if (selfUser && !isSelfLoading && user) {
      if (selfUser.role === 'careerAdvisor') {
        navigate(getUserRouteByRoleAndLatestUrl(user));
        setIsAdvisor(true);
      }
    }
  }, [selfUser, isSelfLoading, user]);

  return (
    <div>
      <div className="cohort-form">
        <div className="cohort-form__container">
          <div className="cohort-form__header">
            <h2 className="cohort-form__title">
              {`Please tell us what cohort at ${university?.name} you’re part of!`}
            </h2>
            <p className="cohort-form__subtitle">
              We will use this to show you relevant employers, contacts & jobs.
              <br />
              <br />
              <a
                href={`https://share.hsforms.com/1XspFVBkwT2S7dMKLWzrKqg55xla?email=${selfUser?.email || ''}`}
                target="_blank"
                className="cohort-form__link"
                rel="noreferrer"
              >
                Can’t find your cohort?
              </a>
            </p>
          </div>
          <div className="cohort-form__inputs">
            <div className="cohort-form__dropdown">
              <DropdownSearchStatic
                name="Find your cohort"
                selectedOption={selectedCohort?.name || ''}
                id="find-your-cohort"
                placeholder={isAdvisor ? 'Career Advisor' : 'E.g. “MBA25”'}
                options={cohorts?.map((cohort) => ({ value: cohort.id, name: cohort.name, logo: university?.logo_url })) || []}
                handleItemSelect={handleCohortSelect}
                canAddItems={false}
                isDisabled={isAdvisor}
                warningText="Please select a cohort"
                invalid={isWarningVisible}
                openOnClick
              />
            </div>
            {formNeedsFirstName && (
              <div className="cohort-form__input">
                <Input
                  value={firstName}
                  id="firstName"
                  invalid={!firstName}
                  label="What’s your first name?"
                  placeholder="Enter first name"
                  handleValueChange={setFirstName}
                />
              </div>
            )}
            <Checkbox
              label={`I don’t study at ${university?.name}, I’m a career advisor `}
              checked={isAdvisor}
              onChange={setIsAdvisor}
            />
            <Button
              label="Next"
              icon="bi bi-arrow-right"
              iconPosition="right"
              onClick={handleContinue}
              mode="primary"
              size="medium"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CohortForm;
