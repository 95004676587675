import { Column, TableRow } from './table-types';
import { TableCell } from './cell/table-cell';
import { TagsCell } from './tags-cell/tags-cell';
import { TableCellWithoutTooltip } from './table-cell-without-tooltip/table-cell-without-tooltip';

type TableBodyProps = {
  tableData: TableRow[];
  columns: Column[];
  withRowNumber?: boolean;
  isRowClickable?: boolean;
  hasTooltip?: boolean;
  align?: 'left' | 'center' | 'right';
};

const TableBody = ({
  tableData,
  columns,
  withRowNumber = false,
  isRowClickable = false,
  hasTooltip = true,
  align = 'left',
}: TableBodyProps) => {
  const handleRowClick = (data: TableRow) => {
    if (isRowClickable && data.handleRowClick) {
      data.handleRowClick();
    }
  };

  return (
    <tbody>
      {tableData.map((data: TableRow, index: number) => (
        <tr
          className={`table__row table__row--${align} ${isRowClickable ? 'table__row--clickable' : ''}`}
          key={data.id}
          onClick={() => handleRowClick(data)}
        >
          {withRowNumber && <td key={`nr${data.id}`}>{index + 1}</td>}
          {columns.map(({
            accessor,
            type,
            isCentered,
            isFitContent,
          }: Column) => (
            <>
              {hasTooltip && type !== 'tags' && (
              <TableCell
                key={accessor}
                cellData={data.items[accessor]}
                type={type}
                isCentered={isCentered}
                isFitContent={isFitContent}
              />
              )}
              {!hasTooltip && type === 'tags' && (
              <TagsCell
                key={accessor}
                cellData={data.items[accessor]}
                isFitContent={isFitContent}
              />
              )}
              {!hasTooltip && type !== 'tags' && (
              <TableCellWithoutTooltip
                key={accessor}
                cellData={data.items[accessor]}
                type={type}
                isCentered={isCentered}
                isFitContent={isFitContent}
              />
              )}
            </>
          ))}
        </tr>
      ))}
    </tbody>

  );
};

export default TableBody;
