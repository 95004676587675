import { http } from '@/services/api/http';
import { CommentFromCAType } from '@/domains/core/advisor/types';
import { convertFromApiCAComment } from './model/comment';

export const addStudentGeneralComment = async (studentId: string, comment: string) => http.post(`/university/advisor/comment/${studentId}`, { comment }, undefined, 'v2');

export const resolveComment = async (commentID: string, commentType: CommentFromCAType): Promise<any> => http.patch(`/comments/${commentID}/resolve`, { comment_type: commentType });

export const getStudentComments = async (studentID: string) => {
  const response = await http.get(`/comments/${studentID}`);

  if (!response?.comments || !Array.isArray(response?.comments)) {
    return [];
  }

  return response.comments.map(convertFromApiCAComment);
};
