import './comment-box-wrapper.scss';
import { useCACommentState } from '@/domains/core/advisor/contexts/CA-comment-input/CA-comment-input';

export const CommentBoxWrapper = () => {
  const { commentElement } = useCACommentState();

  return (
    <div className="comment-box-wrapper">
      {commentElement}
    </div>
  );
};
