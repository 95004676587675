import { useMemo, useRef, useState } from 'react';
import { FilterOption } from '@/domains/core/company/types';
import { AvatarStack } from '../avatar-stack/avatar-stack';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';
import { DropdownSelect } from '../select-dropdown/select-dropdown';

import './avatar-stack-with-dropdown.scss';

type Props = React.ComponentProps<typeof AvatarStack> & {
  handleSelectOption?: (option: FilterOption) => void;
  fullList?: FilterOption[];
};

export const AvatarStackWithDropdownSelect = ({
  personList,
  handleSelectOption,
  fullList = [],
  ...props
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const mappedSelectedNameOptions = useMemo<FilterOption[]>(() => personList.map((p) => ({ name: p.name, value: p.id })), [personList]);

  const [isFullListOpen, setIsFullListOpen] = useState(false);

  const openFullListModal = () => {
    if (!fullList.length) return;
    setIsFullListOpen(true);
  };

  const closeListModal = () => {
    setIsFullListOpen(false);
  };

  const handleClick = () => {
    if (isFullListOpen) {
      closeListModal();
    } else {
      openFullListModal();
    }
  };

  const handleSelectOptionFromList = (option: FilterOption) => {
    if (handleSelectOption) {
      handleSelectOption(option);
    }
    closeListModal();
  };

  return (
    <div className="avatar-stack-with-dropdown" ref={containerRef}>
      <AvatarStack
        {...props}
        personList={personList}
        withAddButton={fullList.length > 0}
        handleClick={handleClick}
        handleAddButtonClick={openFullListModal}
      />

      {isFullListOpen && handleSelectOption && (
        <div className="avatar-stack-with-dropdown__dropdown-container">
          <PopupBoxWrapper handleBlur={closeListModal} parentRef={containerRef}>
            <div className="avatar-stack-with-dropdown__dropdown">
              <DropdownSelect
                options={fullList}
                handleItemSelect={handleSelectOptionFromList}
                selectedOptions={mappedSelectedNameOptions}
              />
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
