import { ContactPatchRequestPayload, ContactApiStatus, SuggestedContactType } from '@/domains/core/contact/types';
import { http } from './http';

export const getContacts = async () => http.get('/users/contacts');

export const getCompanyContacts = async (companyID: string) => http.get(`/contacts/bycompany/${companyID}`);

export const getContactsByCompanyAndUser = async (companyID: string, userID: string) => http.get(`/contacts/bycompany/${companyID}/byuser/${userID}`);

export const deleteContact = async (contactID: string) => http.delete(`/contacts/${contactID}`);

export const getContact = async (contactID: string) => http.get(`/contacts/${contactID}/chat`);

export const getMessages = async (contactID: string) => http.get(`/messages/${contactID}`);

export const patchContact = async ({
  id,
  email,
  secondary_email,
}: ContactPatchRequestPayload) => http.patch(`/contacts/${id}/email`, {
  email,
  secondary_email,
});
export const sendMessage = async (contactID: string) => http.post('/linkedin/message/send', { contact_id: contactID });

export const sentConnection = async (contactID: string) => http.post('/linkedin/connection/sent', { to_contact_id: contactID });

export const getFirebaseUserToken = async () => {
  const res = await http.post('/firebase/token', {});
  return res.token;
};

export const getAllContacts = (contactsIDs: string[]): Promise<any[]> => {
  const promises = contactsIDs.map((id: string) => http.get(`/contacts/${id}/chat`));
  return Promise.allSettled(promises);
};

export const sendEmailToContact = async (message: string, subject: string, contact_id: string, to_email:string, original_message_id: string | null = null) => http.post('/email/messages', {
  to_email,
  contact_id,
  subject,
  message,
  original_message_id: original_message_id || null,
});

export const logMessageSent = async (contact_id: string, ai_used: boolean, is_first_outreach: boolean, template_used: string, channel: 'email' | 'linkedin' | 'connection') => http.post('/messages/sent', {
  contact_id, ai_used, is_first_outreach, template_used, channel,
});

export const getEmails = async (contact_id: string) => http.get(`/email/messages?contactID=${contact_id}`);

export const fetchAllContactEmails = async () => http.get('/email/messages');

export const updateContactConnectionStatus = async (contact: any, status: string) => http.put(`/contacts/${contact.id}/linkedin`, { ...contact, linkedin_connection_status: status });

export const getPendingContacts = async () => http.get('/contacts/bystatus/pending');

export const updateContactPositionInKanban = async (contactID: string, status: ContactApiStatus, row: number) => http.patch(`/users/contacts/${contactID}`, { row, status });

export const getNotesByContact = async (contactId: string) => http.get(`/contacts/${contactId}/notes?offset=0&limit=20`);

export const addContactNote = async (contactID: string, title: string, content: string) => http.post(`/contacts/${contactID}/notes`, { content, title });

export const editContactNote = async (contactID: string, noteID: string, title: string, content: string) => http.patch(`/contacts/${contactID}/notes/${noteID}`, { content, title });

export const deleteContactNote = async (contactID: string, noteID: string) => http.delete(`/contacts/${contactID}/notes/${noteID}`);

export const saveContact = async (suggestedContact: SuggestedContactType, companyID: string) => http.post(
  '/contacts',
  {
    email: null,
    phone: null,
    linkedin_url: suggestedContact.contact.linkedin_url,
    linkedin_urn: suggestedContact.contact.linkedin_urn,
    first_name: suggestedContact.contact.first_name,
    last_name: suggestedContact.contact.last_name,
    current_position: {
      ...suggestedContact.contact.current_position,
      company_id: companyID,
    },
    career_summary: suggestedContact.contact.career_summary,
    source: 'StudentApp',
    linkedin_connection_status: 'notConnected',
  },
);
