import { createContext, useReducer, useContext } from 'react';
import type { ReactNode } from 'react';
import { CommentBoxWrapper } from '../../components/comment-box-wrapper/comment-box-wrapper';

interface CommentState {
  isInputOpened: boolean;
  commentElement: JSX.Element | null;
}

type CommentActionType =
  | { type: 'OPEN'; payload: JSX.Element }
  | { type: 'CLOSE' };

const CACommentReducer = (state: CommentState, action: CommentActionType): CommentState => {
  switch (action.type) {
    case 'OPEN':
      return { isInputOpened: true, commentElement: action.payload };
    case 'CLOSE':
      return { isInputOpened: false, commentElement: null };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

const CACommentStateContext = createContext<CommentState | undefined>(undefined);
const CACommentDispatchContext = createContext<React.Dispatch<CommentActionType> | undefined>(undefined);

export const CACommentInputProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(CACommentReducer, {
    isInputOpened: false,
    commentElement: null,
  });

  return (
    <CACommentStateContext.Provider value={state}>
      <CACommentDispatchContext.Provider value={dispatch}>
        <CommentBoxWrapper />
        {children}
      </CACommentDispatchContext.Provider>
    </CACommentStateContext.Provider>
  );
};

export const useCACommentState = () => {
  const context = useContext(CACommentStateContext);
  if (!context) {
    throw new Error('useCACommentState must be used within an CACommentInputProvider');
  }
  return context;
};

export const useCACommentDispatch = () => {
  const context = useContext(CACommentDispatchContext);
  if (!context) {
    throw new Error('useCACommentDispatch must be used within an CACommentInputProvider');
  }

  return context;
};
