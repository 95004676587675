import { IconButton, Tooltip } from '@careeros/coco';
import { useMemo } from 'react';
import './avatar-stack.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import EmptyAvatarImage from '@/assets/images/man-gesturing-no.png';

export type AvatarStackItem = {
  id: string;
  name: string;
  avatar: string;
  handleClick?: (id: string) => void;
};

type Props = {
  personList: AvatarStackItem[];
  maxAvatars?: number;
  addButtonTooltip?: string;
  withAddButton?: boolean;
  emptyStateTooltip?: string;
  listTooltip?: string;
  size?: 24 | 28;
  order?: 'front-on-top' | 'last-on-top';
  withPerItemHover?: boolean;
  withPerItemTooltip?: boolean;
  isBlurred?: boolean;
  handleClick?: () => void;
  handleAddButtonClick?: () => void;
};

// TODO: move to coco
export const AvatarStack = ({
  personList,
  maxAvatars = 4,
  addButtonTooltip,
  withAddButton = false,
  emptyStateTooltip,
  listTooltip,
  size = 24,
  order = 'last-on-top',
  withPerItemHover = false,
  withPerItemTooltip = false,
  isBlurred = false,
  handleClick,
  handleAddButtonClick = () => {},
}: Props) => {
  const visibleAvatars = useMemo(() => {
    if (personList.length <= maxAvatars) {
      return personList;
    }
    return personList.slice(0, maxAvatars - 1);
  }, [personList, maxAvatars]);

  const handleClickEvent:React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleClick?.();
  };

  return (
    <div
      className={`avatar-stack avatar-stack--size-${size} ${isBlurred ? 'avatar-stack--blur' : ''} ${handleClick ? 'avatar-stack--with-cursor-pointer' : ''}`}
      onClick={handleClickEvent}
    >
      {visibleAvatars.length > 0 && (
        <Tooltip
          label={listTooltip || ''}
          withArrow
          position="top"
          disableHoverListener={!listTooltip || withPerItemTooltip}
        >
          <div className="avatar-stack__list">
            {visibleAvatars.map((person) => (
              <div className={`avatar-stack__item avatar-stack__item--order-${order}`}>
                <Tooltip
                  label={person.name}
                  withArrow
                  position="top"
                  disableHoverListener={!withPerItemTooltip}
                >
                  <div>
                    {/* reserved space for tooltip */}
                    {withPerItemTooltip && withPerItemHover && <div style={{ height: size / 3 }} />}
                    <div className="avatar-stack__avatar-wrapper">
                      <DefaultLogo
                        className={`avatar-stack__avatar avatar-stack__avatar--${size} ${withPerItemHover ? 'avatar-stack__avatar--with-hover' : ''}`}
                        source={person.avatar}
                        name={person.name}
                        size={size}
                        type="contact"
                      />
                    </div>
                  </div>
                </Tooltip>
              </div>
            ))}

            {(personList.length > maxAvatars) && (
              <div className={`avatar-stack__item avatar-stack__item--order-${order}`}>
                <div className="avatar-stack__avatar-wrapper">
                  <div className={`avatar-stack__avatar avatar-stack__avatar--${size} ${withPerItemHover ? 'avatar-stack__avatar--with-hover' : ''} avatar-stack__avatar--extra`}>
                    <span>
                      {`+${personList.length - maxAvatars + 1}`}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Tooltip>
      )}

      {(withAddButton && personList.length === 0) && (
        <div className={`avatar-stack__avatar avatar-stack__avatar--${size} avatar-stack__avatar--add`}>
          <Tooltip label={addButtonTooltip || ''} withArrow position="top" disableHoverListener={!addButtonTooltip}>
            <IconButton
              icon="bi bi-person-add"
              mode="rounded"
              size="small"
              outlined
              label={addButtonTooltip}
              handleClick={handleAddButtonClick}
            />
          </Tooltip>
        </div>
      )}

      {(!withAddButton && personList.length === 0) && (
        <Tooltip label={emptyStateTooltip || ''} position="top" withArrow disableHoverListener={!emptyStateTooltip}>
          <div className="avatar-stack__placeholder">
            <img src={EmptyAvatarImage} alt="Man gesturing no" className="avatar-stack__image" />
          </div>
        </Tooltip>
      )}
    </div>
  );
};
