import {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from '@careeros/coco';
import { ToggleViews } from '@/components/toggle-views/toggle-views';
import { Loader } from '@/components/loader/loader';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { useGetCohortsByUniversityID, useGetStudentsByUniversity } from '@/services/queries/student';
import { useSelfUniversity } from '@/services/queries/user';
import {
  Cohort, CohortLinkDataType, MiniAdvisor, StudentModel, StudentStatus,
} from '@/domains/core/advisor/types';
import { useGetUniversityTags } from '@/services/queries/tag';
import { FilterOption } from '@/domains/core/company/types';
import { TagType } from '@/components/tag/tag';
import { useLastViewedAt } from '@/domains/core/advisor/utils/lastview';
import { useGetAllAdvisors } from '@/services/queries/advisor';
import StudentKanban from '@/domains/core/advisor/containers/student-kanban/student-kanban';
import StudentTable from '@/domains/core/advisor/containers/student-table/student-table';
import { useScroll } from '@/services/hooks/use-scroll';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { useGetSingleCompany } from '@/services/queries/company';
import { buildCohortsWithAll } from '@/services/helpers/advisor';
import { EmailBox } from '@/domains/core/advisor/components/email-box/email-box';
import { sendMassEmail } from '@/domains/core/advisor/api/student';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import '../styles/company-overview.scss';

const columnsForCompany: StudentStatus[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Archived',
  'Rejected',
];

type FilterState = {
  searchQuery: string,
  selectedCohort: string | null,
  assignedToMe: boolean,
  selectedTags: FilterOption[],
};

function CompanyOverviewPage() {
  const [selectedView, setSelectedView] = useState<'list' | 'kanban'>('kanban');
  const lastViewedAt = useLastViewedAt();
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [isEmailBoxOpened, setIsEmailBoxOpened] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);

  const [filters, setFilters] = useState<FilterState>({
    searchQuery: '',
    selectedCohort: null,
    assignedToMe: false,
    selectedTags: [],
  });

  const { universityID: universityIDFromParams, companyID } = useParams();
  const { data: university } = useSelfUniversity();

  const universityID = useMemo(() => {
    if (universityIDFromParams) {
      return universityIDFromParams;
    }

    if (!university) {
      return undefined;
    }

    return university.id;
  }, [universityIDFromParams, university]);

  const shouldFetchUniversityData = { enabled: !!universityID };

  const {
    data: students,
    isLoading: areStudentsLoading,
  } = useGetStudentsByUniversity(universityID || '', lastViewedAt, shouldFetchUniversityData, companyID);

  const { data: company } = useGetSingleCompany(companyID, {
    enabled: !!companyID,
  });

  const {
    data: cohorts,
    isLoading: areCohortsLoading,
  } = useGetCohortsByUniversityID(universityID || '', shouldFetchUniversityData);

  const {
    data: tags,
    isLoading: areTagsLoading,
  } = useGetUniversityTags(universityID || '', shouldFetchUniversityData);

  const {
    data: allAdvisors,
  } = useGetAllAdvisors(universityID || '');

  const { cohortID } = useParams();

  const validatedStudents = useMemo<StudentModel[]>(() => (Array.isArray(students) ? students : []), [students]);
  const validatedCohorts = useMemo<Cohort[]>(() => (Array.isArray(cohorts) ? cohorts : []), [cohorts]);
  const validatedTags = useMemo<TagType[]>(() => (Array.isArray(tags) ? tags : []), [tags]);
  const validatedAdvisorList = useMemo<MiniAdvisor[]>(() => (Array.isArray(allAdvisors) ? allAdvisors : []), [allAdvisors]);

  const cohortLinks: CohortLinkDataType[] = useMemo(() => {
    if (!universityID || !cohorts) {
      return [];
    }

    // does not include filters - on redirect to cohort page, filters will be reset
    return buildCohortsWithAll(`/app/company-overview/${companyID}/university`, validatedCohorts, universityID, university?.id);
  }, [validatedCohorts, universityID, cohorts]);

  const filterByCohort = (studentsArray: StudentModel[], cohortId: string | null) => {
    if (!cohortId) return studentsArray;
    return studentsArray.filter(student => student.cohort?.id === cohortId);
  };

  const studentsByCohort = useMemo(() => filterByCohort(validatedStudents, filters.selectedCohort), [validatedStudents, filters.selectedCohort]);

  const handleCohortChange = (cohortId: string | null) => {
    setFilters(prev => ({ ...prev, selectedCohort: cohortId }));
  };

  const updateScrollState = () => {
    if (!kanbanContainer.current) {
      return;
    }

    setShowLeftScrollArrow(kanbanContainer.current.scrollLeft > 0);
    setShowRightScrollArrow(kanbanContainer.current.scrollLeft < (kanbanContainer.current.scrollWidth - kanbanContainer.current.clientWidth));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    if (!kanbanContainer.current) {
      return;
    }

    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }

    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const sendEmail = async (message: string, subject: string) => {
    if (!students) {
      return;
    }
    addToast(
      {
        type: 'success',
        message: 'Success',
        additionalMessage: 'Your email has been sent.',
      },
    );

    const studentsWhoGetEmail = filterByCohort(validatedStudents, filters.selectedCohort);
    const emails = studentsWhoGetEmail.map((student) => student.email);

    await sendMassEmail(message, subject, emails);
    setIsEmailBoxOpened(false);
  };

  useAdvisorRoleCheck();

  useEffect(() => {
    if (cohortID) {
      handleCohortChange(cohortID);
    } else {
      handleCohortChange(null);
    }
  }, [cohortID]);

  useEffect(() => {
    updateScrollState();
  }, [kanbanContainer.current]);

  if (areStudentsLoading || areCohortsLoading || areTagsLoading) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="company-overview" id="main">
      <div className="company-overview__header">
        <div className="company-overview__top-bar">
          <ToggleViews
            labels={['Kanban View', 'List View']}
            size="small"
            selectedValue={selectedView === 'kanban' ? 0 : 1}
            emitSelectedValue={(value) => setSelectedView(value === 0 ? 'kanban' : 'list')}
            icons={['bi-kanban', 'bi-grid-3x3']}
            iconsSelected={['bi-kanban-fill', 'bi-table']}
          />
          <div className="company-overview__company-info">
            <DefaultLogo
              type="company"
              source={company?.logo_url}
              name="Company Name"
              className="company-overview__company-logo"
              size={48}
            />
            <div>
              <div className="company-overview__company-name">{company?.name}</div>
              <div className="company-overview__company-industry">{company?.industry}</div>
            </div>
          </div>
          {/* <div className="company-overview__send-message-button">
            <Button
              label="Message Students"
              size="medium"
              mode="primary"
              outlined
              icon="bi bi-send"
              handleClick={() => setIsEmailBoxOpened(true)}
            />
          </div> COMMENTED OUT FOR NOW */}
          <div className="company-overview__top-right">
            {selectedView === 'kanban' && (
              <div className="company-overview__controls">
                <IconButton
                  icon="bi bi-chevron-left"
                  mode="rounded"
                  size="medium"
                  outlined
                  handleClick={() => handleVerticalScroll('left')}
                  disabled={!showLeftScrollArrow}
                />
                <IconButton
                  icon="bi bi-chevron-right"
                  mode="rounded"
                  size="medium"
                  outlined
                  handleClick={() => handleVerticalScroll('right')}
                  disabled={!showRightScrollArrow}
                />
              </div>
            )}
          </div>
        </div>
        <div className="company-overview__top-container">
          <CohortsNavigation defaultLinkName="All" links={cohortLinks} withBorderBottom />
        </div>
      </div>

      <div className={`company-overview__kanban ${selectedView === 'kanban' ? 'company-overview__kanban--active' : ''}`}>
        <StudentKanban
          universityID={universityID || ''}
          columns={columnsForCompany}
          studentsData={studentsByCohort}
          allAdvisors={validatedAdvisorList}
          onScroll={handleScroll}
          scrollContainerRef={kanbanContainer}
        />
      </div>
      <div className={`company-overview__table ${selectedView === 'list' ? 'company-overview__table--active' : ''}`}>
        <StudentTable
          universityID={universityID || ''}
          tags={validatedTags}
          students={studentsByCohort}
          allAdvisors={validatedAdvisorList}
        />
      </div>
      {isEmailBoxOpened && (
        <EmailBox
          handleSend={sendEmail}
          setIsBoxOpened={setIsEmailBoxOpened}
          companyName={company?.name}
        />
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(CompanyOverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
