import { useEffect, useState } from 'react';
import { Button } from '@careeros/coco';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { FilterSearchBar } from '@/components/filter-search-bar/filter-search-bar';
import { DropdownSearchDynamic } from '@/components/dropdown-search-dynamic/dropdown-search-dynamic';
import { RangeSlider } from '@/components/range-slider/range-slider';
import { Select } from '@/components/select/select';
import './contact-book-filters.scss';
import { FilterOption } from '@/domains/core/company/types';
import { Chip, ChipTypes } from '@/components/chip/chip';
import {
  ContactBookFiltersType, ContactFilterChipType, ContactFilterCompany, ContactFilterTagTypes,
} from '@/domains/core/advisor/types';
import { convertURLParamsToFiltersContacts } from '../../utils/getSearchParamsStringContacts';

type Props = {
  filters: ContactBookFiltersType;
  defaultFilters: ContactBookFiltersType;
  companies: ContactFilterCompany[];
  industries: {
    name: string;
  }[];
  setFilters: (filters: ContactBookFiltersType) => void;
};

export const ContactBookFilters = ({
  filters,
  defaultFilters,
  companies,
  industries,
  setFilters,
}: Props) => {
  const [selectedFiltersTags, setSelectedFiltersTags] = useState<ContactFilterChipType[]>([
    {
      type: 'tag',
      name: `${filters.response_rate.min}% - ${filters.response_rate.max}%`,
      filterName: 'response_rate',
    },
  ]);

  const mapIndustriesToOptions = (industriesArray: {
    name: string;
  }[]) => industriesArray
    .map((industry) => ({
      name: industry.name,
      value: industry.name,
    }))
    .sort((a, b) => {
      if (a.name === 'Other') return 1;
      if (b.name === 'Other') return -1;
      return a.name.localeCompare(b.name);
    });

  const handleCompanySearch = async () => {
    let companiesForLoader: ContactFilterCompany[] = [];

    try {
      // companiesForLoader = await getCompaniesByQuery(query || ''); need backend
    } catch {
      companiesForLoader = [];
    }

    return companiesForLoader
      .filter(company => filters?.companies?.every(
        selectedCompany => selectedCompany.name !== company.name,
      ))
      .map(company => ({
        name: company.name,
        value: company.name,
      }));
  };

  const mappedIndustries = mapIndustriesToOptions(industries);

  const updateFilterTags = (newFilters: typeof filters) => {
    const newTags: ContactFilterChipType[] = Object.entries(newFilters).flatMap(([filterName, filterValue]) => {
      // Handle string contactName
      if (filterName === 'contactName' && typeof filterValue === 'string' && filterValue) {
        return [{
          type: 'tag' as ChipTypes,
          name: filterValue,
          filterName: filterName as ContactFilterTagTypes,
        }];
      }

      // Handle arrays (company, industries)
      if (Array.isArray(filterValue)) {
        return filterValue.map((option) => ({
          type: 'industry' as ChipTypes,
          name: (typeof option === 'string' ? option : option.name).toString(),
          filterName: filterName as ContactFilterTagTypes,
        }));
      }

      // Handle boolean values (alumni, recruiter)
      if ((filterName === 'isAlum' || filterName === 'isRecruiter') && filterValue === 'Yes') {
        return [{
          type: 'tag' as ChipTypes,
          name: filterName === 'isAlum' ? 'Alumni' : 'Recruiter',
          filterName: filterName as ContactFilterTagTypes,
        }];
      }

      // Handle response rate object
      if (filterName === 'response_rate' && typeof filterValue === 'object' && filterValue !== null) {
        const { min, max } = filterValue as { min: number; max: number };
        return [{
          type: 'tag' as ChipTypes,
          name: `${min}% - ${max}%`,
          filterName: filterName as ContactFilterTagTypes,
        }];
      }

      return [];
    }).filter(tag => tag.name !== '');

    setSelectedFiltersTags(newTags);
  };

  const handleFiltersInSearchParams = () => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    if (!companies.length || !industries.length) return;
    const filtersFromSearch = convertURLParamsToFiltersContacts(searchParams, { companies, industries });

    setFilters(filtersFromSearch);
    updateFilterTags(filtersFromSearch);
  };

  const handleSearchChange = (contactName: string) => {
    const newFilters = { ...filters, contactName };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleCompanySelect = (item: string) => {
    const selectedCompany = companies.find((company) => company.name === item)!;
    const newFilters = { ...filters, companies: [...filters.companies, selectedCompany] };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleAlumniChange = (value: string) => {
    const newFilters = { ...filters, isAlum: value };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleRecruiterChange = (value: string) => {
    const newFilters = { ...filters, isRecruiter: value };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleIndustriesChange = (options: FilterOption[]) => {
    const newFilters = { ...filters, industryName: options };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleResponseRateChange = ([min, max]: number[]) => {
    const newFilters = { ...filters, response_rate: { min, max } };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleFilterDelete = (filterName: ContactFilterTagTypes, tagName: string) => {
    const newFilters = { ...filters } as ContactBookFiltersType;

    switch (filterName) {
      case 'companies':
      case 'industryName':
        newFilters[filterName] = (newFilters[filterName] as any[]).filter(
          (item: any) => (item.name || item) !== tagName,
        );
        break;
      case 'isAlum':
      case 'isRecruiter':
        newFilters[filterName] = '';
        break;
      case 'contactName':
        newFilters[filterName] = defaultFilters.contactName;
        break;
      case 'response_rate':
        newFilters[filterName] = { min: defaultFilters.response_rate.min, max: defaultFilters.response_rate.max };
        break;
      default:
        newFilters[filterName] = defaultFilters[filterName];
    }

    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleFilterReset = () => {
    setFilters(defaultFilters);
    updateFilterTags(defaultFilters);
  };

  useEffect(() => {
    if (new URLSearchParams(window.location.search).toString()) {
      handleFiltersInSearchParams();
    }
  }, [companies, industries]);

  return (
    <div className="contact-book-filters">
      <div className="contact-book-filters__container">
        <div className="contact-book-filters__filters">
          <FilterSearchBar
            value={filters.contactName}
            placeholder="Search first or last name"
            onChange={handleSearchChange}
          />
          <div className="contact-book-filters__filters-child">
            <DropdownSearchDynamic
              selectedOption={filters.companies.map((company) => company.name)}
              options={companies}
              handleItemSelect={handleCompanySelect}
              loaderFunction={handleCompanySearch}
              placeholder="Search company"
              name="loaction-search"
              id="location-search"
              size="medium"
              canAddItems={false}
              showIcon={false}
              withMultipleSelection
              openOnClick
              listWidth="full"
            />
          </div>

          <Select
            id="alumni"
            label=""
            handleValueChange={handleAlumniChange}
            placeholder="Alumni"
            value={filters.isAlum}
            options={['Yes', 'No']}
            width="fit-box"
          />

          <Select
            id="hr"
            label=""
            handleValueChange={handleRecruiterChange}
            placeholder="HR/Recruiter"
            value={filters.isRecruiter}
            options={['Yes', 'No']}
            width="fit-box"
          />

          <MultiSelect
            labelType="list"
            handleSelectedOptions={handleIndustriesChange}
            placeholder="Industry"
            selected={filters.industryName || []}
            options={mappedIndustries}
            width="full"
            withSelectAllOption
          />

          <RangeSlider
            label="Response Rate"
            min={0}
            max={100}
            defaultMinValue={filters.response_rate.min}
            defaultMaxValue={filters.response_rate.max}
            onChange={handleResponseRateChange}
          />
        </div>
      </div>
      {selectedFiltersTags.length > 0 && (
        <div className="contact-book-filters__filter-labels" data-testid="contact-filters-chips">
          {selectedFiltersTags.map((tag) => (
            <Chip
              key={`${tag.filterName}-${tag.name}`}
              label={tag.name}
              type={tag.type}
              handleClose={() => handleFilterDelete(tag.filterName, tag.name)}
            />
          ))}
          <Button
            mode="reset"
            outlined
            size="medium"
            label="Reset"
            handleClick={handleFilterReset}
            icon="bi bi-arrow-repeat"
            data-testid="contact-filters-reset-button"
          />
        </div>
      )}
    </div>
  );
};
