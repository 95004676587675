import { DefaultLogo } from '@/components/default-logo/default-logo';
import './student-OB-header.scss';
import { StudentAISummary } from '../student-ai-summary/student-ai-summary';

type Props = {
  name: string;
  email: string;
  profileImage: string;
  university: string;
  cohort: string;
  AISummary?: string;
  isAISummaryLoading?: boolean;
  isAISummaryDisabled?: boolean;
  handleSaveNote: () => void;
};

export const StudentOBHeader = ({
  name,
  email,
  profileImage,
  university,
  cohort,
  AISummary,
  isAISummaryLoading,
  isAISummaryDisabled,
  handleSaveNote,
}: Props) => (
  <div className="student-OB-header">
    <div className="student-OB-header__info">
      <DefaultLogo
        className="student-OB-header__profile-image"
        name={name}
        source={profileImage}
        type="contact"
        size={48}
      />
      <div className="student-OB-header__profile">
        <h3 className="student-OB-header__title">
          {`${name}, ${university} ${cohort}`}
        </h3>
        <span className="student-OB-header__subtitle">
          {email}
        </span>
      </div>
    </div>
    <StudentAISummary
      AISummary={AISummary}
      isAISummaryLoading={isAISummaryLoading}
      isAISummaryDisabled={isAISummaryDisabled || (!AISummary && !isAISummaryLoading)}
      handleSaveNote={handleSaveNote}
    />
  </div>
);
