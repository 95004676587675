import { WorkExperience } from '../work-experience/work-experience';
import avatar1 from '@/assets/images/fake-company-avatars/fake-company-avatar-1.png';
import avatar2 from '@/assets/images/fake-company-avatars/fake-company-avatar-2.png';
import './coming-soon-state-student-profile.scss';

const mockedWorkExperienceData = [
  {
    id: '1',
    position: 'Financial Analyst',
    company_name: 'JPMorgan Chase',
    company_icon_url: avatar1,
    location: 'Frankfurt, Germany',
    is_current_work: false,
    start_date: 'June 2021',
    end_date: 'July 2023',
    bullets: [
      'Conducted in-depth financial analysis, creating valuation models, including DCF and comparable company analysis, to support $500M+ in transactions.',
      'Prepared detailed financial reports and presentations for executive leadership, enhancing decision-making on potential mergers and acquisitions.',
    ],
  },
  {
    id: '2',
    position: 'Junior Financial Consultant',
    company_name: 'Allianz',
    company_icon_url: avatar2,
    location: 'Berlin, Germany',
    is_current_work: false,
    start_date: 'June 2021',
    end_date: 'July 2023',
    bullets: [
      'Provided strategic financial advice to Fortune 500 clients, focusing on cost-cutting strategies that led to over $20M in annual savings.',
    ],
  },
];

export const ComingSoonStateStudentProfile = ({ first_name }: { first_name: string }) => (
  <div className="coming-soon-state">
    <div className="coming-soon-state__work-container">
      <WorkExperience workExperience={mockedWorkExperienceData} />
    </div>
    <div className="coming-soon-state__modal">
      <div className="coming-soon-state__icon">📣</div>
      <div className="coming-soon-state__title">{`${first_name}’s Full Profile Coming Soon!`}</div>
      <div className="coming-soon-state__subtitle">
        The detailed profile will include work experience, education, and
        skills.
      </div>
    </div>
  </div>
);
