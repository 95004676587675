import {
  useState, useEffect, useMemo,
} from 'react';
import { useQueryClient } from 'react-query';
import { Button } from '@careeros/coco';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { FilterOption } from '@/domains/core/company/types';
import './comments-sidebar.scss';
import Comments from '@/assets/images/comments.svg';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { CommentFromCA, CommentFromCAType } from '@/domains/core/advisor/types';
import { useStudentComments } from '@/services/queries/student';
import { Loader } from '@/components/loader/loader';
import { resolveComment } from '@/domains/core/advisor/api/comment';
import { AdvisorCommentPreview } from '@/domains/core/advisor/components/advisor-comment-preview/advisor-comment-preview';

type Props = {
  withFilter?: boolean;
  title: string;
  subtitle: string;
  onWriteCommentClick?: () => void;
  onSeeCommentClick: (comment: CommentFromCA) => void;
  isResolveDisabled?: boolean;
  studentID?: string,
  applicationID?: string,
};

const CommentsHistorySidebar = ({
  // comments,
  withFilter = false,
  title,
  subtitle,
  onWriteCommentClick,
  onSeeCommentClick,
  isResolveDisabled = false,
  studentID,
  applicationID,
}: Props) => {
  const { data: commentsResponse, isLoading } = useStudentComments(studentID);
  const queryClient = useQueryClient();
  const [isEmpty, setIsEmpty] = useState(false);
  const preparedComments = useMemo<CommentFromCA[]>(() => (
    commentsResponse?.filter(c => {
      if (applicationID) {
        return c.application?.id === applicationID;
      }
      return true;
    }).sort((a, b) => {
      const dateA: any = new Date(a.created_at);
      const dateB: any = new Date(b.created_at);
      return dateB - dateA;
    }) || []
  ), [commentsResponse, applicationID]);
  const unresolvedCount = useMemo(() => preparedComments?.filter((comment: CommentFromCA) => !comment.resolved).length, [preparedComments]);
  const tabs = ['Unresolved', 'View all'];
  const commentTypeFilters = ['Company Comments', 'General Comments'];
  const defaultTab = useMemo(() => (unresolvedCount ? 'Unresolved' : 'View all'), [unresolvedCount]);
  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([]);
  const [filteredComments, setFilteredComments] = useState<CommentFromCA[]>([]);
  const unresolvedComments = useMemo(() => filteredComments.filter((comment: CommentFromCA) => !comment.resolved), [filteredComments]);

  const handleResolveComment = async (commentID: string, commentType: CommentFromCAType): Promise<void> => {
    const response = await resolveComment(commentID, commentType);
    if (response) {
      queryClient.invalidateQueries(['studentComments']);
    }
  };

  const handleSetFilter = (filters: FilterOption[]) => {
    setSelectedFilters(filters);
  };

  const handleFilterComments = () => {
    const filterCommentsResult = preparedComments.filter((comment: CommentFromCA) => {
      if (selectedFilters.length === commentTypeFilters.length) {
        return true;
      }
      if (selectedFilters.some((filter) => filter.value === 'Company Comments')) {
        return comment.type === 'application';
      }
      if (selectedFilters.some((filter) => filter.value === 'General Comments')) {
        return comment.type === 'general';
      }

      return true;
    });

    setFilteredComments(filterCommentsResult);
  };

  const getCommentTitleBasedOnType = (comment: CommentFromCA): string => {
    if (comment.type === 'application') {
      return `Comment on ${comment.application?.company.name}`;
    }

    return 'General Comment';
  };

  useEffect(() => {
    setIsEmpty(!preparedComments.length);
  }, [preparedComments]);

  // TODO: comments are never updated because of the way sidebar is opened. consider getting comments inside this component
  useEffect(() => {
    handleFilterComments();
  }, [selectedFilters, preparedComments]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="comments-sidebar">
      <div className="comments-sidebar__header">
        <h2 className="comments-sidebar__title">
          {title}
        </h2>
        <p className="comments-sidebar__subtitle">
          {subtitle}
        </p>
      </div>
      {onWriteCommentClick && (
        <Button
          mode="primary"
          outlined
          size="medium"
          onClick={onWriteCommentClick}
          label="Write a comment"
          icon="bi bi-plus-circle"
        />
      )}
      {isEmpty ? (
        <div className="comments-sidebar__empty">
          <img className="comments-sidebar__empty-image" src={Comments} alt="" />
          <p className="comments-sidebar__empty-text">No comments found.</p>
        </div>
      ) : (
        <>
          <div className="comments-sidebar__filters-container">
            <div className="comments-sidebar__tabs-header">
              <TabsHeader tabs={tabs} defaultTab={defaultTab} tabCounters={{ Unresolved: unresolvedCount }} />
            </div>
            {withFilter && (
              <div className="comments-sidebar__filter">
                <MultiSelect
                  labelType="placeholder"
                  width="full"
                  handleSelectedOptions={handleSetFilter}
                  placeholder="Filter by"
                  selected={[]}
                  size="full"
                  dropdownPosition="right"
                  options={commentTypeFilters.map((filter) => ({ name: filter, value: filter }))}
                />
              </div>
            )}
          </div>
          <TabsContent>
            <div data-tab="View all">
              <div className="comments-sidebar__comments">
                {filteredComments.map((comment: CommentFromCA) => (
                  <AdvisorCommentPreview
                    key={comment.id}
                    title={getCommentTitleBasedOnType(comment)}
                    comment={comment.comment}
                    imageUrl={comment.application?.company.logo_url}
                    dateAdded={formatDateDeltaFromNow(comment.created_at)}
                    isResolved={comment.resolved}
                    advisorName={comment.advisor_name}
                    handleResolveComment={(id) => handleResolveComment(id, comment.type)}
                    handleSeeComment={() => onSeeCommentClick(comment)}
                    companyName={comment.application?.company.name}
                    id={comment.id}
                    isResolveDisabled={isResolveDisabled}
                  />
                ))}
              </div>
              {filteredComments.length === 0 && (
                <div className="comments-sidebar__empty">
                  <img className="comments-sidebar__empty-image" src={Comments} alt="" />
                  <p className="comments-sidebar__empty-text">No comments found.</p>
                </div>
              )}
            </div>
            <div data-tab="Unresolved">
              <div className="comments-sidebar__comments">
                {unresolvedComments.map((comment: CommentFromCA) => (
                  <AdvisorCommentPreview
                    key={comment.id}
                    title={getCommentTitleBasedOnType(comment)}
                    comment={comment.comment}
                    imageUrl={comment.application?.company.logo_url}
                    dateAdded={formatDateDeltaFromNow(comment.created_at)}
                    isResolved={comment.resolved}
                    advisorName={comment.advisor_name}
                    handleResolveComment={(id) => handleResolveComment(id, comment.type)}
                    handleSeeComment={() => onSeeCommentClick(comment)}
                    companyName={comment.application?.company.name}
                    id={comment.id}
                    isResolveDisabled={isResolveDisabled}
                  />
                ))}
              </div>
              {unresolvedComments.length === 0 && (
                <div className="comments-sidebar__empty">
                  <img className="comments-sidebar__empty-image" src={Comments} alt="" />
                  <p className="comments-sidebar__empty-text">No unresolved comments found.</p>
                </div>
              )}
            </div>
          </TabsContent>
        </>
      )}
    </div>
  );
};

export default withTabs(CommentsHistorySidebar) as typeof CommentsHistorySidebar;
