import { ApiComment, CommentFromCA, CommentFromCAType } from '@/domains/core/advisor/types/api/comment';

export const convertFromApiCAComment = (apiComment: ApiComment): CommentFromCA => {
  const commentType: CommentFromCAType = apiComment.application.id.length > 0 ? 'application' : 'general';
  const applicationData = commentType === 'application' ? apiComment.application : null;

  return {
    ...apiComment,
    type: commentType,
    application: applicationData,
  };
};
