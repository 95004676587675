import { useContext, useEffect, useState } from 'react';

import { Loader } from '@/components/loader/loader';
import { useSelfUniversity } from '@/services/queries/user';

import '../styles/dashboard.scss';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';

export const universityAirtableLinks: { [key: string]: string } = { // TODO we need to make this dynamic, or have this in the API
  ESADE: 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrZen09HDfxi4nAa',
  ESMT: 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shr3lPdkiOTQiX8Ak',
  'Schiller International University': '',
  'Texas A&M': '',
  'Munich Business School': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shroumh8AALtWOj2k',
  HEC: 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrxcnSwJpfDA4io0',
  'WHU – Otto Beisheim School of Management': ' https://airtable.com/embed/appA1i5xrSzFKAxbB/shrSblkjkbZPxCkaK',
  'University of Maryland': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shryPyvvZFvTDBcnh',
  Tomorrow: '',
  'Católica Lisbon School of Business': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrOGAHxvkbQD5K7e',
  'Darla Moore School of Business, University of South Carolina': '',
  'Georgetown University': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrYSmI2ZdUj729sk',
  'Stanford Graduate School of Business': '',
  'Rice University': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shr2zI5jYnLQxkHSb',
  'SDA Bocconi School of Management': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrtKZfy0qExMWjMm',
  'London Business School': '',
  'University College Dublin': '',
  'University of Oxford Saïd Business School': '',
  'Copenhagen Business School': '',
  'The University of Edinburgh': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrFhNMb4tfDyde7W',
  'University of Georgia': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shr3mmtOfDxnaekry',
  'Rotterdam School of Management': '',
  'The University of Leeds': '',
  'EBS Business School': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrBOKcYjGDUH8DNm',
  'Antwerp Management School': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shri2Jl27QZlMnRDN',
  'HHL Leipzig Graduate School of Management': 'https://airtable.com/embed/appA1i5xrSzFKAxbB/shrYvEKpZZAawPTUF',
  'Bayes Business School': '',
  'Zeppelin University': '',
  ISEG: '',
  ESSEC: '',
  'Asia School of Business': '',
};

const FORM_SPACING = 78;

function InsightsPage() {
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight - FORM_SPACING);
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const [airtableLink, setAirtableLink] = useState('');
  const { addToast }: AddToastType = useContext(ToastContext);

  useEffect(() => {
    addToast({
      type: 'info',
      message: 'Insights are loading',
      additionalMessage: 'It should take less than 5 seconds...',
    });
    const updateHeight = () => {
      const height = window.innerHeight - FORM_SPACING;
      setIframeHeight(height);
    };

    // Initial height setting
    updateHeight();

    // Adding resize event listener
    window.addEventListener('resize', updateHeight);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  useAdvisorRoleCheck();

  useEffect(() => {
    if (!isUniversityLoading && universityAirtableLinks[university?.name || '']) {
      setAirtableLink(universityAirtableLinks[university?.name || '']);
    }
  }, [university, isUniversityLoading]);

  return (
    <div className="student-dashboard student-dashboard--form">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="form-wrapper form-wrapper--insights">
            <iframe title="Inisghts" className="airtable-embed" src={airtableLink} frameBorder="0" width="100%" height={iframeHeight} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(InsightsPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
