import { LastViewedResponse } from '@/domains/core/advisor/types/api/lastview';
import { http } from '@/services/api/http';

const supportedPageType = 'student_list';

export const getAdvisorLastViewedAt = async (): Promise<Date> => {
  const url = `/university/advisor/pageview/${supportedPageType}`;

  const res: LastViewedResponse = await http.get(url, 'v2');

  return new Date(res.viewed_at);
};

export const advisorViewedPage = async (viewedAt: Date): Promise<void> => {
  const url = `/university/advisor/pageview/${supportedPageType}`;

  await http.post(url, { viewed_at: viewedAt.toISOString() }, undefined, 'v2');
};
