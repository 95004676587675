import { useState } from 'react';
import {
  Button, DropdownList, IconButton, Tooltip,
} from '@careeros/coco';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { Tag, TagColors } from '@/components/tag/tag';
import './contact-header.scss';
import { formatResponderRate } from '@/domains/core/advisor/utils/format-responder-rate';
import { ContactTagType } from '@/domains/core/advisor/types';

type Props = {
  badges: ContactTagType;
  handleSaveButtonClick: (contactId: string) => void;
  image: string;
  name: string;
  position: string;
  companyName: string;
  isSaved: boolean;
  id: string;
  email: string;
  linkedInUrl: string;
};

export const ContactHeader = ({
  badges,
  handleSaveButtonClick,
  image,
  name,
  position,
  companyName,
  isSaved,
  id,
  email,
  linkedInUrl,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const tags = [
    ...(badges?.is_alum ? [{ tag_content: '🎓 Alum' }] : []),
    ...(badges?.is_recruiter ? [{ tag_content: '🔍 HR' }] : []),
  ];

  const responderRate = badges?.response_rate ? formatResponderRate(badges.response_rate) : 0;
  let rateModifier: 'red' | 'yellow' | 'green' = 'green';
  if (responderRate < 33) {
    rateModifier = 'red';
  } else if (responderRate < 66) {
    rateModifier = 'yellow';
  }
  const showBadges = responderRate !== 0 || badges?.is_alum === true || badges?.is_recruiter === true;

  const dropdownOptions = [
    ...(linkedInUrl ? [{
      label: 'Send LinkedIn Message',
      onClick: () => { window.open(linkedInUrl, '_blank'); },
      icon: 'bi bi-linkedin',
    }] : []),
    ...(email ? [{
      label: 'Send email',
      onClick: () => { window.open(`mailto:${email}`); },
      icon: 'bi bi-send',
    }] : []),
  ];

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="contact-header">
      <div className="contact-header__main-info">
        <div className="contact-header__company-logo">
          <DefaultLogo
            source={image}
            type="contact"
            name={name}
            className="contact-header__company-logo"
            data-testid="job-logo"
            size={48}
          />
        </div>
        <div className="contact-header__job-details">
          <div className="contact-header__job-title" data-testid="job-title">{name}</div>
          <div className="contact-header__job-subtitle">
            <div className="contact-header__job-company-name">{companyName}</div>
            •
            <p className="contact-header__job-company-position">{position}</p>
          </div>
        </div>
        <div
          className="contact-header__actions"
        >
          <Tooltip label={isSaved ? 'Unsave' : 'Save'} withArrow position="top">
            <IconButton
              data-testid="save-button"
              outlined
              size="large"
              mode="rounded"
              label={isSaved ? 'Saved' : 'Save'}
              icon={
                isSaved ? 'bi bi-bookmark-heart-fill' : 'bi bi-bookmark-heart'
              }
              handleClick={() => handleSaveButtonClick(id)}
            />
          </Tooltip>

          <Button
            size="medium"
            mode="primary"
            iconPosition="right"
            label="Message"
            icon="bi bi-chevron-down"
            handleClick={toggleDropdown}
          />

          {showDropdown && (
            <div className="contact-header__dropdown">
              <DropdownList
                options={dropdownOptions}
                handleClose={() => setShowDropdown(false)}
                iconSize="small"
              />
            </div>
          )}
        </div>
      </div>
      {showBadges && (
        <div className="contact-header__tags">
          {tags.map((tag) => (
            tag.tag_content === '🎓 Alum' ? (
              <div className="contact-header__with-tooltip">
                <Tooltip
                  key={tag.tag_content}
                  label="Students who studied at your university in the past"
                  position="top"
                  withArrow
                >
                  <Tag
                    color={TagColors.Grey}
                    label={tag.tag_content}
                  />
                </Tooltip>
              </div>
            ) : (
              <Tag
                key={tag.tag_content}
                color={TagColors.Grey}
                label={tag.tag_content}
              />
            )
          ))}
          {responderRate !== 0 && (
            <div className="contact-tile__with-tooltip">
              <Tooltip label={`Responded to ${responderRate}% of students`} position="top" withArrow>
                <Tag
                  color={TagColors.Grey}
                  label="Responder"
                  marker={`${responderRate}%`}
                  markerColor={rateModifier}
                />
              </Tooltip>
            </div>
          )}
        </div>
      )}
      <div className="contact-header__contacts">
        {linkedInUrl && (
          <div className="contact-header__contact">
            <i className="bi bi-linkedin" />
            <a href={linkedInUrl} target="_blank" rel="noreferrer">{linkedInUrl}</a>
          </div>
        )}
        {email && (
          <div className="contact-header__contact">
            <i className="bi bi-envelope" />
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
          </div>
        )}
      </div>
    </div>
  );
};
