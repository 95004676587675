import { AccentColorType } from '@/components/action-card/action-card';
import { AvatarStackItem } from '@/components/avatar-stack/avatar-stack';
import { DasboardActionTileType, DashboardActionTile } from '../../types';
import PersonImage1 from '@/assets/images/fake-people/maria.jpg';
import PersonImage2 from '@/assets/images/fake-people/bessie.jpg';
import PersonImage3 from '@/assets/images/fake-people/cody.jpg';
import PersonImage4 from '@/assets/images/fake-people/wade.jpg';

type TileProperties = {
  title: string;
  type: DasboardActionTileType;
  imageStack: AvatarStackItem[];
  backgroundIcons: [string, string];
  accentColor?: AccentColorType;
  isBlurredImageStack?: boolean;
  withImageStackHover?: boolean;
  withImageStackTooltip?: boolean;
  link?: string;
  defaultImageStackTooltip?: string;
};

const tileDataAppearance: Record<DasboardActionTileType, TileProperties> = {
  companies: {
    type: DasboardActionTileType.CompaniesCard,
    title: 'Find new dream companies',
    imageStack: [],
    backgroundIcons: ['bi bi-bookmark-heart', 'bi bi-bookmark-heart-fill'],
    accentColor: 'information',
    isBlurredImageStack: true,
    withImageStackHover: true,
    withImageStackTooltip: true,
    link: '/app/companies/explore',
    defaultImageStackTooltip: 'Amazing Company 🤫',
  },
  inbox: {
    type: DasboardActionTileType.Inbox,
    title: 'Send or reply to a message or email',
    imageStack: [],
    backgroundIcons: ['bi bi-chat', 'bi bi-chat-fill'],
    accentColor: 'secondary40',
    withImageStackHover: true,
    withImageStackTooltip: true,
    link: '/app/inbox',
  },
  contacts: {
    type: DasboardActionTileType.Network,
    title: 'Manage & expand your network',
    imageStack: [],
    backgroundIcons: ['bi bi-person-vcard', 'bi bi-person-vcard-fill'],
    accentColor: 'primary30',
    isBlurredImageStack: true,
    withImageStackHover: true,
    withImageStackTooltip: true,
    link: '/app/contacts',
    defaultImageStackTooltip: 'Amazing Contact 🤫',
  },
  applications: {
    type: DasboardActionTileType.Applications,
    title: 'Manage your applications',
    imageStack: [],
    backgroundIcons: ['bi bi-send', 'bi bi-send-fill'],
    accentColor: 'tertiary50',
    withImageStackHover: true,
    withImageStackTooltip: true,
    link: '/app/overview',
  },
  resume: {
    type: DasboardActionTileType.Resume,
    title: 'Tailor or create a new resume for a job',
    imageStack: [],
    backgroundIcons: ['bi bi-file-earmark', 'bi bi-file-earmark-fill'],
    accentColor: 'warning-light',
    withImageStackHover: true,
    withImageStackTooltip: true,
    link: '/app/resume-os',
  },
  'ai-tools': {
    type: DasboardActionTileType.AITools,
    title: 'Use AI to learn about companies & roles',
    imageStack: [],
    backgroundIcons: ['bi bi-lightbulb', 'bi bi-lightbulb-fill'],
    accentColor: 'blue-green70',
    withImageStackHover: true,
    withImageStackTooltip: true,
  },
  jobs: {
    type: DasboardActionTileType.Jobs,
    title: 'Browse open job postings',
    imageStack: [],
    backgroundIcons: ['bi bi-briefcase', 'bi bi-briefcase-fill'],
    accentColor: 'primary50',
    isBlurredImageStack: true,
    withImageStackHover: true,
    withImageStackTooltip: true,
    link: '/app/jobs',
    defaultImageStackTooltip: 'Amazing Company 🤫',
  },
  resources: {
    type: DasboardActionTileType.Resources,
    title: 'Explore resources from your university',
    imageStack: [],
    backgroundIcons: ['bi bi-book', 'bi bi-book-half'],
    accentColor: 'error20',
  },
};

const fakeContactsForBlurredStack = [
  {
    id: '1',
    name: 'Amazing Contact 🤫',
    avatar: PersonImage1,
  },
  {
    id: '2',
    name: 'Amazing Contact 🤫',
    avatar: PersonImage2,
  },
  {
    id: '3',
    name: 'Amazing Contact 🤫',
    avatar: PersonImage3,
  },
  {
    id: '4',
    name: 'Amazing Contact 🤫',
    avatar: PersonImage4,
  },
];

export const parseSindleTile = (tile: DashboardActionTile): TileProperties => {
  const tileProperties = tileDataAppearance[tile.type];
  let imageStack = tile.data?.map((data) => ({
    name: tileProperties.defaultImageStackTooltip || data.name,
    avatar: data.image_url,
    id: data.id,
  }));

  if (!imageStack) {
    imageStack = tile.type === DasboardActionTileType.Network ? fakeContactsForBlurredStack : [];
  }

  return {
    ...tileProperties,
    imageStack,
  };
};

export function parseApiActionTiles(tilesFromApi: DashboardActionTile[]): TileProperties[] {
  const sortedTiles = tilesFromApi.sort((a, b) => a.order - b.order);

  return sortedTiles.map(parseSindleTile);
}
