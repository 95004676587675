import ESADELogo from '@/assets/images/university/esade-logo.jpeg';
import StanfordLogo from '@/assets/images/university/Standford_GSB.png';
import VMockLogo from '@/assets/images/vmock-logo.png';
import HandShakeLogo from '@/assets/images/handshake.svg';
import GeorgetownLogo from '@/assets/images/hs-school-logo-data.png';

export const ESADE_MASTER_PROGRAMS = ['MSM', 'MiM', 'MMkt', 'CEMS', 'MiBA', 'MFin', 'MIE'];
export const ESADE_EXECUTIVE_PROGRAMS = [
  'EMBA Blended Co 2026',
  'EMBA Blended Co 2025',
  'EMBA Monthly Bcn Co 2026',
  'EMBA Weekly Co 2025',
  'EMBA Weekly Co 2026',
  'EMBA Monthly Madrid Co 2025',
  'EMBA Monthly Bcn Co 2025',
  'EMBA Monthly Madrid Co 2026',
];

type UniversityResources = {
  name: string;
  description: string;
  image: string;
  linkLabel: string;
  link: string;
  onlyForCohorts?: string[];
  excludedCohorts?: string[];
};

type UniversityResourcesData = {
  data: UniversityResources[];
  excludedCohorts?: string[];
};

type UniversityResourcesMap = {
  [key: string]: UniversityResourcesData;
};

export const dashboardUniversityResources = (selfId: string): UniversityResourcesMap => ({
  'Georgetown University': {
    data: [
      {
        name: 'SFSCC Course on Canvas',
        description: 'Career resources covering a variety of topics, including resumes, interviewing, and networking',
        image: GeorgetownLogo,
        linkLabel: 'Visit now',
        link: 'https://georgetown.instructure.com/courses/60142',
      },
      {
        name: 'Handshake',
        description: 'Launch the next step in your career.',
        image: HandShakeLogo,
        linkLabel: 'Visit now',
        link: 'https://georgetown.joinhandshake.com',
      },
    ],
  },
  'Stanford Graduate School of Business': {
    data: [
      {
        name: 'Stanford | goCanvas',
        description: 'Over 2,400 instructors and 19,000 students use Canvas in 2,000 courses every quarter across Stanford’s seven schools.',
        image: StanfordLogo,
        linkLabel: 'Visit now',
        link: 'https://www.gsb.stanford.edu/',
      },
    ],
  },
  ESADE: {
    excludedCohorts: ESADE_EXECUTIVE_PROGRAMS,
    data: [
      {
        name: 'Build a LinkedIn Profile that is a Recruiter Magnet',
        description: 'Master the most advanced Job Search techniques through this LinkedIn Masterclass',
        image: ESADELogo,
        linkLabel: 'Visit now',
        link: 'https://bit.ly/liesade',
        onlyForCohorts: ESADE_MASTER_PROGRAMS,
      },
      {
        name: 'Optimize the content on your LinkedIn Profile',
        description: 'Ensuring alignment with your target career roles & your CV',
        image: VMockLogo,
        linkLabel: 'Visit now',
        link: 'https://www.vmock.com/esade',
        onlyForCohorts: ESADE_MASTER_PROGRAMS,
      },
      {
        name: 'ESADE Action Plan',
        description: 'Check your Career Action Plan with your Career Advisor',
        image: ESADELogo,
        linkLabel: 'Update Plan',
        link: `/app/action-plan/${selfId}`,
        excludedCohorts: ESADE_MASTER_PROGRAMS,
      },
      {
        name: 'MBA Career Hub',
        description: 'Visit this channel for Internal Content & Communication',
        image: ESADELogo,
        linkLabel: 'Visit now',
        link: 'https://urledu.sharepoint.com/sites/EsadeMBACareers',
        excludedCohorts: ESADE_MASTER_PROGRAMS,
      },
    ],
  },
});

export const universitiesWithResources = Object.keys(dashboardUniversityResources('selfId'));

export const checkIfCohortHasResources = (cohort: string, university: string, selfId: string): boolean => {
  if (!dashboardUniversityResources(selfId)[university] || dashboardUniversityResources(selfId)[university]?.excludedCohorts?.includes(cohort)) {
    return false;
  }

  const resources = dashboardUniversityResources(selfId)[university]?.data || [];

  if (!resources) {
    return false;
  }

  const cohortResources = resources.filter((resource) => {
    if (resource.onlyForCohorts) {
      return resource.onlyForCohorts.includes(cohort);
    }
    if (resource.excludedCohorts) {
      return !resource.excludedCohorts.includes(cohort);
    }
    return true;
  });

  return cohortResources.length > 0;
};
