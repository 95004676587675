import { getConversations, getUserStatistic } from '@/services/api/user';
import { getLeaderboard } from '@/services/api/student';
import { getPendingContacts } from '@/services/api/contact';

const defaultUserStatistic = {
  weekly_goals: {
    saved_contacts: 0,
    sent_linkedin_invitations: 0,
    saved_companies: 0,
    saved_companies_target: 5,
    saved_contacts_target: 5,
    sent_linkedin_invitations_target: 5,
  },
  challenges: {
    saved_contacts: 0,
    sent_linkedin_invitations: 0,
    saved_companies: 0,
    save_company_challenge_status: '',
    save_contact_challenge_status: '',
    send_request_challenge_status: '',
  },
};

export async function dashboardLoader() {
  // Kick off all requests simultaneously
  const [conversationsResponse, userStatisticResponse, pendingContacts, leaderboardResponse] = await Promise.all([
    getConversations(),
    getUserStatistic(),
    getPendingContacts(),
    getLeaderboard(),
  ]);

  const statistic = userStatisticResponse || defaultUserStatistic;

  return {
    conversationIDs: conversationsResponse || [],
    statistic,
    pendingContacts: !pendingContacts || pendingContacts.error ? [] : pendingContacts,
    leaderboard: leaderboardResponse || [],
  };
}
