import { http } from './http';

export const getTallyData = async (userID: string) => http.get(`/users/${userID}/onboarding`);

export const getStudentProfileData = async (studentID: string) => {
  const response = await http.get(`/student/${studentID}/profile`);

  if (!response) {
    return {};
  }

  return response;
};
