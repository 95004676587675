import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import RocketEmoji from '@/assets/images/rocket-emoji.png';
import PersonImage1 from '@/assets/images/fake-people/maria.jpg';
import PersonImage2 from '@/assets/images/fake-people/bessie.jpg';
import PersonImage3 from '@/assets/images/fake-people/cody.jpg';
import PersonImage4 from '@/assets/images/fake-people/wade.jpg';
import PersonImage5 from '@/assets/images/fake-people/darlene.jpg';
import FakeCompany1 from '@/assets/images/vmock-logo.png';
import FakeCompany2 from '@/assets/images/linkedin.svg';
import FakeCompany3 from '@/assets/images/mba-exchange-logo.jpg';
import FakeCompany4 from '@/assets/images/hs-school-logo-data.png';
import FakeCompany5 from '@/assets/images/vault-logo.webp';

import './suggested-contacts-empty.scss';
import { SuggestedContact } from '../suggested-contact/suggested-contact';

const mockedSuggestedContacts = [
  {
    name: 'Maria Beatriz Fernandes Frias e Carmo Ferreira',
    position: 'Software Engineer',
    image: PersonImage1,
    languages: ['English', 'Spanish'],
    isAlumni: true,
    companyLogo: FakeCompany1,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 3,
  },
  {
    name: 'Bessie Cooper Bessie Cooper',
    position: 'Software Engineer',
    image: PersonImage2,
    languages: [],
    isAlumni: true,
    companyLogo: FakeCompany2,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 0,
  },
  {
    name: 'Cody Fisher',
    position: 'Software Engineer',
    image: PersonImage3,
    languages: ['English', 'Spanish'],
    isAlumni: true,
    companyLogo: FakeCompany3,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 1,
  },
  {
    name: 'Wade Warren',
    position: 'Software Engineer',
    image: PersonImage4,
    languages: ['English', 'Spanish'],
    isAlumni: true,
    companyLogo: FakeCompany4,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 2,
  },
  {
    name: 'Darlene Robertson',
    position: 'Software Engineer',
    image: PersonImage5,
    languages: ['English'],
    isAlumni: false,
    companyLogo: FakeCompany5,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 0,
  },
];

export const SuggestedContactsEmpty = () => {
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="suggested-contacts-empty">
      <div className="suggested-contacts-empty__container">
        {mockedSuggestedContacts.map((contact) => (
          <SuggestedContact
            key={contact.name}
            id={contact.name}
            name={contact.name}
            position={contact.position}
            image={contact.image}
            languages={contact.languages}
            isAlumni={contact.isAlumni}
            companyLogo={contact.companyLogo}
            companyName={contact.companyName}
            schoolName={contact.schoolName}
            networkingStatusRate={contact.networkingStatusRate}
            isBlurred
            handleMainButtonAction={() => setIsPromptVisible(true)}
          />
        ))}
      </div>
      {isPromptVisible && (
        <div className="suggested-contacts-empty__prompt">
          <img src={RocketEmoji} alt="" className="suggested-contacts-empty__prompt-image" />
          <h3 className="suggested-contacts-empty__prompt-title">Almost ready for take-off</h3>
          <p className="suggested-contacts-empty__prompt-description">
            To be able to browse suggested contacts, you need to first
            {' '}
            <mark>save 5 companies</mark>
          </p>

          <Button
            label="Explore Companies"
            mode="primary"
            size="medium"
            handleClick={() => navigate('/app/companies/explore')}
            icon="bi bi-box-arrow-up-right"
            iconPosition="right"
          />
        </div>
      )}
    </div>
  );
};
