import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@/components/loader/loader';
// import { KanbanBoard } from '@/domains/generic/kanban/kanban-board';
import {
  changeApplicationStatus,
} from '@/domains/core/company';
import { statuses } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { OverviewContent } from '@/domains/generic';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import {
  filterTiles, filterTilesByStatus, getAllSavedIndustries, sortByPosition,
} from '@/services/helpers/company';
import {
  FilterNameType, FilterOption, Tile,
} from '@/domains/core/company/types';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useApplicationsNoCache } from '@/services/queries/application';
import './styles/overview.scss';
import { ModalContext } from '@/components/modal/modal-provider';
import { CommentsHistorySidebar } from '@/domains/generic/sidebars';
import { CommentFromCA, CommentFromCAType } from '@/domains/core/advisor/types';
import { resolveComment } from '@/domains/core/advisor/api/comment';
import { CommentPreviewBox } from '@/domains/core/advisor/components/comment-preview-box/comment-preview-box';

const companyStatuses: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Rejected',
];

function OverviewPage() {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { data: tiles = [], isLoading: isTilesLoading, isFetching: isFetchingTiles } = useApplicationsNoCache();
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const { addToast }: AddToastType = useContext(ToastContext);
  const [kanbanLayout, setKanbanLayout] = useState({});
  const [currentFilters, setCurrentFilters] = useState<{ [name in FilterNameType]: FilterOption[] }>();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [industries, setIndustries] = useState(getAllSavedIndustries(tiles));
  const [searchValue, setSearchValue] = useState('');
  const [filteredTilesByStatus, setFilteredTilesByStatus] = useState<Tile[]>([]);
  const queryClient = useQueryClient();
  const areFiltersEmpty = (!currentFilters || Object.values(currentFilters).filter((v) => v && v.length > 0).length === 0) && !searchValue.length;

  const computeKanbanLayout = (allCompanies: Tile[]) => {
    if (!allCompanies || !Array.isArray(allCompanies)) {
      setKanbanLayout({});
      return;
    }
    const kanban = {
      'Company Saved': sortByPosition(allCompanies.filter((company: Tile) => company.status === 'saved')),
      Networking: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'networking')),
      Applying: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applying')),
      Applied: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applied')),
      Interviewing: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'interviewing')),
      Offer: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'offer')),
      Rejected: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'reject')),
    };
    setKanbanLayout(kanban);
  };

  const handleFiltering = (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => {
    setCurrentFilters(filters);
    setSearchValue(searchQuery);
    const filteredResult = filterTiles(tiles, filters, searchQuery);
    const tilesFilteredByStatusRes = filterTilesByStatus(filteredResult, Object.values(filters.status));

    setFilteredTilesByStatus(tilesFilteredByStatusRes);
    computeKanbanLayout(filteredResult);
  };

  const handleResolveComment = async (commentID: string, commentType: CommentFromCAType): Promise<void> => {
    const response = await resolveComment(commentID, commentType);
    if (response) {
      queryClient.invalidateQueries(['studentComments']);
    }
  };

  const openCommentPreview = (comment: CommentFromCA) => {
    const commentTitle = comment.application?.company.name || 'General Comment';
    closeSidebar();
    openModal(
      <CommentPreviewBox
        companyName={comment.application?.company.name}
        companyLogo={comment.application?.company.logo_url}
        title={commentTitle}
        closeCommentBox={closeModal}
        commentText={comment.comment}
        id={comment.id}
        handleMarkAsResolved={(id) => handleResolveComment(id, comment.type)}
        isResolved={comment.resolved}
      />,
    );
  };

  const openFullCommentHistory = () => {
    openSidebar(
      <CommentsHistorySidebar
        withFilter
        title="Full Comments History"
        subtitle="All comments for student’s entire Overview Board."
        onSeeCommentClick={openCommentPreview}
      />,
    );
  };

  const trackStatusChange = (status: string, newStatus: string, companyName: string) => {
    if (status === newStatus) {
      return;
    }

    if (newStatus !== 'Company Saved') {
      trackEvent(`Company moved to ${newStatus}`, user, { company: companyName });
    }

    trackEvent(`Overview Board: Company Dropped from ${status} to ${newStatus}`);
    trackEvent('Overview Board: Company Status change');
    trackEvent('Kanban Board Update', user);
  };

  // Handle Data
  const cardChangeHandler = async (cardInfo: any, newStatus: string) => {
    const {
      id, status, dropPosition,
    } = cardInfo;
    const newStatusForAPI = statuses[newStatus];
    const newKanban: any = { ...kanbanLayout };
    const oldKanban: any = { ...kanbanLayout };
    const tile = newKanban[status].find((company: Tile) => company.id === id);
    if (!tile) {
      return;
    }
    newKanban[status] = newKanban[status].filter((company: Tile) => company.id !== id);

    // Insert the tile at the specific index provided in dropPosition
    newKanban[newStatus].splice(dropPosition, 0, tile);

    setKanbanLayout(newKanban);
    trackStatusChange(status, newStatus, tile.company_name);

    const response = await changeApplicationStatus(id, newStatusForAPI, dropPosition);
    // Handle the API response and possible error states
    if (response.status === newStatusForAPI) {
      addToast({
        type: 'success',
        message: 'Company status updated',
        additionalMessage: 'The company status has been successfully updated.',
      });
    } else {
      addToast({
        type: 'error',
        message: 'Failed to update company status',
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update company status',
        error: response.error,
      });
      oldKanban[newStatus] = oldKanban[newStatus].filter((company: Tile) => company.id !== id);
      setKanbanLayout(oldKanban);
    }
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  useEffect(() => {
    if (isTilesLoading || isFetchingTiles || !Array.isArray(tiles)) {
      return;
    }

    if (!areFiltersEmpty && currentFilters) {
      handleFiltering(currentFilters, searchValue);
    } else {
      computeKanbanLayout(tiles);
      setFilteredTilesByStatus(tiles.filter((tile: Tile) => tile.status !== statuses.Archived));
    }

    setIndustries(getAllSavedIndustries(tiles));
  }, [tiles, isTilesLoading, isFetchingTiles]);

  useEffect(() => {
    trackEvent('Page Visit', {}, {
      pageURL: 'Dashboard',
    });
  }, []);

  return (
    <div className="overview-board" id="main">
      <OverviewContent
        columns={companyStatuses}
        layout={kanbanLayout}
        cardChangeHandler={cardChangeHandler}
        handleFiltersChange={handleFiltering}
        savedIndustries={industries}
        openFullCommentHistory={openFullCommentHistory}
        tiles={filteredTilesByStatus}
      />
    </div>
  );
}

export default CustomWithAuthenticationRequired(OverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
