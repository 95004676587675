import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Input } from '@/components/input/input';
import '../styles/people.scss';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { StudentTableColumns } from '@/domains/core/advisor';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { Loader } from '@/components/loader/loader';
import { useSelfUniversity } from '@/services/queries/user';
import { FilterOption, Tile } from '@/domains/core/company/types';
import { excludedCAs } from '@/domains/core/advisor/constants/excluded';
import {
  buildCohortsWithAll,
  mapStudentsToTableData,
} from '@/services/helpers/advisor';
import { TableRow } from '@/components/table/table-types';
import { useGetUniversityTags } from '@/services/queries/tag';
import { TagType } from '@/components/tag/tag';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { ExportStudentData } from '@/domains/core/advisor/components/export-student-data/export-student-data';
import { useStudentsByUniversity } from '@/services/hooks/use-students-by-university';
import Table from '@/components/table/table';
import { Student } from '@/domains/core/advisor/types';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';

function StudentsPage() {
  const { universityID, cohortID } = useParams();
  const { data: university } = useSelfUniversity();
  const [parsedStudents, setParsedStudents] = useState<TableRow[]>([]);
  const [searchStudentsValue, setSearchStudentsValue] = useState('');
  const [filters, setFilters] = useState<{
    tags:(string | JSX.Element)[];
    search: string;
  }>({
        tags: [],
        search: '',
      });

  const [searchParams, setSearchParams] = useSearchParams();
  const { data: tags } = useGetUniversityTags(
    universityID || university?.id || '',
    {
      enabled: !!universityID || !!university?.id, // Only fetch when a valid universityID is available
    },
  );

  const {
    data: studentsResponse,
    cohorts,
    isCohortsLoading,
    isStudentsLoading,
  } = useStudentsByUniversity('students', universityID);

  const parseStudents = (unParsedStudents: any, applications: Tile[]) => {
    const pStudents = mapStudentsToTableData(
      unParsedStudents,
      applications,
      tags || [],
    );
    setParsedStudents(pStudents);
  };

  const filteredStudents = useMemo(() => {
    if (!studentsResponse) return [];

    const studentsData = studentsResponse.map((student: any) => ({
      ...student,
      name: `${student.first_name} ${student.last_name}`,
      type: 'MBA Student',
      logged_in: true,
    }));

    const nonExcludedStudents = studentsData.filter((student) => !excludedCAs.includes(student.email));

    const matchesTagFilters = (student: Student) => !filters.tags.length || student?.tags?.some((tag: TagType) => filters.tags.includes(tag.label));

    const matchesSearchFilter = (student: Student) => {
      const searchTerm = filters.search.toLowerCase().trim();
      const fullName = `${student.first_name} ${student.last_name}`.toLowerCase();
      const email = student.email.toLowerCase();

      return !filters.search || fullName.includes(searchTerm) || email.includes(searchTerm);
    };

    return nonExcludedStudents.filter((student) => matchesTagFilters(student) && matchesSearchFilter(student));
  }, [studentsResponse, filters.tags, filters.search]);

  const tilesData = useMemo(() => filteredStudents.map((student) => {
    const applications = student.applications || [];
    return applications.filter((tile: any) => tile.company_name !== 'CareerOS');
  }), [filteredStudents]);

  const searchStudentByName = (name: string) => {
    setSearchStudentsValue(name);
    setFilters((prev) => ({ ...prev, search: name }));
    searchParams.set('search', name);
    setSearchParams(searchParams);
  };

  const handleTagFiltering = (options: FilterOption[]) => {
    const filterBy = options.map((option: FilterOption) => option.name);
    searchParams.set('tags', JSON.stringify(filterBy));
    setSearchParams(searchParams);
    setFilters((prev) => ({ ...prev, tags: filterBy }));
  };

  const cohortsWithAll = useMemo(() => {
    if (!cohorts) return [];
    return buildCohortsWithAll(
      '/app/students',
      cohorts,
      universityID,
      university?.id,
    );
  }, [cohorts]);

  const tagOptions = useMemo(() => (Array.isArray(tags)
    ? tags.map((tag: any) => ({ name: tag.label, value: tag.id }))
    : []), [tags]);

  const selectedTags = useMemo(() => {
    const hasNoTags = !filters.tags.length;
    const hasNoOptions = !tagOptions.length;
    if (hasNoTags || hasNoOptions) return [];

    const mappedTags = filters.tags.map((tag: string | JSX.Element) => {
      const matchingTagOption = tagOptions.find(
        (tagOption: FilterOption) => tagOption.name === tag,
      );

      return matchingTagOption ?? null;
    });

    const validTags = mappedTags.filter(tag => tag) as FilterOption[];
    return validTags;
  }, [filters, tags]);

  useAdvisorRoleCheck();

  useEffect(() => {
    const searchFromUrl = searchParams.get('search') || '';
    const tagsFromUrl = searchParams.get('tags')
      ? JSON.parse(searchParams.get('tags') || '[]')
      : [];

    setSearchStudentsValue(searchFromUrl);
    setFilters({ search: searchFromUrl, tags: tagsFromUrl });
  }, []);

  useEffect(() => {
    if (filteredStudents.length && tilesData.length) {
      parseStudents(filteredStudents, tilesData);
    }
  }, [filteredStudents, tilesData]);

  if (isCohortsLoading || isStudentsLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-dashboard">
      <div className="advisor-dashboard__top-container">
        <div className="advisor-dashboard__top">
          <CohortsNavigation links={cohortsWithAll} defaultLinkName="All Cohorts" withBorderBottom />
        </div>
        {university && (<ExportStudentData universityID={university.id || universityID} cohortID={cohortID} />)}
      </div>

      <div className="advisor-dashboard__content">
        <div className="career-os-grid">
          <div className="width-6/24">
            <MultiSelect
              handleSelectedOptions={handleTagFiltering}
              labelType="number"
              options={tagOptions}
              placeholder="Filter by tags"
              selected={selectedTags}
              size="full"
              withSelectAllOption
            />
          </div>
          <div className="width-18/24">
            <Input
              icon="bi bi-search"
              value={searchStudentsValue}
              id="search-students"
              label=""
              placeholder="Search Students by Name or Email"
              handleValueChange={searchStudentByName}
            />
          </div>
          <div
            className={`width-24/24 student__table ${
              university?.name === 'ESADE'
                ? 'student__table--esade'
                : 'student__table--students'
            }`}
          >
            {studentsResponse.length > 0 ? (
              <Table
                data={parsedStudents}
                columns={StudentTableColumns}
                withRowNumber
                hasTooltip={false}
              />
            ) : (
              <div className="student__table--no-data">
                <p>No students found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(StudentsPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
