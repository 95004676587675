import { useState } from 'react';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { MiniAdvisor, StudentModel } from '@/domains/core/advisor/types';
import { FilterOption } from '@/domains/core/company/types';
import { toggleAdvisorAssigment } from '@/domains/core/advisor/api/advisor';
import { AvatarStackWithDropdownSelect } from '@/components/avatar-stack-with-dropdown/avatar-stack-with-dropdown';

interface Props {
  student: StudentModel;
  allAdvisors: MiniAdvisor[];
  mappedAdvisors: FilterOption[];
}

export const AdvisorAssignment = ({
  student,
  allAdvisors,
  mappedAdvisors,
}: Props) => {
  const queryClient = useQueryClient();
  const [selectedAdvisors, setSelectedAdvisors] = useState<MiniAdvisor[]>(student.assignedAdvisors || []);

  const personListArray = selectedAdvisors.map((person) => ({
    id: person.id,
    name: `${person.firstName} ${person.lastName}`,
    avatar: person.profileImage,
  }));

  const handleAdvisorAssignment = async (element: FilterOption) => {
    try {
      const selectedAdvisor = allAdvisors.find((advisor) => advisor.id === element.value);
      const isAlreadySelected = selectedAdvisors.find(advisor => advisor.id === element.value);

      let newSelectedAdvisors;
      if (isAlreadySelected) {
        newSelectedAdvisors = selectedAdvisors.filter((advisor) => advisor.id !== element.value);
      } else {
        newSelectedAdvisors = [...selectedAdvisors, selectedAdvisor as MiniAdvisor];
      }

      setSelectedAdvisors(newSelectedAdvisors);
      await toggleAdvisorAssigment(student.id, element.value as string);
      queryClient.invalidateQueries(['allStudentsList']);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <AvatarStackWithDropdownSelect
      withAddButton
      personList={personListArray}
      handleSelectOption={handleAdvisorAssignment}
      fullList={mappedAdvisors}
      addButtonTooltip="Assign a Career Advisor"
      listTooltip={`Assigned CA: ${personListArray.map((a) => a.name).join(', ')}`}
    />
  );
};
