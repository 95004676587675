import { getStudentActionPlan, getStudentByID } from '@/services/api/student';

export async function actionPlanByStudentLoader(request: any) {
  const { studentID } = request.params;
  const student = await getStudentByID(studentID);
  const actionPlan = await getStudentActionPlan(studentID);

  return {
    student, planA: actionPlan?.planA, planB: actionPlan?.planB, planC: actionPlan?.planC,
  };
}
