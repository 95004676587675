import { useNavigate, useParams } from 'react-router-dom';
import {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Loader } from '@/components/loader/loader';
import '../styles/dashboard.scss';
import {
  CompaniesTableColumns,
  companyOptions,
  GraphWidget,
  industriesObject,
  industryOptions,
  networkingOptions,
} from '@/domains/core/advisor';
import { Input } from '@/components/input/input';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { IndustrySidebar } from '@/domains/generic';
import { statuses } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { TableRow } from '@/components/table/table-types';
import {
  buildCohortsWithAll,
  mapCompaniesSavedByStudentsToTableData,
} from '@/services/helpers/advisor';
import { useSelfUniversity } from '@/services/queries/user';
import { Tile } from '@/domains/core/company/types';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { CompanyCount } from '@/domains/core/advisor/types';
import { useAdvisorData } from '@/services/hooks/use-student-data';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { ExportStudentData } from '@/domains/core/advisor/components/export-student-data/export-student-data';
import { useStudentsByUniversity } from '@/services/hooks/use-students-by-university';
import { processCompanyData } from '@/domains/core/advisor/utils/process-company-data';
import Table from '@/components/table/table';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';

export const universityLookerLinks: { [key: string]: string } = {
  ESADE: 'https://lookerstudio.google.com/embed/reporting/cc90d82b-025c-4c15-bc43-28181d5870cb/page/p_puu0ssrhnd',
  ESMT: 'https://lookerstudio.google.com/embed/reporting/1981357d-eb09-44e9-9421-05a106280c5b/page/p_puu0ssrhnd',
  'Schiller International University': '',
  'Texas A&M': '',
  'Munich Business School': 'https://lookerstudio.google.com/embed/reporting/63c7dc2a-6e10-4e03-a391-ae97356e11d2/page/p_puu0ssrhnd',
  HEC: 'https://lookerstudio.google.com/embed/reporting/07fcb101-88c3-4af4-a78f-a5b53bacd566/page/p_puu0ssrhnd',
  'WHU – Otto Beisheim School of Management': 'https://lookerstudio.google.com/embed/reporting/d162c7bc-0ffb-402f-acb4-b5466271c27f/page/p_puu0ssrhnd',
  'University of Maryland': 'https://lookerstudio.google.com/embed/reporting/5ace231a-32a2-4d09-a3c2-5f955fb32060/page/p_puu0ssrhnd',
  Tomorrow: '',
  'Católica Lisbon School of Business': 'https://lookerstudio.google.com/embed/reporting/8baee316-01d9-40f9-85ed-781a23f6fe16/page/p_puu0ssrhnd',
  'Darla Moore School of Business, University of South Carolina': '',
  'Georgetown University': 'https://lookerstudio.google.com/embed/reporting/a5bddb9a-9cc2-4e69-8c4f-5327c659034e/page/p_puu0ssrhnd',
  'Stanford Graduate School of Business': '',
  'Rice University': 'https://lookerstudio.google.com/embed/reporting/f6bbb4b9-b576-429a-8999-71294f0be758/page/p_puu0ssrhnd',
  'SDA Bocconi School of Management': 'https://lookerstudio.google.com/embed/reporting/1fad73fa-0834-4aa9-bfe8-ffc3cf61518c/page/p_puu0ssrhnd',
  'London Business School': '',
  'University College Dublin': '',
  'University of Oxford Saïd Business School': '',
  'Copenhagen Business School': '',
  'The University of Edinburgh': 'https://lookerstudio.google.com/embed/reporting/d8342173-f429-479d-a5cf-07107f8656f4/page/p_puu0ssrhnd',
  'University of Georgia': 'https://lookerstudio.google.com/embed/reporting/41834010-25b0-4015-9cba-b4c177d65a0d/page/p_puu0ssrhnd',
  'Rotterdam School of Management': '',
  'The University of Leeds': '',
  'EBS Business School': 'https://lookerstudio.google.com/embed/reporting/56377a41-53c1-438e-9935-8e2b5d674bb7/page/p_puu0ssrhnd',
  'Antwerp Management School': 'https://lookerstudio.google.com/embed/reporting/974da20d-3fa5-4f6c-9e21-0f42154162be/page/p_puu0ssrhnd',
  'HHL Leipzig Graduate School of Management': 'https://lookerstudio.google.com/embed/reporting/081d30e9-4c51-4011-ad39-f8e0df53a0c5/page/p_puu0ssrhnd',
  'Bayes Business School': '',
  'Zeppelin University': '',
  ISEG: '',
  ESSEC: '',
  'Asia School of Business': '',
  'University of CareerOS': 'https://lookerstudio.google.com/embed/reporting/ff042b2b-7309-41e1-b5a8-01d64baa932e/page/p_puu0ssrhnd',
};

function DashboardAdvisorPage() {
  const { trackEvent } = useAnalytics();
  const { universityID, cohortID } = useParams();
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const navigate = useNavigate();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const [searchCompanyValue, setSearchCompanyValue] = useState('');
  const [industries, setIndustries] = useState<any>([]);
  const [industriesCompanies, setIndustriesCompanies] = useState<any>([]);
  const [industryData, setIndustryData] = useState<any>(null);
  const [companiesData, setCompaniesData] = useState<any>(null);
  const [allCompanies, setAllCompanies] = useState<any[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<TableRow[]>([]);
  const [networkingData, setNetworkingData] = useState<any>(null);
  const [lookerLink, setLookerLink] = useState('');

  const {
    data: studentsResponse,
    cohorts,
    isCohortsLoading,
    isStudentsLoading,
  } = useStudentsByUniversity('students', universityID);

  const searchCompaniesByName = (name: string) => {
    if (!university) return;
    setSearchCompanyValue(name);
    const filteredAllCompanies = allCompanies?.filter((company: Tile) => company.company_name.toLowerCase().includes(name.toLowerCase().trim()));
    setFilteredCompanies(mapCompaniesSavedByStudentsToTableData(filteredAllCompanies, cohortID || '', universityID || university.id));
  };

  const computeIndustryData = (tilesData: Tile[]) => {
    const tilesArray = tilesData.flatMap((array: any) => array).filter(Boolean);
    const industryCount = tilesArray.reduce((acc: any, obj: any) => {
      const key = obj.industry;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    const sortedIndustries = Object.entries(industryCount)
      .sort((a: any, b: any) => b[1].length - a[1].length)
      .map(entry => entry[0]);

    const industriesValues = sortedIndustries.map((industry: any) => industryCount[industry].length || 0);
    const allIndustries = industriesObject.map((industry: any) => industry.name);

    return {
      industries: sortedIndustries,
      industriesCompanies: industryCount,
      industryData: {
        labels: [...sortedIndustries, ...allIndustries.filter((industry: any) => !sortedIndustries.includes(industry))],
        datasets: [{
          label: 'Saved Companies',
          data: industriesValues,
          backgroundColor: '#06A47E',
          color: '#ffffff',
        }],
      },
    };
  };

  const processIndustryData = useCallback((tilesData: Tile[]) => {
    const result = computeIndustryData(tilesData);
    setIndustries(result.industries);
    setIndustriesCompanies(result.industriesCompanies);
    setIndustryData(result.industryData);
  }, []);

  const handleCompanyClick = useCallback((companyIndex: number) => {
    const companyName = companiesData.labels[companyIndex];
    const company = allCompanies.find((cmp) => cmp.company_name === companyName);
    trackEvent('Company Overview Clicked');
    if (cohortID) {
      navigate(`/app/company-overview/${company?.company_id}/university/${universityID || university?.id}/${cohortID}`);
    } else {
      navigate(`/app/company-overview/${company?.company_id}/university/${universityID || university?.id}`);
    }
  }, [companiesData, cohortID]);

  const computeFilteredCompaniesData = (statusToReturn: string, topTenCompanies: string[], tilesData: Tile[]): number[] => {
    const tilesArray = tilesData.flat().filter(Boolean);
    const status = statuses[statusToReturn];

    const companyCountMap = tilesArray
      .filter(tile => tile.status === status)
      .reduce<CompanyCount>((acc, tile) => {
      acc[tile.company_name] = (acc[tile.company_name] || 0) + 1;
      return acc;
    }, {});

    return topTenCompanies.map(company => companyCountMap[company] || 0);
  };

  const computeCompaniesData = useCallback((tileData: Tile[], universityId: string, cohortId: string) => processCompanyData(tileData, universityId, cohortId, computeFilteredCompaniesData), [cohortID]);

  const processCompaniesData = useCallback((tileData: Tile[]) => {
    if (!university) return;

    const result = computeCompaniesData(tileData, universityID || university.id, cohortID || '');
    setAllCompanies(result.allCompanies);
    setFilteredCompanies(result.filteredCompanies);
    setCompaniesData(result.companiesData);
  }, [university, universityID, cohortID]);

  const computeNetworkingData = useCallback((studentsData: any) => {
    const networkingStudents = studentsData.filter((student: any) => student?.messages_count?.messages_sent > 0).length;
    const networkingStudentsPercentage = Math.floor((networkingStudents / studentsData.length) * 100);
    const networkingDataMock = {
      labels: ['Networking', 'Not Networking'],
      datasets: [
        {
          label: '% of Students Networking',
          data: [networkingStudentsPercentage, 100 - networkingStudentsPercentage],
          backgroundColor: [
            '#98A6FD',
            '#484F7C',

          ],
        },
      ],
    };
    setNetworkingData(networkingDataMock);
  }, [cohortID]);

  const {
    isLoading,
    tiles,
    students,
  } = useAdvisorData(
    studentsResponse,
    universityID,
    cohortID,
    processCompaniesData,
    processIndustryData,
    computeNetworkingData,
  );

  const handleCompanySidebarClick = (id: string) => {
    if (cohortID) {
      navigate(`/app/company-overview/${id}/university/${universityID}/${cohortID}`);
    } else {
      navigate(`/app/company-overview/${id}/university/${universityID || university?.id}`);
    }
  };

  const handleIndustryClick = useCallback((industryIndex: number) => {
    const industry: string = industries[industryIndex];
    const industryCompanies = industriesCompanies[industry];

    const uniqueCompanies: any = [];
    const result: Tile[] = industryCompanies.map((obj: any) => {
      // Check if company already exists in the uniqueCompanies array
      const company = uniqueCompanies
        .find((c: any) => c.company_name === obj.company_name && c.industry === obj.industry);

      if (company) {
        // If company already exists, increment count
        company.count += 1;
        return null;
      }
      // If company doesn't exist, add it to the uniqueCompanies array and set count to 1
      const newCompany = { ...obj, count: 1 };
      uniqueCompanies.push(newCompany);
      return newCompany;
    }).filter(Boolean);
    trackEvent('Clicked on Industry breakdown');

    openSidebar(<IndustrySidebar industry={industry} companies={result} closeSidebar={closeSidebar} onClick={handleCompanySidebarClick} />);
  }, [industries, industriesCompanies]);

  const cohortsWithAll = useMemo(() => {
    if (!cohorts) return [];
    return buildCohortsWithAll('/app/dashboard-advisor', cohorts, universityID, university?.id);
  }, [cohorts]);

  useAdvisorRoleCheck();

  useEffect(() => {
    if (tiles.length > 0) {
      processIndustryData(tiles);
      processCompaniesData(tiles);
    }
    if (students.length > 0) {
      computeNetworkingData(students);
    }
  }, [tiles]);

  useEffect(() => {
    if (!isUniversityLoading && universityLookerLinks[university?.name || '']) {
      setLookerLink(universityLookerLinks[university?.name || '']);
    }
  }, [university, isUniversityLoading]);

  if (isCohortsLoading || isStudentsLoading || isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-dashboard">
      <div className="advisor-dashboard__top-container">
        <div className="advisor-dashboard__top">
          <CohortsNavigation
            links={cohortsWithAll}
            defaultLinkName="All Cohorts"
            withBorderBottom
          />
        </div>
        {university && (<ExportStudentData universityID={university.id || universityID} cohortID={cohortID} />)}
      </div>

      {studentsResponse.length > 0 ? (
        <div className="advisor-dashboard__content">
          <div className="career-os-grid">
            <div className="width-6/24">
              {networkingData && (
                <GraphWidget
                  title="Student Networking Overview"
                  subtitle="Networking Criteria: Has initiated conversations from at least one contact in CareerOS."
                  chartType="doughnut"
                  chartClicked={() => { }}
                  options={networkingOptions}
                  data={networkingData}
                />
              )}
            </div>
            <div className="width-18/24">
              {industryData && (
              <GraphWidget
                title="Top Industries on CareerOS"
                subtitle="Click on an industry bar to view the engagement with all the companies in that industry."
                chartType="bar"
                chartClicked={handleIndustryClick}
                options={industryOptions}
                data={industryData}
              />
              )}
            </div>
            <div className="width-24/24">
              {companiesData && (
                <GraphWidget
                  title="Top 10 Companies Engagement"
                  subtitle="Click on a company bar to view Student Activity with each company"
                  chartType="bar"
                  chartClicked={handleCompanyClick}
                  options={companyOptions}
                  data={companiesData}
                />
              )}
            </div>
            <div className="width-24/24">
              <Input
                icon="bi bi-search"
                value={searchCompanyValue}
                id="search-companies"
                label=""
                placeholder="Search Companies"
                handleValueChange={searchCompaniesByName}
              />
              <br />
              <div className="companies__table">
                <Table
                  data={filteredCompanies}
                  columns={CompaniesTableColumns}
                  hasTooltip={false}
                />
              </div>
            </div>
            {lookerLink && !cohortID && (
            <div className="width-24/24">
              <div className="jobs-section">
                <h3 className="graph-widget__title">
                  Saved Jobs Activity
                </h3>
                <div className="jobs-section__iframe">
                  <iframe title="Job Insights" className="airtable-embed" src={lookerLink} frameBorder="0" width="1366" height="1000" />
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      ) : (
        <div className="advisor-dashboard__content">
          <div className="advisor-dashboard__no-data">
            <p>No students found</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(DashboardAdvisorPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
