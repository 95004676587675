import { useMemo } from 'react';
import { Tooltip } from '@careeros/coco';
import { Tag, TagColors } from '@/components/tag/tag';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { ActionButtonSave } from '@/components/action-buttons/action-button-save';
import './contact-book-tile.scss';
import { formatResponderRate } from '@/domains/core/advisor/utils/format-responder-rate';
import { ContactType } from '@/domains/core/advisor/types';
import { AvatarStack } from '@/components/avatar-stack/avatar-stack';

type Props = {
  contact: ContactType;
  isActive?: boolean;
  isLoading: boolean;
  isSaved: boolean;
  onContactSaveToggle: (contactId: string) => void;
  onSelect: (contactId: string) => void;
};

export const ContactBookTile = ({
  contact,
  isActive = false,
  isSaved,
  isLoading,
  onContactSaveToggle,
  onSelect,
}: Props) => {
  const tags = [
    ...(contact?.badges?.is_alum ? [{ tag_content: '🎓 Alum' }] : []),
    ...(contact?.badges?.is_recruiter ? [{ tag_content: '🔍 HR' }] : []),
  ];

  const responderRate = contact?.badges?.response_rate ? formatResponderRate(contact.badges.response_rate) : 0;
  let rateModifier: 'red' | 'yellow' | 'green' = 'green';
  if (responderRate < 33) {
    rateModifier = 'red';
  } else if (responderRate < 66) {
    rateModifier = 'yellow';
  }

  const personList = contact.saved_by_students.map((student) => ({
    id: student.id,
    name: `${student.first_name} ${student.last_name}`,
    avatar: student.profile_picture_url,
  }));

  const savedByNames = useMemo(
    () => contact.saved_by_students.map((student) => student.first_name).join(', '),
    [contact.saved_by_students],
  );

  return (
    <div
      className={['contact-tile', isActive ? 'contact-tile--active' : ''].join(' ')}
      onClick={() => onSelect(contact.id)}
    >
      <div className="contact-tile__action" data-testid="contact-tile-loader">
        <ActionButtonSave
          onClick={() => onContactSaveToggle(contact.id)}
          isLoading={isLoading}
          iconName="bi-bookmark-heart"
          iconNameSaved="bi-bookmark-heart-fill"
          label="Save"
          labelSaved="Unsave"
          ariaLabel="Save Contact"
          ariaLabelSaved="Unsave Contact"
          isSaved={isSaved}
        />
      </div>
      <div className="contact-tile__main">
        <div className="contact-tile__main-top">
          <DefaultLogo
            source={contact.profile_picture_url}
            type="contact"
            name={contact.first_name}
            className="contact-tile__company-logo"
            size={32}
          />
          <p className="contact-tile__name">{`${contact.first_name} ${contact.last_name}`}</p>
        </div>
        <p className="contact-tile__company-name">
          {contact.current_position.company}
        </p>
      </div>
      <div className="contact-tile__bottom">
        <div className="contact-tile__with-tooltip">
          <AvatarStack personList={personList} listTooltip={`Contact was saved by: ${savedByNames}`} />
        </div>
        {tags.map((tag) => (
          tag.tag_content === '🎓 Alum' ? (
            <div className="contact-tile__with-tooltip">
              <Tooltip
                key={tag.tag_content}
                label="Students who studied at your university in the past"
                position="top"
                withArrow
              >
                <Tag
                  color={TagColors.Grey}
                  label={tag.tag_content}
                />
              </Tooltip>
            </div>
          ) : (
            <Tag
              key={tag.tag_content}
              color={TagColors.Grey}
              label={tag.tag_content}
            />
          )
        ))}
        {responderRate !== 0 && (
          <div className="contact-tile__with-tooltip">
            <Tooltip label={`Responded to ${responderRate}% of students`} position="top" withArrow>
              <Tag
                color={TagColors.Grey}
                label="Responder"
                marker={`${responderRate}%`}
                markerColor={rateModifier}
              />
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
