/* eslint-disable @typescript-eslint/no-use-before-define */
import { Link } from 'react-router-dom';
import {
  ReactNode, useEffect, useRef, useState,
} from 'react';
import { Tooltip } from '@careeros/coco';
import { TableRowItem } from '../table-types';
import './table-cell.scss';

type Props = {
  cellData: TableRowItem;
  type: string;
  isCentered?: boolean;
  isFitContent?: boolean;
};

export const TableCell = ({
  cellData,
  type,
  isCentered,
  isFitContent = false,
}: Props) => {
  const [isTextOverflow, setIsTextOverflow] = useState(false);
  const linkTextRef = useRef<HTMLSpanElement>(null);
  const cellTextRef = useRef<HTMLSpanElement | HTMLDivElement>(null);

  const handleLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (cellData.handleClick) {
      e.preventDefault();
      cellData.handleClick();
    }
  };
  const checkTitleOverflow = () => {
    const titleWidth = cellTextRef.current?.offsetWidth;
    const titleScrollWidth = cellTextRef.current?.scrollWidth;
    const linkWidth = linkTextRef.current?.offsetWidth;
    const linkScrollWidth = linkTextRef.current?.scrollWidth;

    if (titleWidth != null && titleScrollWidth != null && titleScrollWidth > titleWidth) {
      setIsTextOverflow(true);
    } else {
      setIsTextOverflow(false);
    }

    if (linkWidth != null && linkScrollWidth != null && linkScrollWidth > linkWidth) {
      setIsTextOverflow(true);
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(checkTitleOverflow);
    if (cellTextRef.current) {
      resizeObserver.observe(cellTextRef.current);
    }

    if (linkTextRef.current) {
      resizeObserver.observe(linkTextRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [cellTextRef.current, linkTextRef.current]);

  let content: ReactNode | string = '——';
  switch (type) {
    case 'text':
      content = (cellData !== null && cellData !== undefined) ? cellData.label : '——';
      break;
    case 'image':
      content = <img className="table__image" src={cellData.src} alt={cellData.alt || ''} />;
      break;
    case 'icon':
      content = <i className={`table-cell__icon ${cellData.src}`} />;
      break;
    case 'link-icon':
      content = (
        <Link onClick={(e) => e.stopPropagation()} to={cellData.to || ''} state={cellData.state}>
          <Tooltip label={cellData.tooltip || cellData.value?.toString()} withArrow position="top">
            <i className={`table-cell__icon ${cellData.icon}`} />
          </Tooltip>
        </Link>
      );
      break;
    case 'button-icon':
      content = (
        <div onClick={cellData.callback}>
          <Tooltip label={cellData.tooltip || cellData.value?.toString()} withArrow position="top">
            <i className={`table-cell__icon ${cellData.icon}`} />
          </Tooltip>
        </div>
      );
      break;
    case 'link':
      content = (
        <Link className="table-cell__link" onClick={handleLinkClick} to={cellData.to || ''} target={cellData.target || '_self'}>
          {cellData.image && (
            <div className="table-cell__link-image">
              {cellData.image}
            </div>
          )}
          <span ref={linkTextRef} className="table-cell__link-text">
            <span>
              {cellData.label}
            </span>
          </span>
        </Link>
      );
      break;
    default:
      content = cellData.label || '——';
  }

  return (
    <td className={`table-cell ${isCentered ? 'table-cell--centered' : ''} ${isFitContent ? 'table-cell--fit-content' : ''}`}>
      <Tooltip label={cellData.value?.toString()} position="top" disableHoverListener={!isTextOverflow}>
        <span ref={cellTextRef} className="table-cell__content">{content}</span>
      </Tooltip>
    </td>
  );
};
