const SMALLER_SCREEN_BREAKPOINT = 1480;
const TABLET_SCREEN_BREAKPOINT = 768;

export const isMobileDevice = (window.innerWidth <= SMALLER_SCREEN_BREAKPOINT)
            && window.matchMedia
            && window.matchMedia('(pointer: coarse)').matches;

export const isTabletDevice = (window.innerWidth <= TABLET_SCREEN_BREAKPOINT) || isMobileDevice;

export const setMobileVhUnit = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
