import { useState } from 'react';

import { Company } from '@/domains/core/company/types';
import { TabsContent } from '@/components/tabs';
import '../styles/company.scss';
import { BasicCompanyJobsTab } from '@/domains/core/company/components/basic-company-jobs-tab/basic-company-jobs-tab';
import { BasicCompanyHeader, CompanyOverviewContainer } from '@/domains/core/company';
import { Contact } from '@/domains/core/contact/types';

type BasicCompanyProfileProps = {
  contacts: Contact[];
  company: Company;
  articlesUrlProfile?: string;
  applicationInfo: {
    application_id: string;
    application_status: string;
  }
};

const BasicCompanyProfile = ({
  contacts, company, applicationInfo, articlesUrlProfile,
} : BasicCompanyProfileProps) => {
  const [jobsTabCount, setJobsTabCount] = useState(0);

  const updateJobsCount = (count: number) => {
    setJobsTabCount(count);
  };

  if (!company) {
    return (
      <>
        Company not found
      </>
    );
  }

  return (
    <>
      <div className="company-page__header">
        <BasicCompanyHeader
          name={company.name}
          logo={company.logo_url}
          tabs={['About Company', 'Jobs', articlesUrlProfile ? 'Articles' : '']}
          location={company.hq_location}
          isVerified={false}
          tabIcons={['bi bi-building', 'bi bi-briefcase', articlesUrlProfile ? 'bi bi-newspaper' : '']}
          jobsTabCounter={jobsTabCount}
        />
      </div>
      <div className="company-page__content">
        <TabsContent>
          <div className="company-page__overview" data-tab="About Company">
            <CompanyOverviewContainer contacts={contacts} company={company} application={applicationInfo} />
          </div>
          <div className="company-page__articles" data-tab="Articles">
            <div className="articles-container">
              <iframe
                src={`${articlesUrlProfile}`}
                className="articles-iframe"
                title="Articles"
              />
            </div>
          </div>
          <div className="company-page__jobs" data-tab="Jobs">
            <BasicCompanyJobsTab
              companyID={company.id}
              isCompanyApplicationActive={!!company.application_status && company.application_status !== 'archive'}
              companyName={company.name}
              companyLogo={company.logo_url}
              companyLinkedIn={company.linkedin_url}
              updateTabCount={updateJobsCount}
              applicationID={applicationInfo.application_id}
            />
          </div>
        </TabsContent>
      </div>
    </>
  );
};

export default BasicCompanyProfile;
