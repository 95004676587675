import { useEffect, useState } from 'react';

import './default-logo.scss';

function isValidImageSrc(src: string) {
  if (!src) return false;
  const pattern = /^https?:\/\/.+/;
  return pattern.test(src) || src.startsWith('/');
}

interface ContactImageProps {
  type: 'contact' | 'company' | 'job';
  source: string;
  className: string;
  handleClick?: () => void;
  name: string;
  icon?: string;
  // size?: 16 | 20 | 24 | 28 | 32 | 40 | 48 | 56 | 64 | 72 | 80;
  size?: number;
}
export const DefaultLogo = ({
  source,
  className,
  handleClick,
  name,
  type,
  icon,
  size = 28,
}: ContactImageProps) => {
  const [showDefaultImg, setShowDefaultImg] = useState(!isValidImageSrc(source));
  const handleImageError = () => {
    setShowDefaultImg(true);
  };
  const logoText = type === 'contact' ? name.split(' ').map(part => part[0]).join('') : name;

  useEffect(() => {
    setShowDefaultImg(!isValidImageSrc(source));
  }, [source]);

  return !showDefaultImg ? (
    <img
      onClick={handleClick}
      onError={handleImageError}
      src={source}
      className={className}
      alt=""
    />
  ) : (
    <div
      onClick={handleClick}
      className={`default-logo${type === 'company' ? ' default-logo--green' : ''} ${className}`}
      style={{ width: size, height: size, fontSize: size }}
    >
      {icon ? (
        <i className={`${icon} default-logo__icon`} />
      ) : (
        <span className="default-logo__text">{logoText}</span>
      )}
    </div>
  );
};
