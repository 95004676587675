import { useCookies } from 'react-cookie';
import { Button } from '@careeros/coco';
import './cookie-banner.scss';
import CookiePolicy from '@/assets/documents/GDPR Cookie Policy v1.0.1.pdf';

export const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['cookiesAccepted']);
  const handleCookieAccepted = () => {
    setCookie('cookiesAccepted', 'true', { path: '/' });
  };
  return (
    <div className={cookies.cookiesAccepted ? 'cookie-banner cookie-banner--accepted' : 'cookie-banner'}>
      <div className="cookie-banner__wrapper">
        <div className="cookie-banner__text">
          This website uses cookies to enhance the user experience. You can read more in our
          {' '}
          &nbsp;
          <a href={CookiePolicy} target="_blank" rel="noreferrer" aria-label="Cookie Policy (opens in a new window)">Cookie Policy</a>
        </div>
        <Button onClick={handleCookieAccepted} mode="primary" size="small" label="Ok" />
      </div>
    </div>
  );
};
