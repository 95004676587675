import { Button, IconButton, Tooltip } from '@careeros/coco';

type Props = {
  handleApplyButtonClick: () => void;
  handleSaveButtonClick: () => void;
  isSaved: boolean;
  isApplied: boolean;
  isIconCTA?: boolean;
  hiddenApplyButton: boolean;
};

export const JobHeaderCta = ({
  handleApplyButtonClick,
  handleSaveButtonClick,
  isSaved,
  isApplied,
  isIconCTA = false,
  hiddenApplyButton,
}: Props) => (
  isIconCTA ? (
    <>
      {!hiddenApplyButton && (
        <Tooltip label={isApplied ? 'Applied' : 'Apply'} position="top" withArrow>
          <IconButton
            size="medium"
            mode="rounded"
            outlined
            label="Apply"
            icon={isApplied ? 'bi bi-send-fill' : 'bi bi-send'}
            handleClick={handleApplyButtonClick}
          />
        </Tooltip>
      )}
      <Tooltip label={isSaved ? 'Saved' : 'Save'} position="top" withArrow>
        <IconButton
          data-testid="save-button"
          size="medium"
          mode="rounded"
          label={isSaved ? 'Saved' : 'Save'}
          icon={isSaved ? 'bi bi-bookmark-heart-fill' : 'bi bi-bookmark-heart'}
          handleClick={handleSaveButtonClick}
        />
      </Tooltip>
    </>
  ) : (
    <>
      {!hiddenApplyButton && (
        <Button
          size="medium"
          mode="primary"
          outlined
          iconPosition="right"
          label="Apply"
          icon="bi bi-send"
          handleClick={handleApplyButtonClick}
        />
      )}
      <Button
        data-testid="save-button"
        size="medium"
        mode="primary"
        label={isSaved ? 'Saved' : 'Save'}
        icon="bi bi-bookmark-heart"
        handleClick={handleSaveButtonClick}
      />
    </>
  )
);
