import { useEffect } from 'react';
import { useAuth0, User } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { getUserRoles } from '@/services/helpers/user';

export function useAdvisorRoleCheck() {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const allowAdvisorsOnly = (u: User) => {
    const roles = getUserRoles(u);

    const canViewCAPages = roles.includes('Admin') || roles.includes('CareerAdvisor');

    if (!canViewCAPages) {
      navigate('/app/dashboard');
    }
  };

  console.log('role check called');

  useEffect(() => {
    if (user) allowAdvisorsOnly(user);
  }, [user]);
}

export function useBetaAdvisorRoleCheck() {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const allowAdvisorsOnly = (u: User) => {
    const roles = getUserRoles(u);

    const canViewBetaCAPages = (roles.includes('Admin') || roles.includes('CareerAdvisor')) && roles.includes('BetaUser');
    if (!canViewBetaCAPages) {
      navigate('/app/dashboard');
    }
  };

  console.log('role check called');

  useEffect(() => {
    if (user) allowAdvisorsOnly(user);
  }, [user]);
}
