import { useState } from 'react';
import './job-deadline.scss';
import { Button, Tooltip } from '@careeros/coco';
import { PopupWindowWrapper } from '@/domains/generic';
import { JobDeadlineEdit } from '@/domains/core/company';

type Props = {
  applicationDeadlineText: string;
  isDateRolling: boolean;
  applicationDeadline: string;
  revalidate: (deadline: string | null, isRolling: boolean) => void;
  jobID: string;
};

export const JobDeadline = ({
  applicationDeadlineText,
  applicationDeadline,
  isDateRolling,
  revalidate,
  jobID,
}: Props) => {
  const [isJobDeadlineEditing, setIsJobDeadlineEditing] = useState(false);

  const openJobDeadlineEditing = () => setIsJobDeadlineEditing(true);

  const closeJobDeadlineEditing = (deadline: string | null, isRolling: boolean) => {
    setIsJobDeadlineEditing(false);

    revalidate(deadline, isRolling);
  };

  return (
    <div className="job-deadline">
      {isJobDeadlineEditing && (
      <PopupWindowWrapper handleClose={() => setIsJobDeadlineEditing(false)}>
        <JobDeadlineEdit
          isDateRolling={isDateRolling}
          deadlineDate={applicationDeadline?.split('T')[0]}
          handleClose={closeJobDeadlineEditing}
          jobID={jobID}
          isEditing={(isDateRolling || !!applicationDeadline)}
        />
      </PopupWindowWrapper>
      )}
      {(isDateRolling || !!applicationDeadline) ? (
        <Tooltip
          label="Click to edit"
          position="top"
          withArrow
        >
          <div
            data-testid="tracker-job-tile-deadline"
            className="job-deadline__deadline"
            onClick={openJobDeadlineEditing}
          >
            <span>
              Application deadline:
              {' '}
              {applicationDeadlineText}
            </span>
            <i className="job-deadline__deadline-icon bi bi-pencil" />
            <i className="job-deadline__deadline-icon job-deadline__deadline-icon--hovered bi bi-pencil-fill" />
          </div>
        </Tooltip>
      ) : (
        <div className="job-deadline__deadline">
          <Button
            label="Add application deadline"
            mode="text"
            size="small"
            handleClick={openJobDeadlineEditing}
            hoverIcon="bi bi-plus-circle-fill"
            icon="bi bi-plus-circle"
            iconPosition="left"
            data-testid="tracker-job-tile-add-deadline"
          />
        </div>
      )}
    </div>
  );
};
