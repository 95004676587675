import { Button, IconButton } from '@careeros/coco';
import { CompanyProgressTracker } from '../../components/company-progress-tracker/company-progress-tracker';
import { Company } from '../../types';
import { Contact } from '@/domains/core/contact/types';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { LinkButton } from '@/components/link/link';

import './company-status.scss';

type CompanyStatusProps = {
  company: Company;
  application: {
    application_id: string;
    application_status: string;
  }
  saveCompany: () => void;
  goToContact: (contactID: string) => void;
  contacts: Contact[];
};

const CompanyStatusContainer = ({
  company, application, saveCompany, contacts, goToContact,
}: CompanyStatusProps) => {
  const linkToAddContacts = `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(company.name)}&spellCorrectionEnabled=false&origin=GLOBAL_SEARCH_HEADER&sid=n)F`;

  const saveMoreContacts = () => {
    window.open(linkToAddContacts, '_blank');
  };
  return (
    <div className="company-status-container">
      <div className="company-status-container__tracker-container">
        <div className={`progress-tracker ${application.application_status ? '' : 'progress-tracker--full'}`}>
          <h3 className="progress-tracker__title">
            Your application progress
          </h3>
          {application.application_status ? (
            <CompanyProgressTracker status={application.application_status} name={company.name} id={company.id} />
          ) : (
            <>
              <p className="progress-tracker__text">
                Saving
                {' '}
                {company.name}
                {' '}
                allows you to start your complete journey with them, including tips for success and a game plan checklist for each step along the way that will prepare and propel you towards your dream job.
              </p>
              <Button mode="primary" handleClick={saveCompany} iconPosition="left" icon="bi bi-bookmark-heart" label="Save Company" size="medium" />
            </>
          )}
        </div>
      </div>
      {application.application_status && (
        <div className="company-status-container__contacts-container">
          <div className="networking-widget">
            <div className="networking-widget__header">
              <h3 className="networking-widget__title">Saved Contacts</h3>
              {(application.application_status && contacts.length > 0) && (
                <Button
                  handleClick={saveMoreContacts}
                  label="Save more"
                  size="medium"
                  icon="bi bi-box-arrow-up-right"
                  mode="text"
                  iconPosition="right"
                />
              )}
            </div>
            <div className="networking-widget__content">
              {(application.application_status && contacts.length > 0) && (
                <div className="networking-widget__contacts">
                  {contacts.map((contact) => (
                    <div className="networking-widget__contact">
                      <DefaultLogo
                        type="contact"
                        source={contact.career_summary.basics.image}
                        handleClick={() => goToContact(contact.id)}
                        className="networking-widget__contact__image"
                        name={`${contact.first_name} ${contact.last_name}`}
                        size={38}
                      />
                      <div className="networking-widget__contact__info">
                        <h4 className="networking-widget__contact__name">{`${contact.first_name} ${contact.last_name}`}</h4>
                        <span className="networking-widget__contact__label">{contact.current_position.title}</span>
                      </div>
                      <IconButton handleClick={() => goToContact(contact.id)} outlined mode="primary" size="small" icon="bi bi-send" />
                    </div>
                  ))}
                </div>
              )}

              {(application.application_status && !contacts.length) && (
                <LinkButton
                  link={linkToAddContacts}
                  label="Add Contact"
                  icon="bi bi-plus-circle"
                  target="_blank"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyStatusContainer;
