import DOMPurify from 'dompurify';
import { useState } from 'react';
import { Checkbox, Tooltip } from '@careeros/coco';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import './advisor-comment-preview.scss';
import { addBlankTargetToLinks } from '@/services/helpers/processHtml';

const sanitizeConfig = {
  ADD_ATTR: ['target'], // Allow the "target" attribute
};

type Props = {
  id: string;
  title: string;
  comment: string;
  imageUrl?: string;
  isResolved: boolean;
  dateAdded: string;
  advisorName: string;
  companyName?: string;
  handleResolveComment: (id: string) => Promise<void>;
  handleSeeComment: (id: string) => void;
  isResolveDisabled: boolean;
};

export const AdvisorCommentPreview = ({
  id,
  title,
  comment,
  imageUrl,
  dateAdded,
  isResolved,
  advisorName,
  companyName,
  isResolveDisabled,
  handleResolveComment,
  handleSeeComment,
}: Props) => {
  const [isResolvedSuccess, setIsResolvedsuccess] = useState(false);

  const handleResolveCommentClick = async () => {
    try {
      await handleResolveComment(id);
      setIsResolvedsuccess(true);
    } catch {
      setIsResolvedsuccess(false);
    }
  };

  const handleSeeCommentClick = () => {
    handleSeeComment(id);
  };

  return (
    <div className="advisor-comment-preview">
      <div className="advisor-comment-preview__header">
        <div className="advisor-comment-preview__image" onClick={handleSeeCommentClick}>
          {imageUrl ? (
            <DefaultLogo
              source={imageUrl}
              name={companyName || advisorName}
              size={16}
              type={companyName ? 'company' : 'contact'}
              className="advisor-comment-preview__image-logo"
            />
          ) : (
            <i className="bi bi-chat-square-text" />
          )}
        </div>
        <Tooltip label="Click to view full comment" position="top" withArrow>
          <div className="advisor-comment-preview__title" onClick={handleSeeCommentClick}>{title}</div>
        </Tooltip>
        <div className="advisor-comment-preview__date">{dateAdded}</div>
      </div>
      <div
        className="advisor-comment-preview__content"
        onClick={handleSeeCommentClick}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addBlankTargetToLinks(comment), sanitizeConfig) }}
      />
      <div className="advisor-comment-preview__resolve">
        {(isResolved || isResolvedSuccess) && (
          <div className="advisor-comment-preview__resolved">
            <i className="advisor-comment-preview__resolved-icon bi bi-check-circle-fill" />
            Resolved
          </div>
        )}
        {(!isResolved) && isResolveDisabled && (
          <div className="advisor-comment-preview__resolved">
            <i className="advisor-comment-preview__resolved-icon advisor-comment-preview__resolved-icon--grey bi bi-dash-circle-dotted" />
            Unresolved
          </div>
        )}

        {(!isResolved && !isResolveDisabled && !isResolvedSuccess) && (
          <Checkbox
            id="resolve-comment"
            label="Mark as Resolved"
            checked={isResolved}
            onChange={handleResolveCommentClick}
          />
        )}
      </div>
    </div>
  );
};
