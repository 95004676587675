import { Button, Tooltip } from '@careeros/coco';
import './suggested-contact.scss';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { SuggestedContactTag } from '../suggested-contact-tag/suggested-contact-tag';

type Props = {
  id: string;
  name: string;
  position: string;
  image: string;
  languages?: string[];
  isAlumni?: boolean;
  companyLogo: string;
  companyName: string;
  schoolName: string;
  networkingStatusRate?: number;
  handleMainButtonAction: (id: string) => void;
  isBlurred?: boolean;
};

export const SuggestedContact = ({
  id,
  name,
  position,
  image,
  languages = [],
  isAlumni = false,
  companyLogo,
  companyName,
  schoolName,
  networkingStatusRate = 0,
  handleMainButtonAction,
  isBlurred = false,
}: Props) => (
  <div className="suggested-contact-tile">
    <div className={`suggested-contact-tile__main-info ${isBlurred ? 'suggested-contact-tile__main-info--blur' : ''}`}>
      <div className="suggested-contact-tile__avatar-container">
        <div className="suggested-contact-tile__avatar">
          <DefaultLogo
            source={image}
            name={name}
            className="suggested-contact-tile__avatar-image"
            size={72}
            type="contact"
          />
        </div>
        <div className="suggested-contact-tile__company-logo">
          <DefaultLogo
            source={companyLogo}
            name={companyName}
            className="suggested-contact-tile__company-logo-image"
            size={24}
            type="company"
          />
        </div>
      </div>

      <div className="suggested-contact-tile__description">
        <div className="suggested-contact-tile__name">{name}</div>
        <div className="suggested-contact-tile__position">
          <span>{position}</span>
          {networkingStatusRate > 0 && (
            <span className="suggested-contact-tile__networking-status">
              <Tooltip position="top" withArrow label="In your desired functional area!">
                <div>
                  {new Array(Math.floor(networkingStatusRate)).fill(0).map(() => (
                    <span>🔥</span>
                  ))}
                </div>
              </Tooltip>
            </span>
          )}
        </div>

        <div className="suggested-contact-tile__tags">
          {isAlumni && (
            <SuggestedContactTag
              label="Alumni"
              tooltipLabel={`Also studied at ${schoolName}`}
              icon="bi bi-mortarboard-fill"
              color="purple"
            />
          )}
          {Array.isArray(languages) && languages.length > 0 && (
            <SuggestedContactTag
              label={languages[0]}
              tooltipLabel={`Also speaks: ${languages.join(', ')}`}
              icon="bi bi-translate"
              color="green"
            />
          )}
        </div>
      </div>
    </div>
    <div className="suggested-contact-tile__action">
      <Button
        mode="primary"
        size="small"
        onClick={() => handleMainButtonAction(id)}
        outlined
        label="Reach Out"
        icon="bi bi-send"
        iconPosition="right"
      />
    </div>
  </div>
);
