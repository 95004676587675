import { Toggle } from '@careeros/coco';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { JobBanner } from '../job-banner/job-banner';
import { JobTile } from '../job-tile/job-tile';
import { JobBannerType, JobFeedJob, JobOrBanner } from '../../types/job';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './job-tabs.scss';

const isBanner = (jobOrBanner: JobOrBanner): jobOrBanner is JobBannerType => (jobOrBanner as JobBannerType)?.type === 'banner';

type Props = {
  savedJobs: JobFeedJob[];
  jobsList: JobFeedJob[];
  observerTarget: React.RefObject<HTMLDivElement>;
  totalJobsCount: number;
  isNewPageLoading: boolean;
  isBannerSelected: boolean;
  currentActiveId: string;
  currentLoadingId: string;
  hasExtensionInstalled: boolean;
  isPageEnd: boolean;
  onSelectBanner: () => void;
  handleSaveAction: (jobId: string) => void;
  handleClick: (jobId: string) => void;
  handleUnsaveAction: (jobId: string) => void;
  onApplyClick: (id: string, url: string, apply: boolean) => void;
  toggleSavedCompaniesFilter: (checked: boolean) => void;
  isSavedCompaniesFilterOn: boolean;
};

export const AllJobsTab = ({
  savedJobs,
  jobsList,
  totalJobsCount,
  observerTarget,
  isNewPageLoading,
  isBannerSelected,
  currentActiveId,
  currentLoadingId,
  hasExtensionInstalled,
  isPageEnd,
  onSelectBanner,
  handleSaveAction,
  handleClick,
  handleUnsaveAction,
  onApplyClick,
  toggleSavedCompaniesFilter,
  isSavedCompaniesFilterOn: isSavedCompaniesFilterOnInState,
}: Props) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const [isSavedCompaniesFilterOn, setIsSavedCompaniesFilterOn] = useState(false);
  const jobsToDisplay = useMemo(() => {
    const [firstJob, secondJob, ...restJobs] = jobsList;
    const jobsWithBanner: JobOrBanner[] = [firstJob, secondJob];

    if (!hasExtensionInstalled) {
      jobsWithBanner.splice(2, 0, { type: 'banner' });
    }

    return [...jobsWithBanner, ...restJobs].filter(Boolean);
  }, [jobsList, savedJobs, hasExtensionInstalled]);

  const handleJobBannerExtensionClick = () => {
    onSelectBanner();
    trackEvent('Job Feed Chrome Extension banner clicked');
  };

  const handleJobBannerLookingForMoreClick = () => {
    onSelectBanner();
    trackEvent('Looking for more banner clicked');
  };

  const goToCompanyPage = (companyId: string) => {
    navigate(`/app/companies/company/${companyId}?company-page-tab=Jobs`);
  };

  useEffect(() => {
    if (isPageEnd) {
      trackEvent('Looking for more banner shown');
    }
  }, [isPageEnd]);

  useEffect(() => {
    setIsSavedCompaniesFilterOn(isSavedCompaniesFilterOnInState);
  }, [isSavedCompaniesFilterOnInState]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const savedCompaniesFilter = searchParams.get('favoriteCompanies');

    if (savedCompaniesFilter) {
      setIsSavedCompaniesFilterOn(savedCompaniesFilter === 'true');
    }
  }, []);

  return (
    <>
      <div className="job-tabs__top-info">
        <div className="job-tabs__count">
          <p className="job-tabs__count-number">{`${totalJobsCount?.toLocaleString()} jobs found`}</p>
          {totalJobsCount > 0 && (
            <TooltipIcon tooltipLabel="Jobs are ranked by date posted" />
          )}
        </div>
        <Toggle
          isChecked={isSavedCompaniesFilterOn}
          size="small"
          handleChange={toggleSavedCompaniesFilter}
          labelRight="Show jobs from companies I already saved"
        />
      </div>
      <div className="job-tabs__items">
        {jobsToDisplay.map((item) => (
          isBanner(item) ? (
            <JobBanner
              key="banner"
              header="Looking for more?"
              text="Save jobs from other job boards with our Chrome Extension and receive application tips here in CareerOS."
              isActive={isBannerSelected}
              type="extension"
              onClick={handleJobBannerExtensionClick}
            />
          ) : (
            <JobTile
              key={item.id}
              job={item}
              isActive={currentActiveId === item.id}
              isLoading={currentLoadingId === item.id}
              isSaved={item.saved}
              onJobSaveToggle={item.saved ? () => handleUnsaveAction(item.id) : () => handleSaveAction(item.id)}
              onSelect={handleClick}
              withCompanyName
              onApplyClick={onApplyClick}
              handleCompanyClick={goToCompanyPage}
            />
          )
        ))}
        {isPageEnd && (
          <JobBanner
            header="Looking for more?"
            key="find-more-jobs-banner"
            text="Save jobs from other job boards with our Chrome Extension and receive application tips here in CareerOS."
            isActive={isBannerSelected}
            onClick={handleJobBannerLookingForMoreClick}
          />
        )}
        <div ref={observerTarget} />
        {isNewPageLoading && <div className="job-tabs__loader" />}
      </div>
    </>
  );
};
