import './action-card-skeleton.scss';

export type AccentColorType = 'information' | 'tertiary50' | 'primary30' | 'primary50' | 'secondary40' | 'warning-light' | 'blue-green70' | 'error20';

type Props = {
  accentColor?: AccentColorType;
};

export const ActionCardSkeleton = ({
  accentColor = 'information',
}: Props) => (
  <div className={`action-card-skeleton action-card-skeleton--color-${accentColor}`}>
    <div className="action-card-skeleton__background-icons">
      <i className="action-card-skeleton__background-icon bi bi-hourglass-top" />
      <i className="action-card-skeleton__background-icon bi bi-hourglass-bottom" />
    </div>
    <div className="action-card-skeleton__title">
      <div className="action-card-skeleton__text-skeleton" />
      <div className="action-card-skeleton__text-skeleton action-card-skeleton__text-skeleton--small" />
    </div>
    <div className="action-card-skeleton__image-stack">
      <div className="action-card-skeleton__image-skeleton" />
      <div className="action-card-skeleton__image-skeleton" />
    </div>
  </div>
);
