import { DropdownList, IconButton, Tooltip } from '@careeros/coco';
import {
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import './student-actions-button.scss';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';

type Props = {
  studentId: string;
  universityId: string;
};

export const StudentActionsButton = ({ studentId, universityId }: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const toggleDropdown: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setIsDropdownOpen((prevValue) => !prevValue);
  };

  const navigateToStudent = (path: string) => {
    navigate(`/app/advisor/students/student-overview/${studentId}/university/${universityId}?tab=${encodeURIComponent(path)}`);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="student-actions-button" ref={buttonRef}>
      <Tooltip label="Actions" position="top" withArrow>
        <IconButton
          icon="bi bi-three-dots"
          mode="rounded"
          size="small"
          outlined
          handleClick={toggleDropdown}
        />
      </Tooltip>

      {isDropdownOpen && (
        <div className="student-actions-button__dropdown-container">
          <PopupBoxWrapper handleBlur={closeDropdown} parentRef={buttonRef}>
            <div className="student-actions-button__dropdown">
              <DropdownList
                handleClose={closeDropdown}
                parentRef={buttonRef}
                options={[
                  {
                    label: 'See Overview Board',
                    icon: 'bi bi-kanban',
                    onClick: () => navigateToStudent('Overview Board'),
                  },
                  {
                    label: 'See Contacts',
                    icon: 'bi bi-people',
                    onClick: () => navigateToStudent('Contacts'),
                  },
                  {
                    label: 'Access Resumes',
                    icon: 'bi bi-file-earmark',
                    onClick: () => navigateToStudent('Resumes'),
                  },
                  {
                    label: 'See Documents',
                    icon: 'bi bi-filetype-doc',
                    onClick: () => navigateToStudent('Documents'),
                  },
                  {
                    label: 'See Notes',
                    icon: 'bi bi-file-earmark-text',
                    onClick: () => navigateToStudent('Notes'),
                  },
                ]}
                iconSize="small"
              />
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
