import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';

import { Logo } from '@/components/logo/logo';
import './login-info.scss';

export const LoginInfo = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/with-university');
  };

  return (
    <div className="login">
      <div className="login__logo">
        <Link to="/">
          <Logo size="small" />
        </Link>
      </div>
      <h1 className="login__title">Welcome to your Career Execution Platform.</h1>
      <div className="login__disclaimer">
        By logging in, you accept our
        {' '}
        <a href="/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
        {' '}
        .
      </div>
      <div className="login-button">
        <Button
          mode="primary"
          size="fill"
          label="Log in"
          handleClick={handleLoginClick}
        />
      </div>
      {/* <LoginButton /> */}
    </div>
  );
};
