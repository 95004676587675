import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { InfoModal } from '@careeros/coco';
import * as Sentry from '@sentry/react';
import { buildCohortsWithAll } from '@/services/helpers/advisor';
import { useStudentsByUniversity } from '@/services/hooks/use-students-by-university';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { TabsContent, TabsHeader } from '@/components/tabs';
import { useContactBookFiltering } from '@/services/hooks/use-contact-book-filters';
import { useTabs, withTabs } from '@/components/tabs/TabsContext';
import '@/pages/styles/contact-book.scss';
import { ModalContext } from '@/components/modal/modal-provider';
import { useSavedContactBook } from '@/services/hooks/use-saved-contact-book';
import { Loader } from '@/components/loader/loader';
import { getContactInfoById, toggleSaveContact } from '@/domains/core/advisor/api/contacts-book';
import { ContactType, SelectedContactType } from '@/domains/core/advisor/types';
import { ContactBookFilters } from '@/domains/core/advisor/components/contact-book-filters/contact-book-filters';
import { ContactsNotFound } from '@/domains/core/advisor/components/contacts-book-not-found/contacts-book-not-found';
import { AllContactsTab } from '@/domains/core/advisor/components/all-contacts-tab/all-contacts-tab';
import { SavedContactsTab } from '@/domains/core/advisor/components/all-contacts-tab/saved-contacts-tab';
import ContactDescription from '@/domains/core/advisor/components/contact-description/contact-description';
import { useBetaAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';

const defaultFilters = {
  contactName: '',
  companies: [],
  isAlum: '',
  isRecruiter: '',
  industryName: [],
  response_rate: {
    min: 0,
    max: 100,
  },
};

const ContactBookPage = () => {
  const { currentTab } = useTabs();
  const { universityID, cohortID } = useParams();
  const { data: university } = useSelfUniversity();
  const { data: self } = useSelf();
  const { cohorts } = useStudentsByUniversity('students', universityID);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [selectedContact, setSelectedContact] = useState<SelectedContactType | null>(null);

  const {
    contacts,
    observerTarget,
    currentActiveId,
    currentLoadingId,
    isLoading,
    totalContactsCount,
    companies,
    industries,
    isNewPageLoading,
    filters,
    setCurrentActiveId,
    setCurrentLoadingId,
    setContacts,
    setFilters,
  } = useContactBookFiltering({ currentTab, defaultFilters, cohortID });

  const {
    savedContacts,
    observerTargetSaved,
    totalContactsCountSaved,
    currentActiveId: savedContactsCurrentActiveId,
    setCurrentActiveId: setSavedContactsCurrentActiveId,
    isNewPageLoading: isNewPageLoadingSaved,
    setTotalContactsCountSaved,
    setSavedContacts,
  } = useSavedContactBook({ currentTab, isLoading, cohortID });

  const tabs = ['All Contacts', 'Saved Contacts'];

  const cohortsWithAll = useMemo(() => {
    if (!cohorts) return [];
    return buildCohortsWithAll(
      '/app/contacts-book',
      cohorts,
      universityID,
      university?.id,
    );
  }, [cohorts]);

  const handleContactSelect = (id: string) => {
    setCurrentActiveId(id);
    setSavedContactsCurrentActiveId('');
  };

  const handleSavedContactSelect = (id: string) => {
    setCurrentActiveId('');
    setSavedContactsCurrentActiveId(id);
  };

  const getDetailedContactInfo = async () => {
    try {
      if (currentActiveId || savedContactsCurrentActiveId) {
        const contactDetail = await getContactInfoById(currentActiveId || savedContactsCurrentActiveId);
        if (contactDetail) {
          setSelectedContact(contactDetail);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const updateContactSavedStatus = (contactID: string, updatedContact: ContactType) => {
    const updatedContactsList = contacts.map(cont => (cont.id === contactID ? updatedContact : cont));
    const updatedSavedContactsList = savedContacts.map(cont => (cont.id === contactID ? updatedContact : cont));

    setContacts(updatedContactsList);
    setSavedContacts(updatedSavedContactsList);
  };

  const handleSaveContact = async (contactId: string) => {
    setCurrentLoadingId(contactId);
    if (!self) return;

    try {
      const contactToUpdate = contacts.find(contact => contact.id === contactId);
      await toggleSaveContact(contactId, self.id);
      setTotalContactsCountSaved(prevTotalJobsCount => prevTotalJobsCount + 1);
      if (contactToUpdate) {
        const updatedContact = { ...contactToUpdate, is_bookmarked: true };
        updateContactSavedStatus(contactId, updatedContact);
        setSavedContacts(prev => [updatedContact, ...prev]);
        if (selectedContact?.id === contactId) {
          setSelectedContact({ ...selectedContact, is_bookmarked: true });
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setCurrentLoadingId('');
    }
  };

  const handleUnsaveContact = async (rememberAction: boolean, contactId: string) => {
    if (rememberAction) {
      localStorage.setItem('rememberUnsaveContact', 'true');
    }

    if (!self) return;

    setCurrentLoadingId(contactId);

    try {
      const contactToUpdate = contacts.find(contact => contact.id === contactId);
      await toggleSaveContact(contactId, self.id);
      setTotalContactsCountSaved(prevTotalJobsCount => prevTotalJobsCount - 1);
      if (contactToUpdate) {
        const updatedContact = { ...contactToUpdate, is_bookmarked: false };
        updateContactSavedStatus(contactId, updatedContact);
        setSavedContacts(prev => prev.filter(contact => contact.id !== contactId));
        if (selectedContact?.id === contactId) {
          setSelectedContact({ ...selectedContact, is_bookmarked: false });
        }
      }
      if (currentActiveId === contactId) {
        const newActiveContact = savedContacts.find(contact => contact.id !== contactId);
        setCurrentActiveId(newActiveContact ? newActiveContact.id : '');
      }
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setCurrentLoadingId('');
    }
    closeModal();
  };

  const handleUnsave = (contactId: string) => {
    const rememberAction = localStorage.getItem('rememberUnsaveContact');

    if (rememberAction) {
      handleUnsaveContact(true, contactId);
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Unsave Contact"
          description="Once you unsave this contact, it will be removed from your saved contacts. You can always save it again later."
          buttonLabel="Yes, unsave contact"
          handleButtonClick={(rememberAnswer: boolean) => handleUnsaveContact(rememberAnswer, contactId)}
          secondaryButtonLabel="Keep contact"
          handleSecondaryButtonClick={closeModal}
          rememberText="Don't ask this again"
        />,
      );
    }
  };

  useBetaAdvisorRoleCheck();

  useEffect(() => {
    setCurrentActiveId(contacts[0]?.id);
  }, [contacts, savedContacts]);

  useEffect(() => {
    getDetailedContactInfo();
  }, [currentActiveId, savedContactsCurrentActiveId]);

  if (Array.isArray(contacts) && contacts.length === 0 && !isLoading) {
    return (
      <div className="contacts-book" id="contacts-book">
        <div className="contacts-book__top-container">
          <div className="contacts-book__top">
            <CohortsNavigation links={cohortsWithAll} defaultLinkName="All Cohorts" />
          </div>
          <ContactBookFilters
            filters={filters}
            setFilters={setFilters}
            defaultFilters={defaultFilters}
            companies={companies}
            industries={industries}
          />
        </div>
        <ContactsNotFound />
      </div>
    );
  }

  return (
    <div className="contacts-book" id="contacts-book">
      <div className="contacts-book__top-container">
        <div className="contacts-book__top">
          <CohortsNavigation links={cohortsWithAll} defaultLinkName="All Cohorts" />
        </div>
        <ContactBookFilters
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
          companies={companies}
          industries={industries}
        />
      </div>
      <div className="contacts-book__bottom">
        {isLoading ? <Loader /> : (
          <>
            <div className="job-tabs">
              <TabsHeader
                withBorder
                tabs={tabs}
                defaultTab={tabs[0]}
                tabCounters={{ [tabs[1]]: totalContactsCountSaved }}
              />

              <TabsContent>
                <div data-tab="All Contacts" className="contacts-book__tab">
                  <AllContactsTab
                    contactsList={contacts}
                    totalContactsCount={totalContactsCount}
                    observerTarget={observerTarget}
                    isNewPageLoading={isNewPageLoading}
                    currentActiveId={currentActiveId}
                    currentLoadingId={currentLoadingId}
                    handleSaveAction={handleSaveContact}
                    handleClick={handleContactSelect}
                    handleUnsaveAction={handleUnsave}
                  />
                </div>
                <div data-tab="Saved Contacts" className="contacts-book__tab">
                  <SavedContactsTab
                    contactsList={savedContacts}
                    totalContactsCount={totalContactsCountSaved}
                    observerTarget={observerTargetSaved}
                    isNewPageLoading={isNewPageLoadingSaved}
                    currentActiveId={savedContactsCurrentActiveId}
                    currentLoadingId={currentLoadingId}
                    handleSaveAction={handleSaveContact}
                    handleClick={handleSavedContactSelect}
                    handleUnsaveAction={handleUnsave}
                  />
                </div>
              </TabsContent>
            </div>
            <div className="contacts-book__content-wrapper">
              <div className="contacts-book__block">
                <ContactDescription
                  selectedContact={selectedContact}
                  isSaved={selectedContact?.is_bookmarked}
                  currentLoadingId={currentLoadingId}
                  handleSaveAction={handleSaveContact}
                  handleUnsaveAction={handleUnsave}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withTabs(ContactBookPage);
