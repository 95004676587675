import { http } from '@/services/api/http';
import { TemplateResponse } from '@/domains/core/advisor/types/api/template';
import { convertFromApiTemplates } from '@/domains/core/advisor/api/model/template';
import { Template } from '../types/template';

export const getTemplates = async (): Promise<Template[]> => {
  const url = '/university/template';

  const res: TemplateResponse[] = await http.get(url, 'v2');

  return convertFromApiTemplates(res);
};
