import { Helmet } from 'react-helmet';

interface SEOProps {
  description: string;
  company: {
    name: string;
    logo_url: string;
    hq_location: string;
  };
  canonicalUrl?: string;
  imageUrl?: string;
}

export const PublicCompanySEO = ({
  description,
  company,
  canonicalUrl = window.location.href,
  imageUrl,
}: SEOProps) => {
  const seoTitle = `${company.name} - Company Profile | CareerOS`;
  const seoDescription = description
    || `Learn about career opportunities at ${company.name}. Discover company culture, benefits, office locations and more. Located in ${company.hq_location}.`;
  const seoImage = imageUrl || company.logo_url;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImage} />

      {/* Additional metadata */}
      <meta name="geo.region" content={company.hq_location} />
      <meta name="og:locality" content={company.hq_location} />
    </Helmet>
  );
};
