import { ApiStudentStatus } from '@/domains/core/advisor/types/api/student';
import { StudentStatus } from '@/domains/core/advisor/types';

const statusMap: { [key in ApiStudentStatus]: StudentStatus } = {
  inactive: 'Inactive',
  companies_saved: 'Companies Saved',
  networking: 'Networking',
  applied: 'Applying',
  interviewing: 'Interviewing',
  offer: 'Offer',
};

export const convertFromApiStatus = (status: ApiStudentStatus): StudentStatus => {
  const mappedStatus = statusMap[status];
  if (!mappedStatus) {
    return 'Inactive';
  }

  return mappedStatus;
};
