import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button } from '@careeros/coco';
import { router } from '@/main';
import { Loader } from '@/components/loader/loader';
import { Input } from '@/components/input/input';
import { ModalContext } from '@/components/modal/modal-provider';
import { changeApplicationStatus } from '@/domains/core/company';
import { statuses } from '@/domains/core/tracker-data';
import { InfoModal } from '@/domains/generic/modals';
import RestoreIcon from '@/assets/images/icons/restore.svg';
import './styles/archive.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { Tile } from '@/domains/core/company/types';

function ArchivePage() {
  const { archivedCompanies } = useLoaderData() as any;
  const [companies, setCompanies] = useState<Tile[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const revalidator = useRevalidator();
  const queryClient = useQueryClient();

  const searchCompanies = (value: string) => {
    setSearchValue(value);
    const filteredCompanies = archivedCompanies.filter((company: Tile) => company.company_name.toLowerCase().includes(value.toLowerCase()));
    setCompanies(filteredCompanies);
  };

  const goToOverview = () => {
    closeModal();
    router.navigate('/app/overview');
  };
  const restoreApplication = async (applicationID: string) => {
    try {
      closeModal();
      await changeApplicationStatus(applicationID, statuses['Company Saved'], 0);
      revalidator.revalidate();
      openModal(
        <InfoModal
          handleButtonClick={goToOverview}
          title="Company Restored!"
          description="The Company is now restored. You can find it in your Overview Board."
          buttonLabel="Go to Overview Board"
        />,
      );
      await queryClient.invalidateQueries(['archive']);
      await queryClient.invalidateQueries(['applications']);
      queryClient.invalidateQueries(['dashboard-actions']);
    } catch (error) {
      // throw new Error(`Error restoring application:${error}`);
      // Handle error here
    }
  };
  const openAreYouSure = (id: string) => {
    openModal(
      <InfoModal
        handleButtonClick={() => restoreApplication(id)}
        title="Are you sure?"
        description="Restoring will bring this company back to your Overview Board and you will be able to continue your progress."
        buttonLabel="Restore"
      />,
    );
  };
  useEffect(() => {
    setCompanies(archivedCompanies);
  }, [archivedCompanies]);

  return (
    <div className="archive" id="main">
      <div className="white-wrapper">
        {archivedCompanies.length > 0 ? (
          <>
            <div className="archive__search">
              <Input icon="bi bi-search" value={searchValue} id="search-companies" label="Search for companies" placeholder="Type company name" handleValueChange={searchCompanies} />
            </div>
            <div className="archive-table">
              <div className="archive-table__header">
                <div className="archive-table__header__item">Company Name</div>
                <div className="archive-table__header__item">Industry</div>
                <div className="archive-table__header__item archive-table__header__item--center">Number of Contacts</div>
                <div className="archive-table__header__item archive-table__header__item--center">Job Title</div>
                <div className="archive-table__header__item archive-table__header__item--center">Job Location</div>
                <div className="archive-table__header__item archive-table__header__item--center">Actions</div>
              </div>
              <div className="archive-table__body">
                {companies.map((application: Tile) => (
                  <div className="archive-table__body__row">
                    <div className="archive-table__body__item">
                      <img src={application.logo_url} className="archive-table__body__item__image" alt="" />
                      <span>{application.company_name}</span>
                    </div>
                    <div className="archive-table__body__item">{application.industry}</div>
                    <div className="archive-table__body__item archive-table__body__item--center">-</div>
                    <div className="archive-table__body__item archive-table__body__item--center">-</div>
                    <div className="archive-table__body__item archive-table__body__item--center">-</div>
                    <div className="archive-table__body__item archive-table__body__item--center archive-table__body__item--restore" onClick={() => openAreYouSure(application.id)}>
                      <img className="archive-table__body__item__restore" src={RestoreIcon} alt="" />
                      {' '}
                      Restore
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </>
        ) : (
          <div className="companies-not-found">
            <h3 className="companies-not-found__title">{'Looks like you haven\'t archived any companies yet'}</h3>
            <Button handleClick={goToOverview} mode="primary" size="large" label="Go To Overview Board" />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(ArchivePage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
