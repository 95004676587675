import { useCallback, useMemo } from 'react';
import { Tooltip } from '@careeros/coco';
import { Tag, TagColors } from '@/components/tag/tag';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { ActionButtonSave } from '@/components/action-buttons/action-button-save';
import { getTagIcons } from '../../helpers/getTagIcons';
import { JobFeedJob } from '../../types/job';
import { sortTags } from '@/services/helpers/job-feed';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import './job-tile.scss';
import { JobTileDeadline } from '../job-tile-deadline/job-tile-deadline';
import { AppliedBadge } from '../applied-badge/applied-badge';

type Props = {
  job: JobFeedJob;
  isActive?: boolean;
  isLoading: boolean;
  isSaved: boolean;
  onJobSaveToggle: (jobId: string) => void;
  onSelect: (jobId: string) => void;
  withCompanyName?: boolean;
  onApplyClick: (id: string, url: string, apply: boolean) => void;
  handleCompanyClick?: (id: string) => void;
};

export const JobTile = ({
  job,
  isActive = false,
  isSaved,
  isLoading,
  onJobSaveToggle,
  onSelect,
  withCompanyName = false,
  onApplyClick,
  handleCompanyClick,
}: Props) => {
  const handleSave = useCallback(() => onJobSaveToggle(job.id), [onJobSaveToggle, job.id]);
  const sortedTags = useMemo(() => (job && job.tags ? sortTags(job.tags) : []), [job?.tags]);
  const postedDate = useMemo(() => formatDateDeltaFromNow(job.posted_on || job.created_at || ''), [job.posted_on, job.created_at]);

  const handleAppliedClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    onApplyClick(job.id, job.linkedin_url, false);
  };

  const onCompanyClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();

    if (handleCompanyClick && job.company_id) {
      handleCompanyClick(job.company_id);
    }
  };

  return (
    <div
      className={['job-tile', isActive ? 'job-tile--active' : ''].join(' ')}
      onClick={() => onSelect(job.id)}
    >
      <div className="job-tile__action" data-testid="job-tile-loader">
        <ActionButtonSave
          onClick={handleSave}
          isLoading={isLoading}
          iconName="bi-bookmark-heart"
          iconNameSaved="bi-bookmark-heart-fill"
          label="Save"
          labelSaved="Unsave"
          ariaLabel="Save Job"
          ariaLabelSaved="Unsave Job"
          isSaved={isSaved}
        />
      </div>
      <div className="job-tile__main">
        <Tooltip label={job.title} position="top" disableHoverListener={!job.title}>
          <p className="job-tile__title">{job.title || 'No title'}</p>
        </Tooltip>
        {withCompanyName && (
          <div
            className="job-tile__company"
            data-testid="job-logo"
            onClick={onCompanyClick}
          >
            <Tooltip label="View Company Profile" position="top" withArrow>
              <div className="job-tile__company-item">
                <div className="job-tile__company-logo-container">
                  <DefaultLogo
                    source={job.linkedin_logo_url}
                    type="job"
                    name={job.company_name}
                    className="job-tile__company-logo"
                    size={24}
                    icon="box-arrow-up-right"
                  />
                  <i className="job-tile__company-logo-icon bi bi-box-arrow-up-right" />
                </div>
                <p className="job-tile__company-name">{job.company_name}</p>
              </div>
            </Tooltip>
          </div>
        )}
        <div className="job-tile__dates">
          <p className="job-tile__posted-on">{postedDate}</p>

          {(job?.deadline && !job?.applied && job.saved && !job.rolling_date) && (
            <JobTileDeadline deadline={job?.deadline} handleClick={() => onApplyClick(job.id, job.linkedin_url, true)} />
          )}

          {job?.applied && (
            <AppliedBadge handleClick={handleAppliedClick} />
          )}
        </div>
      </div>
      <div className="job-tile__bottom">
        {job?.tags && sortedTags.map((tag) => (
          <Tag
            key={tag.tag_type}
            color={TagColors.Grey}
            label={tag.tag_content}
            image={getTagIcons(tag.tag_type)}
          />
        ))}
      </div>
    </div>
  );
};
