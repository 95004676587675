import { TemplateResponse } from '@/domains/core/advisor/types/api/template';
import { Template } from '@/domains/core/advisor/types/template';

export function convertFromApiTemplate(apiTemplate: TemplateResponse): Template {
  return {
    id: apiTemplate.id,
    type: apiTemplate.type,
    name: apiTemplate.name,
    emailSubject: apiTemplate.email_subject,
    message: apiTemplate.message,
  };
}

export function convertFromApiTemplates(apiTemplates: TemplateResponse[]): Template[] {
  if (!Array.isArray(apiTemplates)) {
    return [];
  }

  return apiTemplates.map(convertFromApiTemplate);
}
