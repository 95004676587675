import { NavbarLink } from '../navbar-link/navbar-link';
import { LinkType, NavbarItemName } from '../../types/types';
import './navbar-links.scss';

type Props = {
  links: LinkType[] | undefined;
  collapsed: boolean;
  splitBefore?: string;
  handleLinkClick: (link: string) => void;
  unreadTabs?: NavbarItemName[];
};

export const NavbarLinks = ({
  links,
  collapsed,
  splitBefore,
  handleLinkClick,
  unreadTabs = [],
}: Props) => {
  const checkIfLinkIsVisible = (link: LinkType) => {
    if (link.isHiddenOnReadState) {
      const isTabUnread = unreadTabs.includes(link.label as NavbarItemName);

      return isTabUnread;
    }

    return true;
  };

  return (
    <ul className="sidebar-links">
      {!!links && links.map((link) => (
        <>
          {splitBefore === link.label && <div className={`sidebar-links__separator ${collapsed ? 'sidebar-links__separator--collapsed' : ''}`} />}
          {checkIfLinkIsVisible(link) && (
            <li key={link.url}>
              <NavbarLink
                link={link}
                collapsed={collapsed}
                handleLinkClick={() => handleLinkClick(link.label)}
                isUnread={unreadTabs.includes(link.label as NavbarItemName)}
              />
            </li>
          )}
        </>
      ))}
    </ul>
  );
};
