import { Button, IconButton, Tooltip } from '@careeros/coco';
import { QuestType } from '../../../types';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import CompletedLogo from '@/assets/images/icons/quests/completed-logo.svg';
import {
  CTA_COMPLETED_LABEL,
  CTA_LABEL, ICON_TYPE, QUEST_CATEGORY, QUEST_COMPLETABLE, QUEST_COMPLETED_DESCRIPTION, QUEST_DISMISSIBLE,
} from '../quests-data';
import { Badge } from '@/components/badge/badge';

import './quest-tile.scss';

type Props = {
  id: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  type: QuestType;
  primary: boolean;
  contactLogo?: string;
  companyLogo?: string;
  contactName?: string;
  companyName?: string;
  completed?: boolean;
  createdAt?: string;
  openAreYouSureComplete: (id: string) => void;
  openAreYouSureDismiss: (id: string) => void;
  handleMainCTAClick: (titleClicked: boolean) => void;
};

export const QuestTileDumb = ({
  id,
  title,
  description,
  type,
  primary,
  companyLogo = '',
  contactLogo = '',
  contactName = '',
  companyName = '',
  createdAt,
  openAreYouSureComplete,
  openAreYouSureDismiss,
  handleMainCTAClick,
  completed = false,
}: Props) => {
  const today = new Date().toISOString();
  const renderQuestLogo = () => {
    if (type === QuestType.SaveContact || type === QuestType.ApplicationDeadline || type === QuestType.AddNewContact) {
      return (
        <DefaultLogo
          source={companyLogo}
          type="company"
          name={companyName}
          className=""
          size={45}
        />
      );
    }
    if (type === QuestType.SendConnection || type === QuestType.SendMessage || type === QuestType.SendFirstEmail || type === QuestType.SendFirstOutreach || type === QuestType.SendSecondOutreach || type === QuestType.SendFollowUpEmail || type === QuestType.RespondToEmail || type === QuestType.ReplyToContact) {
      return (
        <DefaultLogo
          source={contactLogo}
          type="contact"
          name={contactName}
          className=""
          size={45}
        />
      );
    }
    if (completed) return <img src={CompletedLogo} alt="" />;
    return <img src={ICON_TYPE[type]} alt="" />;
  };
  return (
    <div className={`${primary ? `quest-tile quest-tile--primary quest-tile--${type}` : 'quest-tile'} ${completed ? 'quest-tile--completed' : 'quest-tile--in-progress'}`}>
      <div className={`quest-tile__icon quest-tile__icon--${QUEST_CATEGORY[type].split(' ').join('-')}`}>
        {renderQuestLogo()}
      </div>
      <div className="quest-tile__info">
        <div className="quest-tile__title-container">
          <div className={`quest-tile__title ${completed ? 'quest-tile__title--completed' : 'quest-tile__title--in-progress'}`} onClick={() => handleMainCTAClick(true)}>{title}</div>
          {createdAt && createdAt.startsWith(today.split('T')[0]) && (type !== QuestType.DownloadExtension && type !== QuestType.SaveFirstCompany && type !== QuestType.ConnectEmail && type !== QuestType.CompleteDemographicsForm) && <Badge label="New Quest" color="dark-gradient" icon="bi bi-star-fill" iconPosition="left" />}
          {(type === QuestType.DownloadExtension || type === QuestType.SaveFirstCompany || type === QuestType.ConnectEmail || type === QuestType.CompleteDemographicsForm) && <Badge label="Recommended" color="blue-outline" />}
        </div>
        <div className="quest-tile__description">{completed ? QUEST_COMPLETED_DESCRIPTION[type] : description}</div>
      </div>
      <div className="quest-tile__actions">
        {QUEST_COMPLETABLE[type] && (
        <Tooltip label="Mark as Done" withArrow position="top">
          <div data-testid="complete" className="quest-tile__button quest-tile__button--icon quest-tile__button--complete" onClick={() => openAreYouSureComplete(id)}>
            <i className="bi bi-check-square quest-tile__button-icon " />
            <i className="bi bi-check-square-fill quest-tile__button-icon quest-tile__button-icon--hovered" />
          </div>
        </Tooltip>
        )}
        {QUEST_DISMISSIBLE[type] && (
        <Tooltip label="Dismiss" withArrow position="top">
          <div data-testid="dismiss" className="quest-tile__button quest-tile__button--icon quest-tile__button--dismiss" onClick={() => openAreYouSureDismiss(id)}>
            <i className="bi bi-x-square quest-tile__button-icon" />
            <i className="bi bi-x-square-fill quest-tile__button-icon quest-tile__button-icon--hovered" />
          </div>
        </Tooltip>
        )}
      </div>
      <div className="quest-tile__cta">
        {primary
          ? (
            <Button
              mode="primary"
              icon="bi bi-box-arrow-up-right"
              iconPosition="right"
              size="small"
              outlined={completed}
              onClick={() => handleMainCTAClick(false)}
              label={completed ? CTA_COMPLETED_LABEL[type] : CTA_LABEL[type]}
            />
          )
          : (
            <IconButton
              size="medium"
              outlined
              mode="rounded"
              icon="bi bi-arrow-up-right"
              hoverIcon="bi bi-arrow-up-right"
              label={CTA_LABEL[type]}
              onClick={() => handleMainCTAClick(false)}
            />
          )}
      </div>
    </div>
  );
};
