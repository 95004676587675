import { useQuery } from 'react-query';
import { getTemplates } from '@/domains/core/advisor/api/template';

export const useAdvisorTemplates = (options = {}) => useQuery(
  ['advisor-templates'],
  () => getTemplates(),
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 24, // pretty sure the templated never change
    ...options, // Spread additional options
  },
);
