import './suggested-contacts-container.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import { SuggestedContact } from '../suggested-contact/suggested-contact';
import { useSuggestedContactsByCompanyID } from '@/services/queries/contact';
import { Loader } from '@/components/loader/loader';

import type { SuggestedContactType } from '@/domains/core/contact/types/contact';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { saveContact } from '@/services/api/contact';
import { useRecombee } from '@/services/hooks/use-recombee';
import { SuggestedContacts } from '@/domains/core/company/components/suggested-contacts/suggested-contacts';
import { ContactTabStates } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  companyID: string;
  companyLinkedIn: string;
  savedContactsUrls: string[];
  handleSeeMoreContactsClicked: () => void;
  companyName: string;
  updateContacts: () => void;
  tabState?: ContactTabStates;
  changeTab: (tab: string, state?: string) => void;
};

export const SuggestedContactsForCompanyContainer = ({
  companyLinkedIn,
  companyID,
  savedContactsUrls,
  handleSeeMoreContactsClicked,
  updateContacts,
  companyName,
  changeTab,
  tabState,
}: Props) => {
  const { data: suggestedContacts, isLoading } = useSuggestedContactsByCompanyID(companyID);
  const { data: selfUniversity, isLoading: isSelfLoading } = useSelfUniversity();
  const { data: self } = useSelf();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { addBookmark, ITEM_TYPES } = useRecombee();

  const navigate = useNavigate();

  const handleReachOut = async (id: string) => {
    const suggestedContact = suggestedContacts?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;
    await saveContact(suggestedContact, suggestedContact.company_id);

    trackEvent('Saved Contact', user, {
      companyID,
      source: 'Suggested Contacts from our DB',
    });

    if (self?.id) {
      await addBookmark(
        self.id,
        ITEM_TYPES.CONTACT,
        id,
        {
          company_name: suggestedContact.contact.current_position.company,
          job_title: suggestedContact.contact.current_position.title,
          languages: suggestedContact.raw_data.languages,
        },
      );
    }
    navigate(`/app/inbox/${suggestedContact.contact_id}`);
  };

  if (isLoading || isSelfLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }
  return (
    <div className="suggested-contacts-container">
      {Array.isArray(suggestedContacts) && suggestedContacts.length > 0 ? (
        <div className="suggested-contacts-container__contacts">
          <div className="suggested-contacts-container__header">
            <h3 className="suggested-contacts-container__title">
              Suggested Contacts
            </h3>
            <TooltipIcon
              tooltipLabel="We found these based on your profile"
              tooltipPosition="top"
            />
          </div>
          <div className="suggested-contacts-container__scroll-container">
            <div className="suggested-contacts-container__list">
              {suggestedContacts.map((suggestedContact: SuggestedContactType) => (
                <SuggestedContact
                  key={suggestedContact.contact_id}
                  id={suggestedContact.contact_id}
                  name={`${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`}
                  position={suggestedContact.contact.current_position.title}
                  image={suggestedContact.contact.career_summary.basics.image}
                  languages={suggestedContact.raw_data.languages}
                  isAlumni={suggestedContact.match_criteria.includes('alumni')}
                  companyLogo={suggestedContact.contact.current_position.company_logo_url}
                  companyName={suggestedContact.contact.current_position.company}
                  schoolName={selfUniversity?.name || ''}
                  networkingStatusRate={suggestedContact.match_criteria.includes('desired_job') ? 1 : 0}
                  handleMainButtonAction={(id) => handleReachOut(id)}
                />
              ))}
            </div>
          </div>
        </div>
      )
        : (
          <SuggestedContacts
            tabState={tabState}
            changeTab={changeTab}
            updateContacts={updateContacts}
            handleSeeMoreContactsClicked={handleSeeMoreContactsClicked}
            companyLinkedIn={companyLinkedIn}
            companyId={companyID}
            savedContactsUrls={savedContactsUrls}
            companyName={companyName}
          />
        )}

    </div>
  );
};
