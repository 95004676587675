import { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigation, useLocation } from 'react-router-dom';
import { useAuth0, User } from '@auth0/auth0-react';
import Navbar from './layout/navbar/navbar';
import TopBar from './layout/topbar/topbar';
import { Loader } from '@/components/loader/loader';
import { ModalProvider } from '@/components/modal/modal-provider';
import { SidebarProvider } from '@/components/sidebar/sidebar-provider';
import { ToastProvider } from '@/components/toast/toast-provider';
import WebSocketHandler from '@/pages/websocket';
import { TrackerProvider } from '@/domains/core/company';
import { NavbarContextProvider } from './layout/navbar/context/navbar-context-provider';
import CareerAdvisorNavbar from '@/domains/core/advisor/layout/navbar/career-advisor-navbar';

export default function Root() {
  const location = useLocation();
  const { state } = useNavigation();
  const { user } = useAuth0();
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const detailRef = useRef<HTMLDivElement>(null);
  const [isCareerAdvisorNavbar, setIsCareerAdvisorNavbar] = useState(false);

  const handleSidebarChange = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const setupNavbar = (userObject: User) => {
    const roles = userObject['https://careeros.com/roles'];

    if (roles.includes('BetaUser')) { // we will change this to CA in the future
      setIsCareerAdvisorNavbar(true);
    } else {
      setIsCareerAdvisorNavbar(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    detailRef.current?.scrollTo(0, 0);
    if ((location.pathname.includes('/app/onboarding') || location.pathname.includes('/app/cohort-assignment')) && !location.search.includes('show_navigation')) {
      setIsOnboarding(true);
    } else {
      setIsOnboarding(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      setupNavbar(user);
    }
  }, [user]);

  useEffect(() => {
    if (state === 'loading') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [state]);

  return (
    <div className={`layout ${sidebarCollapsed ? 'layout--collapsed' : ''} ${isOnboarding ? 'layout--no-sidebar' : ''}`}>
      <SidebarProvider>
        <NavbarContextProvider>
          <ToastProvider>
            <ModalProvider>
              <TrackerProvider>
                {isCareerAdvisorNavbar ? (
                  <CareerAdvisorNavbar
                    collapsed={sidebarCollapsed}
                    handleToggle={handleSidebarChange}
                    isOnboarding={isOnboarding}
                  />
                ) : (
                  <Navbar
                    isOnboarding={isOnboarding}
                    collapsed={sidebarCollapsed}
                    handleToggle={handleSidebarChange}
                  />
                )}
                <TopBar collapsed={sidebarCollapsed} />
                <div
                  id="detail"
                  className={sidebarCollapsed ? 'detail detail--collapsed' : 'detail'}
                  ref={detailRef}
                >
                  {state === 'loading' && (
                    <div id="loader-zone">
                      <Loader />
                    </div>
                  )}
                  <Outlet />
                </div>
                <WebSocketHandler />
              </TrackerProvider>
            </ModalProvider>
          </ToastProvider>
        </NavbarContextProvider>
      </SidebarProvider>
    </div>
  );
}
