import { LoaderFunctionArgs } from 'react-router-dom';
import {
  getCompanyLocations,
  getCompanySizes,
  getCompanyTags,
  getSingleCompany,
} from '@/services/api/company';
import { getApplications, getArchivedApplications } from '@/services/api/application';
import { getFavoriteCompanies } from '@/services/api/favorite';
import { getIndustries } from '@/services/api/industry';
import { getCompanyContacts } from '@/services/api/contact';
import {
  Company, CompanyResponse, ExploreCompaniesResponse, FavoriteCompaniesResponse, FilteredCompaniesResponse,
  OverviewResponse,
} from '../types';

export async function companyLoader({ params }: LoaderFunctionArgs): Promise<CompanyResponse> {
  const { companyID } = params;

  if (!companyID || companyID === 'null') {
    return { company: undefined, contacts: [] };
  }

  try {
    const [companyResponse, contactsResponse] = await Promise.all([
      getSingleCompany(companyID),
      getCompanyContacts(companyID),
    ]);

    const company = !companyResponse || companyResponse?.error ? undefined : companyResponse;

    return {
      company,
      contacts: contactsResponse || [],
    };
  } catch (error) {
    return { company: undefined, contacts: [] };
  }
}

export async function QRcompanyLoader(request: any) {
  const company = await getSingleCompany(request.params.companyID);
  return { company };
}

export async function archiveLoader() {
  const archivedCompanies = await getArchivedApplications();
  if (!archivedCompanies) return { archivedCompanies: [] };
  return { archivedCompanies };
}

export async function exploreCompaniesLoader(): Promise<ExploreCompaniesResponse> {
  const [industries, tilesResponse, archivedCompanies, getMyCompaniesResponse, locations, sizes, tags] = await Promise.all([
    getIndustries(),
    getApplications(),
    getArchivedApplications(),
    getFavoriteCompanies(),
    getCompanyLocations(),
    getCompanySizes(),
    getCompanyTags(),
  ]);

  const favoriteCompanies = getMyCompaniesResponse && getMyCompaniesResponse?.length > 0
    ? getMyCompaniesResponse.map((company: any) => company.id)
    : []; // for mocking

  const tagsResponse = Array.isArray(tags) ? tags : [];
  const sizesResponse = Array.isArray(sizes) ? sizes : [];
  const locationsResponse = Array.isArray(locations) ? locations : [];
  const archivedCompaniesResponse = Array.isArray(archivedCompanies) ? archivedCompanies : [];
  const industriesResponse = Array.isArray(industries) ? industries : [];

  return {
    industries: industriesResponse,
    favoriteCompanies: favoriteCompanies || [],
    applications: tilesResponse || [],
    archivedCompanies: archivedCompaniesResponse,
    locations: locationsResponse,
    sizes: sizesResponse,
    tags: tagsResponse,
  };
}

export async function favoriteCompaniesLoader(): Promise<FavoriteCompaniesResponse> {
  const [myCompaniesResponse, tilesResponse, archivedCompanies, locations, sizes, tags] = await Promise.all([
    getFavoriteCompanies(),
    getApplications(),
    getArchivedApplications(),
    getCompanyLocations(),
    getCompanySizes(),
    getCompanyTags(),
  ]);

  const archivedCompaniesIds = Array.isArray(archivedCompanies) ? archivedCompanies.map((company: any) => company.company_id) : [];
  const filteredCompanies = Array.isArray(myCompaniesResponse) ? myCompaniesResponse.filter((company: Company) => !archivedCompaniesIds.includes(company.id)) : [];

  return {
    favoriteCompanies: filteredCompanies || [],
    applications: tilesResponse || [],
    archivedCompanies: archivedCompanies || [],
    locations: locations || [],
    sizes: sizes || [],
    tags: tags || [],
  };
}

export async function applicationsLoader(): Promise<OverviewResponse> {
  const tilesResponse = await getApplications();

  return { tiles: tilesResponse || [] };
}

export async function filterCompaniesLoader(): Promise<FilteredCompaniesResponse> {
  const [getMyCompaniesResponse, tilesResponse, archivedCompanies, locations, sizes, tags] = await Promise.all([
    getFavoriteCompanies(),
    getApplications(),
    getArchivedApplications(),
    getCompanyLocations(),
    getCompanySizes(),
    getCompanyTags(),
  ]);

  const myCompanies = getMyCompaniesResponse && getMyCompaniesResponse?.length > 0
    ? getMyCompaniesResponse.map((company: any) => company.id)
    : []; // for mocking

  const tagsResponse = Array.isArray(tags) ? tags : [];
  const sizesResponse = Array.isArray(sizes) ? sizes : [];
  const locationsResponse = Array.isArray(locations) ? locations : [];

  return {
    favoriteCompanies: myCompanies || [],
    applications: tilesResponse || [],
    archivedCompanies: archivedCompanies || [],
    locations: locationsResponse,
    sizes: sizesResponse,
    tags: tagsResponse,
  };
}
