import { ResourceTile } from '../resource-tile/resource-tile';
import BayesLogo from '@/assets/images/university/Bayes.jpg';
import HHLLogo from '@/assets/images/university/HHL.png';
import { dashboardUniversityResources } from './dashboard-resources';

type Props = {
  universityName: string;
  universityCohort?: string;
  selfId: string;
  isHighlighted?: boolean;
};

export const DashboardUniversityResources = ({
  universityName,
  universityCohort,
  selfId,
  isHighlighted = false,
}: Props) => (
  <>
    {universityName === 'Bayes Business School' && (
      <div className="width-24/24">
        <div className={`student-dashboard__box ${isHighlighted ? 'student-dashboard__box--highlighted' : ''}`}>
          <div className="student-dashboard__university-logo">
            <img src={BayesLogo} alt="Bayes Business School Logo" />
          </div>
        </div>
      </div>
    )}
    {universityName === 'HHL Leipzig Graduate School of Management' && (
      <div className="width-24/24">
        <div className={`student-dashboard__box ${isHighlighted ? 'student-dashboard__box--highlighted' : ''}`}>
          <div className="student-dashboard__university-logo">
            <img src={HHLLogo} alt="HHL Leipzig Graduate School of Management" />
          </div>
        </div>
      </div>
    )}
    {(dashboardUniversityResources(selfId)[universityName] && !dashboardUniversityResources(selfId)[universityName].excludedCohorts?.includes(universityCohort || '')) && (
      <div className="width-24/24">
        <div className={`student-dashboard__box ${isHighlighted ? 'student-dashboard__box--highlighted' : ''}`}>
          {dashboardUniversityResources(selfId)[universityName].data.map((resource) => (
            <>
              {resource.onlyForCohorts && resource.onlyForCohorts.includes(universityCohort || '') && (
                <ResourceTile
                  name={resource.name}
                  description={resource.description}
                  image={resource.image}
                  linkLabel={resource.linkLabel}
                  link={resource.link}
                />
              )}
              {!resource.onlyForCohorts && !resource.excludedCohorts && (
                <ResourceTile
                  name={resource.name}
                  description={resource.description}
                  image={resource.image}
                  linkLabel={resource.linkLabel}
                  link={resource.link}
                />
              )}
              {resource.excludedCohorts && !resource.excludedCohorts.includes(universityCohort || '') && (
                <ResourceTile
                  name={resource.name}
                  description={resource.description}
                  image={resource.image}
                  linkLabel={resource.linkLabel}
                  link={resource.link}
                />
              )}
            </>
          ))}
        </div>
      </div>
    )}
  </>
);
