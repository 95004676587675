import { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
// eslint-disable-next-line import/no-cycle
import { ModalContext } from './modal-provider';
import './modal.scss';

// TODO: find a better way to solve problem with root
const modalRoot = document.querySelector('#modal-root') as HTMLElement || document.querySelector('#storybook-root') as HTMLElement;
const Modal = () => {
  const {
    modalContent, showModal, closeModal, modalNotDismissible,
  } = useContext(ModalContext) as any;

  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27 && !modalNotDismissible) {
      closeModal();
    }
  };

  const handleCloseModal = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!modalNotDismissible) {
      closeModal();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, []);
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showModal]);

  if (showModal) {
    return createPortal(
      <div className={showModal ? 'modal show' : 'modal'} onClick={handleCloseModal}>
        <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          <div onClick={closeModal} className={`modal__close ${modalNotDismissible ? 'modal__close--disabled' : ''}`}>
            <i className="bi bi-x-lg" />
          </div>
          <div className="modal__body">{modalContent}</div>
        </div>
      </div>,
      modalRoot,
    );
  } return null;
};

export default Modal;
