/* eslint-disable no-restricted-syntax */
import { useNavigate, useParams } from 'react-router-dom';
import {
  useMemo, useState,
} from 'react';
import { Button } from '@careeros/coco';
import { Loader } from '@/components/loader/loader';
import '@/pages/styles/dashboard.scss';
import {
  StudentResumeTableColumns,
} from '@/domains/core/advisor';
import { Input } from '@/components/input/input';
import AnalyticsImage from '@/assets/images/analitic-man.svg';
import { TableRow } from '@/components/table/table-types';
import {
  buildCohortsWithAll,
  mapStudentsToResumeTableData,
} from '@/services/helpers/advisor';
import { useSelfUniversity } from '@/services/queries/user';
import CustomWithAuthenticationRequired from '../../auth/custom-protected-route';
import { Student } from '@/domains/core/advisor/types';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { useStudentsByUniversity } from '@/services/hooks/use-students-by-university';
import { useAdvisorData } from '@/services/hooks/use-student-data';
import Table from '@/components/table/table';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';

function StudentsResumesPage() {
  const navigate = useNavigate();
  const [parsedStudents, setParsedStudents] = useState<TableRow[]>([]);
  const [searchStudentsValue, setSearchStudentsValue] = useState('');
  const { data: university } = useSelfUniversity();
  const { universityID, cohortID } = useParams();
  const {
    data: studentsResponse,
    cohorts,
    isCohortsLoading,
    isStudentsLoading,
  } = useStudentsByUniversity('resumes', universityID || university?.id);

  const parseStudents = (unParsedStudents: Student[]) => {
    const pStudents = mapStudentsToResumeTableData(unParsedStudents);
    setParsedStudents(pStudents);
  };

  const cohortsWithAll = useMemo(() => {
    if (!cohorts) return [];
    return buildCohortsWithAll('/app/resume-os/students', cohorts, universityID, university?.id);
  }, [cohorts]);

  const {
    students,
    isLoading,
  } = useAdvisorData(
    studentsResponse,
    universityID || university?.id,
    cohortID,
    parseStudents,
  );

  const searchStudentByName = (name: string) => {
    const filteredStudents = students
      .filter(Boolean)
      .filter((student: Student) => {
        const fullName = `${student.first_name} ${student.last_name}`;
        return (fullName.toLowerCase().includes(name.toLowerCase().trim()) || student.email.toLowerCase().includes(name.toLowerCase().trim()));
      });
    const filteredParsedStudents = mapStudentsToResumeTableData(filteredStudents);
    setParsedStudents(filteredParsedStudents);
    setSearchStudentsValue(name);
  };

  useAdvisorRoleCheck();

  if (isCohortsLoading || isStudentsLoading || isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-dashboard">
      <div className="advisor-dashboard__top-container">
        <div className="advisor-dashboard__top">
          <CohortsNavigation
            links={cohortsWithAll}
            defaultLinkName="All Cohorts"
            withBorderBottom
          />
        </div>
        <Button
          mode="primary"
          size="medium"
          label="Create a Resume Book"
          icon="bi bi-files"
          onClick={() => navigate(`/app/resume-os/students/${universityID || university?.id}/${cohortID}/resume-book`)}
        />
      </div>

      <div className="advisor-dashboard__content">
        {parseStudents.length > 0 ? (
          <div className="career-os-grid">
            <div className="width-24/24">
              <Input
                icon="bi bi-search"
                value={searchStudentsValue}
                id="search-students"
                label=""
                placeholder="Search Students by Name or Email"
                handleValueChange={searchStudentByName}
              />
            </div>
            <div className="width-24/24">
              <div className="student__table">
                <Table
                  data={parsedStudents}
                  columns={StudentResumeTableColumns}
                  withRowNumber
                  hasTooltip={false}
                />
              </div>
            </div>
          </div>
        )
          : (
            <div className="no-students">
              <div className="advisor-dashboard__image">
                <div className="coming-soon-box">
                  Coming soon
                </div>
                <img className="advisor-dashboard__image" src={AnalyticsImage} alt="" />
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(StudentsResumesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
