import {
  ApiStudent,
} from '@/domains/core/advisor/types/api/student';
import { StudentModel } from '@/domains/core/advisor/types';
import { convertFromApiStudents } from '@/domains/core/advisor/api/model/student';
import { http } from '@/services/api/http';

export const getStudents = async (universityID: string, lastViewedAt: Date, companyID?: string): Promise<StudentModel[]> => {
  const url = `/university/students?last_viewed_at=${encodeURIComponent(lastViewedAt.toISOString())}${companyID ? `&company_id=${companyID}` : ''}`;

  const res: ApiStudent[] = await http.get(url, 'v2');

  return convertFromApiStudents(res);
};

export const sendMassEmail = async (message: string, subject: string, emails: string[]): Promise<any> => http.post('/university/advisor/sendmail', {
  body: message,
  subject,
  emails,
}, undefined, 'v2');
