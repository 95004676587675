import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { NavbarItemName, LinkType } from './types/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useSelfUniversity } from '@/services/queries/user';
import { getLinksForRole } from './utils/links';
import { StudentLinks } from './navbar-links';
import { StudentNavbarView } from './components/navbar-view/navbar-view';
import { useNavbarContext } from '@/services/hooks/use-navbar-context';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';

type Props = {
  collapsed: boolean;
  handleToggle: () => void;
  isOnboarding?: boolean;
};

export default function Navbar({ collapsed, handleToggle, isOnboarding }: Props) {
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { unreadTabs, addUnreadTab, removeUnreadTab } = useNavbarContext();
  const [links, setLinks] = useState<LinkType[]>([]);
  const { checkLinkedInLogin } = useExtensionMessaging();
  const { data: university, isLoading: selfLoading } = useSelfUniversity();
  const [cookies] = useCookies(['cookiesAccepted']);
  const [logoLink, setLogoLink] = useState('/app/dashboard');
  const handleLinkClick = (linkName: string) => {
    trackEvent('Page Visit', user, {
      pageURL: linkName,
    });
    const newLinks = links?.map((linksLink: LinkType) => {
      linksLink.isActive = false;
      return linksLink;
    });
    setLinks(newLinks);
  };

  const setUpLinks = () => {
    if (user && university) {
      const roles = user['https://careeros.com/roles'];
      const linksByRole = getLinksForRole(roles, university);

      if (roles.includes('CareerAdvisor')) {
        setLogoLink(`/app/dashboard-advisor/${university.id}`);
      } else if (roles.includes('Admin')) {
        setLogoLink('/app/admin-view');
      }

      setLinks(linksByRole);
    }
  };

  const checkIfExtensionInstalled = async () => {
    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      addUnreadTab(NavbarItemName.ChromeExtension);
    } else {
      removeUnreadTab(NavbarItemName.ChromeExtension);
    }
  };

  useEffect(() => {
    if (!selfLoading) {
      setUpLinks();
    }
  }, [user, university, selfLoading]);

  useEffect(() => {
    checkIfExtensionInstalled();

    // Checking if extension is installed on tab visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkIfExtensionInstalled();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isOnboarding ? null : (
    <StudentNavbarView
      collapsed={collapsed}
      isCookieBannerVisible={!cookies.cookiesAccepted}
      showContent
      logoLink={logoLink}
      links={links}
      handleToggle={handleToggle}
      handleLinkClick={handleLinkClick}
      splitBefore={links[0] !== StudentLinks[0] ? StudentLinks[0].label : undefined}
      unreadTabs={unreadTabs}
    />
  );
}
