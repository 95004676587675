import { DropdownSearch } from '@careeros/coco';
import { useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CohortLinkDataType } from '../../types';
import './cohorts-navigation.scss';

const MAX_COHORTS_NUMBER = 7;

type Props = {
  links: CohortLinkDataType[];
  defaultLinkName?: string;
  withBorderBottom?: boolean;
};

export const CohortsNavigation = ({ links, defaultLinkName, withBorderBottom = false }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const activeLinkName = useMemo(() => {
    const activeLink = links.find((link) => location.pathname === link.url);
    return activeLink?.label || defaultLinkName;
  }, [links, defaultLinkName, location.pathname]);

  const mappedLinks = useMemo(() => links.map((link) => ({
    name: link.label,
    value: link.url,
  })), [links]);

  const handleItemSelect = (_item: string, value: any) => {
    navigate(value);
  };

  return (
    links.length < MAX_COHORTS_NUMBER ? (
      <div className={`cohorts-navigation ${withBorderBottom ? 'cohorts-navigation--with-border' : ''}`}>
        {links.map((link) => (
          <Link
            key={link.url}
            to={link.url}
            className={`cohorts-navigation__item ${activeLinkName === link.label ? 'cohorts-navigation__item--active' : ''}`}
          >
            {link.label}
          </Link>
        ))}
      </div>
    ) : (
      <DropdownSearch
        name="cohorts-navigation"
        selectedOption={links[0]?.label}
        id="cohorts-navigation"
        options={mappedLinks}
        placeholder="Search Cohort"
        handleItemSelect={handleItemSelect}
        canAddItems={false}
        showIcon={false}
        openOnClick
        size="medium"
        inputIcon="bi bi-search"
      />
    )
  );
};
