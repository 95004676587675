import { Button } from '@careeros/coco';
import { useNavigate } from 'react-router-dom';
import './tally-info-empty.scss';

export const TallyInfoEmptyUser = () => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/app/onboarding');
  };
  return (
    <div className="tally-info-empty">
      <div className="tally-info-empty__title">Targets and Preferences</div>
      <div className="tally-info-empty__description">
        <p className="tally-info-empty__description-title">Fill out a 2 minute form, so we can:</p>
        <ul className="tally-info-empty__description-list">
          <li className="tally-info-empty__description-item">recommend the right companies</li>
          <li className="tally-info-empty__description-item">show you the right people</li>
          <li className="tally-info-empty__description-item">get you the best jobs.</li>
        </ul>
      </div>
      <Button
        handleClick={handleButtonClick}
        label="Personalize my Experience"
        icon="bi bi-arrow-right"
        iconPosition="right"
        mode="primary"
        size="medium"
        outlined
      />
    </div>
  );
};
