/* eslint-disable @typescript-eslint/no-use-before-define */
import { Link } from 'react-router-dom';
import {
  ReactNode, useRef,
} from 'react';
import { Tooltip } from '@careeros/coco';
import { TableRowItem } from '@/components/table/table-types';
import './table-cell-without-tooltip.scss';

type Props = {
  cellData: TableRowItem;
  type: string;
  isCentered?: boolean;
  isFitContent?: boolean;
};

export const TableCellWithoutTooltip = ({
  cellData,
  type,
  isCentered,
  isFitContent = false,
}: Props) => {
  const linkTextRef = useRef<HTMLSpanElement>(null);
  const cellTextRef = useRef<HTMLSpanElement | HTMLDivElement>(null);

  const handleLinkClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (cellData.handleClick) {
      e.preventDefault();
      cellData.handleClick();
    }
  };

  let content: ReactNode | string = '——';
  switch (type) {
    case 'text':
      content = (cellData !== null && cellData !== undefined) ? cellData.label : '——';
      break;
    case 'image':
      content = <img className="table__image" src={cellData.src} alt={cellData.alt || ''} />;
      break;
    case 'icon':
      content = <i className={`table-cell-without-tooltip__icon ${cellData.src}`} />;
      break;
    case 'link-icon':
      content = (
        <Link onClick={(e) => e.stopPropagation()} to={cellData.to || ''} state={cellData.state}>
          <Tooltip label={cellData.tooltip || cellData.value?.toString()} withArrow position="top">
            <i className={`table-cell-without-tooltip__icon ${cellData.icon}`} />
          </Tooltip>
        </Link>
      );
      break;
    case 'link':
      content = (
        <Link className="table-cell-without-tooltip__link" onClick={handleLinkClick} to={cellData.to || ''} target={cellData.target || '_self'}>
          {cellData.image && (
            <div className="table-cell-without-tooltip__link-image">
              {cellData.image}
            </div>
          )}
          <span ref={linkTextRef} className="table-cell-without-tooltip__link-text">
            <span>
              {cellData.label}
            </span>
          </span>
        </Link>
      );
      break;
    default:
      content = cellData.label || '——';
  }

  return (
    <td className={`table-cell-without-tooltip ${isCentered ? 'table-cell-without-tooltip--centered' : ''} ${isFitContent ? 'table-cell--fit-content' : ''}`}>
      <span ref={cellTextRef} className="table-cell-without-tooltip__content">{content}</span>
    </td>
  );
};
