import { useQuery } from 'react-query';
import { getAllAdvisors } from '@/domains/core/advisor/api/advisor';
import { CAStudentNote } from '@/domains/core/advisor/types';
import { getCAStudentNotes } from '@/domains/core/advisor/api/note';

export const useGetAllAdvisors = (universityID: string, options = {}) => useQuery(
  ['all-advisors', universityID],
  async () => {
    const response = await getAllAdvisors(universityID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    enabled: !!universityID,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
    ...options,
  },
);

export const useStudentNotes = (studentID: string, options = {}) => useQuery(
  ['ca-student-notes', studentID],
  async () => {
    const studentNotes: CAStudentNote[] = await getCAStudentNotes(studentID);

    return Array.isArray(studentNotes) ? studentNotes : [];
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);
