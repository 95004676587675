import { Tooltip } from '@careeros/coco';
import './suggested-contact-tag.scss';

type Props = {
  label: string;
  tooltipLabel: string;
  icon: string;
  color: 'purple' | 'green';
};

export const SuggestedContactTag = ({
  label,
  tooltipLabel,
  icon,
  color,
}: Props) => (
  <div className="suggested-contact-tag">
    <Tooltip position="top" withArrow label={tooltipLabel}>
      <div className={`suggested-contact-tag__content suggested-contact-tag__content--${color}`}>
        <i className={`suggested-contact-tag__icon ${icon}`} />
        <span className="suggested-contact-tag__label" title={tooltipLabel}>{label}</span>
      </div>
    </Tooltip>
  </div>
);
