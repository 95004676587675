/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { pdf } from '@react-pdf/renderer';
import { PDFDocumentProxy } from 'pdfjs-dist';
import * as Sentry from '@sentry/browser';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface MemoizedPDFDocumentProps {
  pdfUrl: string;
  numberOfPages: number | null;
  opt: any;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
  updateOldResume: (url: string) => void;
}

const hasPageContent = async (pdfDoc: any, pageNumber: number) => {
  const page = await pdfDoc.getPage(pageNumber);
  const operatorList = await page.getOperatorList();
  return operatorList.fnArray.length > 0;
};

const MemoizedPDFDocument: React.FC<MemoizedPDFDocumentProps> = React.memo(
  ({
    pdfUrl, numberOfPages, opt, onDocumentLoadSuccess, updateOldResume,
  }: any) => {
    const [pagesWithContent, setPagesWithContent] = useState<number[]>([]);

    const checkPages = async () => {
      try {
        const pdfFile = await pdfjs.getDocument(pdfUrl).promise;
        const contentPages = [];
        for (let i = 1; i <= Math.min(numberOfPages, pdfFile.numPages); i++) {
          if (await hasPageContent(pdfFile, i)) {
            contentPages.push(i);
          }
        }
        setPagesWithContent(contentPages);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    useEffect(() => {
      checkPages();
    }, [pdfUrl, numberOfPages]);

    const pages = useMemo(() => pagesWithContent.map(
      (pageNumber) => (
        <Page
          renderMode="canvas"
          key={`page_${pageNumber}`}
          pageNumber={pageNumber}
          onRenderSuccess={() => updateOldResume(pdfUrl)}
          scale={2}
        />
      ),
    ), [pagesWithContent, pdfUrl, updateOldResume]);

    return (
      <Document
        options={opt}
        loading={null}
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className="rendering-document"
      >
        {pages}
      </Document>
    );
  },
  (prevProps, nextProps) => prevProps.pdfUrl === nextProps.pdfUrl,
);

const PDFViewer = ({ children, onError, setNumberOfPages }: any) => {
  const [pdfUrl, setPdfUrl] = useState<any>(null);
  const [previousRenderValue, setPreviousRenderValue] = useState<any>(null);
  const [numberOfPages, setNumPages] = useState<any>(null);

  const onDocumentLoadSuccess = useCallback(async ({ numPages }: { numPages: number }) => {
    if (!pdfUrl) return;
    const pdfDoc = await pdfjs.getDocument(pdfUrl).promise;
    let contentPageCount = 0;
    for (let i = 1; i <= numPages; i++) {
      if (await hasPageContent(pdfDoc, i)) {
        contentPageCount++;
      }
    }
    const finalPageCount = Math.min(contentPageCount, numPages);
    setNumPages(finalPageCount);
    setNumberOfPages(finalPageCount);
    if (contentPageCount > 3) {
      onError('The resume should be less than 3 pages');
    }
  }, [pdfUrl, onError]);

  const opt = useMemo(() => ({
    cMapUrl: '/bcmaps/',
    cMapPacked: true,
  }), []);

  const updateOldResume = (url: string) => {
    setPreviousRenderValue(url);
  };

  const renderPDF = async (mounted: boolean) => {
    let blobUrl: string | null = null;
    const child = React.Children.only(children);
    try {
      const blob = await pdf(child).toBlob();
      if (!mounted) return;

      blobUrl = URL.createObjectURL(blob);
      setPdfUrl(blobUrl);
    } catch (error) {
      console.error('PDF rendering failed:', error);
    }
  };

  useEffect(() => {
    let mounted = true;

    renderPDF(mounted);

    return () => {
      mounted = false;
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [children.props.resume, children.props.title, children.props.highlightedWords, children.props.activeField, numberOfPages]);

  useEffect(() => {
    if (pdfUrl) {
      pdfjs.getDocument(pdfUrl).promise.then((pdfFile: PDFDocumentProxy) => {
        onDocumentLoadSuccess({ numPages: pdfFile.numPages });
      });
    }
  }, [pdfUrl]);

  return (
    <div className="renders">
      {previousRenderValue ? (
        <Document
          key={previousRenderValue}
          className="previous-document"
          file={previousRenderValue}
          loading={null}
        >
          <Page renderMode="canvas" key={1} pageNumber={1} scale={2} />
        </Document>
      ) : null}
      {pdfUrl && (
        <MemoizedPDFDocument
          pdfUrl={pdfUrl}
          numberOfPages={numberOfPages}
          opt={opt}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          updateOldResume={updateOldResume}
        />
      )}
    </div>

  );
};

export default PDFViewer;
