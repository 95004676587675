import { IconButton, Tooltip } from '@careeros/coco';
import { ConnectionStatus } from '@/domains/core/contact/types';
import './contact-tile.scss';
import { CommunicationChannel } from '@/domains/core/netwoking/types';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';
import { ContactTileStep } from '../contact-tile-step/contact-tile-step';

type InteractionWithContact = {
  date?: string;
  name: string;
  type: 'add contact' | 'message' | 'outreach';
};

type Props = {
  name: string;
  image: string;
  companyName: string;
  position: string;
  linkedInConnectionStatus: ConnectionStatus;
  latestsActivities?: InteractionWithContact[];
  nextActivity?: InteractionWithContact;
  communicationChannel: CommunicationChannel;
  isArchived?: boolean;
  hideComminicationChannel?: boolean;
  message?: string;
  isLoadingSteps?: boolean;
  handleTileClick: () => void;
  handleArchive: (shouldArchive: boolean) => void;
  readonly?: boolean;
};

export const ContactKanbanTile = ({
  image,
  name,
  companyName,
  position,
  linkedInConnectionStatus,
  latestsActivities,
  nextActivity,
  communicationChannel,
  handleTileClick,
  isArchived = false,
  hideComminicationChannel,
  message,
  isLoadingSteps,
  handleArchive,
  readonly = false,
}: Props) => {
  const handleArchiveClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    handleArchive(!isArchived);
  };
  const handleClicked = () => {
    if (readonly || isArchived) {
      return;
    }

    handleTileClick();
  };

  return (
    <div className={`contact-kanban-tile ${readonly ? 'contact-kanban-tile--readonly' : ''}`} onClick={handleClicked}>
      <div className="contact-kanban-tile__top">
        <div className="contact-kanban-tile__header">
          <div className="contact-kanban-tile__avatar">
            <DefaultLogo
              type="contact"
              source={image}
              className="contact-kanban-tile__avatar"
              name={name}
              size={48}
            />
            {(!isArchived && !hideComminicationChannel) && (
            <div className="contact-kanban-tile__avatar-icon">
              <i className={`bi bi-${communicationChannel === 'email' ? 'envelope' : 'linkedin'}`} />
            </div>
            )}
          </div>
          <div className="contact-kanban-tile__info">
            <div className={`contact-kanban-tile__name ${isArchived ? 'contact-kanban-tile__name--archived' : ''}`}>{name}</div>
            <div className="contact-kanban-tile__company">{companyName}</div>
            <div className="contact-kanban-tile__company">{position}</div>
          </div>
        </div>

        <div className="contact-kanban-tile__status">
          <div className={`contact-kanban-tile__archive-action ${readonly ? 'contact-kanban-tile__archive-action--hidden' : ''}`}>
            {isArchived ? (
              <Tooltip position="top" withArrow label="Save">
                <IconButton
                  icon="bi bi-bookmark-heart"
                  onClick={handleArchiveClick}
                  mode="rounded"
                  outlined
                  size="small"
                />
              </Tooltip>
            ) : (
              <Tooltip position="top" withArrow label="Archive">
                <IconButton
                  icon="bi bi-archive"
                  onClick={handleArchiveClick}
                  mode="rounded"
                  outlined
                  size="small"
                  isDangerous
                />
              </Tooltip>
            )}
          </div>
          {!isArchived && <StatusTag status={linkedInConnectionStatus} type="linkedin" size="small" withTooltip />}
        </div>
      </div>
      {/* {isLoadingSteps && (
              <div className="contact-kanban-tile__steps">
                <ContactTileStepSkeleton />
              </div>
            )}
            {!isLoadingSteps && message && (
              <div className="contact-kanban-tile__message">
                <ContactTileStep name={stripHTML(message)} icon="bi bi-chat-square-text" />
              </div>
            )} */}

      {/* This is not used currently, needs backend */}
      {!isLoadingSteps && !message && (latestsActivities || nextActivity) && !isArchived && (
      <div className="contact-kanban-tile__steps">
        {latestsActivities?.map((activity) => (
          <ContactTileStep key={activity.name} name={activity.name} date={activity.date} icon="bi bi-person-add" />
        ))}
        {nextActivity && (
          <ContactTileStep name={nextActivity.name} date={nextActivity.date} icon="bi bi-chat-left" isActive />
        )}
      </div>
      )}
    </div>
  );
};
