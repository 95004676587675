import {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { getSearchParamsStringContacts, updateQueryString } from '../../domains/core/advisor/utils/getSearchParamsStringContacts';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { getCompaniesAndIndustries, getContactsForContactsBook } from '@/domains/core/advisor/api/contacts-book';
import { ContactBookFiltersType, ContactFilterCompany, ContactType } from '@/domains/core/advisor/types';

type Props = {
  currentTab: string | null;
  defaultFilters: ContactBookFiltersType;
  cohortID?: string;
};

export const useContactBookFiltering = ({ currentTab, defaultFilters, cohortID }: Props) => {
  const [isPageEnd, setIsPageEnd] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const [totalContactsCount, setTotalContactsCount] = useState<number>(0);
  const [nextPage, setNextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [staticOptionsLoading, setStaticOptionsLoading] = useState(true);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState('');
  const [currentLoadingId, setCurrentLoadingId] = useState('');
  const [companies, setCompanies] = useState<ContactFilterCompany[]>([]);
  const [industries, setIndustries] = useState<{
    name: string;
  }[]>([]);
  const observerTarget = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useState<ContactBookFiltersType>(defaultFilters);
  const isBookmarkedContactsTabSelected = useMemo(() => currentTab === 'Saved Contacts', [currentTab]);

  const searchQuery = useMemo(() => getSearchParamsStringContacts(filters).toString(), [filters]);

  const fetchContacts = async () => {
    setNextPage(1);
    setContacts([]);
    setIsPageEnd(false);
    setIsNewPageLoading(true);
    setError(null);
    setIsLoading(true);

    try {
      const response = await getContactsForContactsBook(cohortID, 1, false, searchQuery);

      setTotalContactsCount(response.total_count);

      if (!response.contacts || !response.contacts?.length) {
        setIsPageEnd(true);
        return;
      }

      setContacts(response.contacts);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPage(1);
      setIsLoading(false);
    }
  };

  const getCompaniesAndIndustriesArrays = async () => {
    setStaticOptionsLoading(true);
    try {
      const response = await getCompaniesAndIndustries();
      if (response.companies && response.companies.length > 0) {
        setCompanies(response.companies);
      }
      if (response.industries && response.industries.length > 0) {
        setIndustries(response.industries);
      }
    } catch (err) {
      setError(err as Error);
    }
    setStaticOptionsLoading(false);
  };

  const fetchNextPageData = async () => {
    if (!isBookmarkedContactsTabSelected && isPageEnd) {
      return;
    }

    setIsNewPageLoading(true);
    setError(null);

    try {
      const response = await getContactsForContactsBook(cohortID, nextPage, false, searchQuery);

      setTotalContactsCount(response.total_count);

      if (!response.contacts || !response.contacts?.length) {
        setIsPageEnd(true);
        return;
      }

      const newContacts = [...contacts, ...response.contacts];

      setContacts(newContacts);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPage(nextPage);
    }
  };

  const selfLoading = useMemo(() => isLoading || staticOptionsLoading, [isLoading, staticOptionsLoading]);

  useEffect(() => {
    if (!isBookmarkedContactsTabSelected && nextPage > currentPage && !isNewPageLoading && !isPageEnd) {
      fetchNextPageData();
    }
  }, [nextPage, currentPage, isNewPageLoading, isPageEnd]);

  useDebounceEffect(fetchContacts, [searchQuery, cohortID]);

  useEffect(() => {
    getCompaniesAndIndustriesArrays();
  }, []);

  useEffect(() => {
    if (selfLoading) {
      return;
    }

    const searchString = getSearchParamsStringContacts(filters);
    updateQueryString(searchString);
  }, [filters, selfLoading]);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (!observer && observerTarget.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setNextPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observer && observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget.current, contacts.length, isBookmarkedContactsTabSelected]);

  return {
    contacts,
    filters,
    observerTarget,
    isNewPageLoading,
    isLoading: selfLoading,
    error,
    currentActiveId,
    totalContactsCount,
    currentLoadingId,
    isPageEnd,
    companies,
    industries,
    setFilters,
    setCurrentActiveId,
    setCurrentLoadingId,
    setTotalContactsCount,
    setContacts,
  };
};
