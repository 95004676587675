import './action-card.scss';
import { AvatarStack, AvatarStackItem } from '../avatar-stack/avatar-stack';

export type AccentColorType = 'information' | 'tertiary50' | 'primary30' | 'primary50' | 'secondary40' | 'warning-light' | 'blue-green70' | 'error20';

type Props = {
  title: string;
  imageStack: AvatarStackItem[];
  backgroundIcons: [string, string];
  accentColor?: AccentColorType;
  handleClick: () => void;
  isBlurredImageStack?: boolean;
  withImageStackHover?: boolean;
  withImageStackTooltip?: boolean;
};

export const ActionCard = ({
  title,
  imageStack,
  backgroundIcons,
  accentColor = 'information',
  isBlurredImageStack = false,
  withImageStackHover = false,
  withImageStackTooltip = false,
  handleClick,
}: Props) => (
  <div onClick={handleClick} className={`action-card action-card--color-${accentColor}`}>
    <div className="action-card__background-icons">
      {backgroundIcons.map((icon) => (
        <i key={icon} className={`action-card__background-icon ${icon}`} />
      ))}
    </div>
    <div className="action-card__title">
      <span>{title}</span>
    </div>
    {imageStack.length > 0 && (
      <div className="action-card__image-stack">
        <AvatarStack
          personList={imageStack}
          withPerItemHover={withImageStackHover}
          withPerItemTooltip={withImageStackTooltip}
          isBlurred={isBlurredImageStack}
        />
      </div>
    )}
  </div>
);
