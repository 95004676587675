/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext } from 'react';
import { StudentModel } from '@/domains/core/advisor/types';

// Create the context
export type StudentContextType = {
  student: StudentModel;
};

const StudentContext = createContext<StudentContextType | null>(null);

// Create a custom hook to use the context
export const useStudentContext = () => useContext(StudentContext);

type StudentProviderProps = {
  children: React.ReactNode;
  student: StudentModel;
};

// Create a provider component
export const StudentContextProvider = ({ children, student }: StudentProviderProps) => (
  <StudentContext.Provider value={{
    student,
  }}
  >
    {children}
  </StudentContext.Provider>
);
