import { useQueryClient } from 'react-query';
import {
  useContext, useState,
} from 'react';
import { Button } from '@careeros/coco';
import { NoteInput } from '@/components/note-input/note-input';
import NoteCard from '@/components/note-card/note-card';
import './contact-notes.scss';
import { InboxContextType, useInboxContext } from '@/pages/context/inbox';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { AddToastType } from '@/domains/generic/toasts/types';
import { addContactNote, deleteContactNote, editContactNote } from '@/services/api/contact';
import { useGetContactNotes } from '@/services/queries/contact';

export const ContactNotes = () => {
  const { currentContact: contact } = useInboxContext() as InboxContextType;
  const { data: notes, isLoading: isNotesLoading } = useGetContactNotes(contact.id, {
    enabled: !!contact.id,
  });
  const [isAddingNote, setIsAddingNote] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();

  const invalidateNotes = () => queryClient.invalidateQueries(['contact-notes', contact.id]);

  const handleAddNote = async (title: string, content: string, successCallback: () => void) => {
    if (!title.trim().length || !content.trim().length) {
      return;
    }

    try {
      const noteResponse = await addContactNote(contact.id, title, content);

      if (noteResponse.error) {
        throw new Error('Failed to add note');
      }

      invalidateNotes();
      trackEvent('New contact note added', {});
      successCallback();
      setIsAddingNote(false);
      addToast(
        {
          type: 'success',
          message: 'Note added successfully',
          additionalMessage: "This note is now saved to your 'Notes' section.",
        },
      );
    } catch (error) {
      addToast(
        {
          type: 'error',
          message: 'Failed to add note',
          additionalMessage: 'Please try again later',
        },
      );
      trackEvent('Toast Error Shown', {}, {
        message: 'Failed to add contact note',
        error,
      });
    }
  };

  const handleEditNote = async (id: string, title: string, content: string, successCallback: () => void) => {
    try {
      await editContactNote(contact.id, id, title, content);

      invalidateNotes();
      trackEvent('Contact Note Edited', {});
      successCallback();
      addToast({
        type: 'success',
        message: 'Note edited successfully',
        additionalMessage: 'This note is now saved to your "Notes" section.',
      });
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Failed to save note',
        additionalMessage: 'Please try again later',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'Failed to edit note',
        error,
      });
    }
  };

  const handleDeleteNote = async (id: string) => {
    try {
      await deleteContactNote(contact.id, id);
      trackEvent('Contact Note Deleted', {});

      invalidateNotes();
      addToast({
        type: 'success',
        message: 'Note deleted successfully',
        additionalMessage: "This note is now deleted from your 'Notes' section.",
      });
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Failed to delete note',
        additionalMessage: 'Please try again later',
      });
      trackEvent('Toast Error Shown', {}, {
        message: 'Failed to delete note',
        error,
      });
    }
  };

  const handleSaveSilently = async (title: string, content: string) => {
    if (!title.trim().length || !content.trim().length) {
      return;
    }

    await addContactNote(contact.id, title, content);
    invalidateNotes();
  };

  return (
    <div className="contact-notes">
      <div
        className="contact-notes__container"
      >
        {isAddingNote ? (
          <NoteInput
            parentID=""
            handleSave={handleAddNote}
            saveSilently={handleSaveSilently}
            handleCancel={() => setIsAddingNote(false)}
          />
        ) : (
          <div className="contact-notes__add-button">
            <Button
              label="Add Note"
              handleClick={() => setIsAddingNote(true)}
              size="medium"
              mode="invisible"
              icon="bi bi-plus-circle"
              isFullWidth
            />
          </div>
        )}
        {!isNotesLoading && notes?.map((note) => (
          <div key={note.id} className="contact-notes__note">
            <NoteCard
              note={note}
              parentID={contact.id}
              handleDelete={handleDeleteNote}
              handleEdit={handleEditNote}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
