import { Tooltip } from '@careeros/coco';
import './action-button-save.scss';

interface ActionButtonSaveProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading: boolean;
  ariaLabel: string;
  label: string;
  iconName: string;
  ariaLabelSaved: string;
  labelSaved: string;
  iconNameSaved: string;
  isSaved: boolean;
}

export const ActionButtonSave = ({
  onClick,
  isLoading,
  ariaLabel,
  label,
  iconName,
  ariaLabelSaved,
  labelSaved,
  iconNameSaved,
  isSaved,
}: ActionButtonSaveProps) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick(e);
  };
  return isLoading ? (
    <div className="loader-5 center">
      <span />
    </div>
  ) : (
    <button
      className="icon"
      onClick={handleOnClick}
      type="button"
      aria-label={isSaved ? ariaLabelSaved : ariaLabel}
    >
      <Tooltip label={isSaved ? labelSaved : label} withArrow position="top">
        <i className={`bi ${isSaved ? iconNameSaved : iconName}`} />
      </Tooltip>
    </button>
  );
};
