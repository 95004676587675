import {
  ReactNode, useEffect, useRef,
} from 'react';
import ReactDOM from 'react-dom';
import './popup-box.scss';

type Props = {
  children: ReactNode;
  parentRef?: React.RefObject<HTMLDivElement>;
  handleBlur?: () => void;
};

export const PopupBoxWrapper = ({
  children,
  parentRef,
  handleBlur,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (listRef.current && !listRef.current.contains(event.target) && !parentRef?.current?.contains(event.target)) {
      handleBlur?.();
    }
  };

  const updatePosition = () => {
    if (listRef.current && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

      listRef.current.style.top = `${rect.bottom + scrollTop}px`;
      listRef.current.style.left = `${rect.left + scrollLeft}px`;

      const boxWidth = listRef.current.clientWidth;
      const boxVisibleWidth = window.innerWidth - (rect.left + scrollLeft);
      const boxOverflow = boxWidth - (boxVisibleWidth - 10);

      if (boxOverflow > 0) {
        listRef.current.style.width = `${Math.floor(boxVisibleWidth) - 100}px`;
        listRef.current.style.left = `${rect.left - boxWidth}px`;
        listRef.current.style.right = 'auto';
      }
    }
  };

  const createComponent = (): ReactNode => (
    <div ref={listRef} className="popup-box">
      {children}
    </div>
  );

  useEffect(() => {
    updatePosition();
    document.addEventListener('mousedown', handleClickOutside);
  }, [listRef.current, wrapperRef.current]);

  useEffect(() => () => {
    document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      ref={wrapperRef}
    >
      {ReactDOM.createPortal(createComponent(), document.querySelector('#root') as HTMLElement)}
    </div>
  );
};
