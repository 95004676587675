import { Button, Tooltip } from '@careeros/coco';
import './tally-info-empty.scss';

type Props = {
  email?: string;
  firstName?: string;
  showReminderButton?: boolean;
};

export const TallyInfoEmptyCA = ({ email, firstName = 'student', showReminderButton = false }: Props) => {
  const handleButtonClick = () => {
    if (!email) return;
    window.open(`mailto:${email}`);
  };

  return (
    <div className="tally-info-empty">
      <div className="tally-info-empty__title">Targets and Preferences</div>
      <div className="tally-info-empty__description">
        {`You’ll see ${firstName}’s target sectors, dream role, and even salary expectations once they complete their profile.`}
      </div>
      {showReminderButton && (
        <div className="tally-info-empty__with-tooltip">
          <Tooltip label="Sends a friendly nudge via email" position="bottom-center">
            <Button
              handleClick={handleButtonClick}
              label={`Send ${firstName} a Reminder`}
              icon="bi bi-arrow-right"
              iconPosition="right"
              mode="primary"
              size="medium"
              outlined
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
