import { useQuery } from 'react-query';
import { getFavoriteCompanies } from '../api/favorite';
import { getSingleCompany, getSingleCompanyProfile, getSingleCompanyProfileBySlug } from '../api/company';

export const useFavoriteCompanies = () => useQuery('favorite', getFavoriteCompanies, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 2,
});

export const useGetSingleCompany = (companyID: string | undefined, options = {}) => useQuery(['company', companyID], () => getSingleCompany(companyID), {
  retry: 0,
  // should be refetched in the background every 24 hours
  staleTime: 1000 * 60 * 60 * 24,
  ...options, // Spread additional options
});

export const useGetSingleCompanyProfile = (companyID: string | undefined, options = {}) => useQuery(['company-profile', companyID], () => getSingleCompanyProfile(companyID), {
  retry: 0,
  // should be refetched in the background every 24 hours
  enabled: !!companyID && companyID !== 'null',
  staleTime: 1000 * 60 * 60 * 24,
  ...options, // Spread additional options
});

export const useGetSingleCompanyProfileBySlug = (slug: string | undefined, options = {}) => useQuery(['company-profile-slug', slug], () => getSingleCompanyProfileBySlug(slug), {
  retry: 0,
  // should be refetched in the background every 24 hours
  enabled: !!slug && slug !== 'null',
  staleTime: 1000 * 60 * 60 * 24,
  ...options, // Spread additional options
});
