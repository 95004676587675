import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@careeros/coco';
import PlaceholderLogo from '@/assets/images/university/university-placeholder-logo.png';
import './multi-select-option.scss';

type Props = {
  isSelected: boolean;
  name: string | JSX.Element;
  width: 'full' | 'fit-box';
  icon?: string;
  showIcon?: boolean;
};

export const MultiSelectOption = ({
  name, isSelected, width, icon, showIcon,
}: Props) => {
  const [isOverflow, setIsOverflow] = useState(false);
  const elementRef = useRef<any>(null);

  useEffect(() => {
    if (elementRef.current) {
      setIsOverflow(elementRef.current.scrollWidth > elementRef.current.clientWidth);
    }
  }, []);

  return (
    <div className="multi-select-option">
      <div className={`multi-select-option__checkbox ${isSelected ? 'multi-select-option__checkbox--checked' : ''}`} />
      {showIcon && (
        <img
          src={icon || PlaceholderLogo}
          alt=""
          className="multi-select-option__item-image"
        />
      )}
      <Tooltip label={name.toString()} position="top" disableHoverListener={typeof name !== 'string' || !isOverflow}>
        <span ref={elementRef} className={`multi-select-option__name multi-select-option__name--${width}`}>{name}</span>
      </Tooltip>
    </div>
  );
};
