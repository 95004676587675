import { useState, useCallback } from 'react';
import recombee from 'recombee-js-api-client';

// Constants
const DATABASE_ID = import.meta.env.VITE_RECOMBEE_DATABASE_ID;
const PUBLIC_TOKEN = import.meta.env.VITE_RECOMBEE_PUBLIC_TOKEN;
const REGION = 'eu-west';

// Types and Interfaces
export enum ItemType {
  COMPANY = 'company',
  JOB = 'job',
  CONTACT = 'contact',
}

// Interaction types for purchase events
export enum PurchaseType {
  JOB_APPLICATION = 'job_application',
  CONTACT_MESSAGE = 'contact_message',
}

interface BaseOptions {
  timestamp?: string;
  cascadeCreate?: boolean;
  [key: string]: any;
}

interface ViewOptions extends BaseOptions {
  duration?: number;
  location?: string;
}

interface PurchaseOptions extends BaseOptions {
  type: PurchaseType;
  messageContent?: string;
  applicationData?: any;
}

interface RecombeeHookReturn {
  error: Error | null;
  loading: boolean;
  addDetailView: (
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options?: ViewOptions
  ) => Promise<boolean>;
  addBookmark: (
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options?: BaseOptions
  ) => Promise<boolean>;
  addPurchase: (
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options: PurchaseOptions
  ) => Promise<boolean>;
  ITEM_TYPES: typeof ItemType;
  PURCHASE_TYPES: typeof PurchaseType;
}

export const useRecombee = (): RecombeeHookReturn => {
  const [client] = useState(() => new recombee.ApiClient(
    DATABASE_ID,
    PUBLIC_TOKEN,
    { region: REGION },
  ));
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Helper to format item ID with type
  const formatItemId = useCallback((itemType: ItemType, itemId: string | number): string => `${itemType.toLowerCase()}:${itemId}`, []);

  // Generic interaction sender
  const sendInteraction = useCallback(async <T extends BaseOptions>(
    Action: any,
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options: T = {} as T,
  ): Promise<boolean> => {
    try {
      setLoading(true);
      setError(null);

      const formattedItemId = formatItemId(itemType, itemId);

      await client.send(new Action(
        userId,
        formattedItemId,
        {
          timestamp: new Date().toISOString(),
          cascadeCreate: true,
          additionalData: options,
        },
      ));
      return true;
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Unknown error occurred'));
      return false;
    } finally {
      setLoading(false);
    }
  }, [client, formatItemId]);

  const addDetailView = useCallback((
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options?: ViewOptions,
  ) => sendInteraction(recombee.AddDetailView, userId, itemType, itemId, options), [sendInteraction]);

  const addBookmark = useCallback((
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options?: BaseOptions,
  ) => sendInteraction(recombee.AddBookmark, userId, itemType, itemId, options), [sendInteraction]);

  const addPurchase = useCallback((
    userId: string,
    itemType: ItemType,
    itemId: string | number,
    options: PurchaseOptions,
  ) => sendInteraction(recombee.AddPurchase, userId, itemType, itemId, options), [sendInteraction]);

  return {
    error,
    loading,
    addDetailView,
    addBookmark,
    addPurchase,
    ITEM_TYPES: ItemType,
    PURCHASE_TYPES: PurchaseType,
  };
};

// When user applies to a job
// addPurchase(userId, ITEM_TYPES.JOB, jobId, {
//   type: PURCHASE_TYPES.JOB_APPLICATION,
//   applicationData: {
//     resumeId: '123',
//     coverLetter: true
//   }
// });

// // When user messages a contact
// addPurchase(userId, ITEM_TYPES.CONTACT, contactId, {
//   type: PURCHASE_TYPES.CONTACT_MESSAGE,
//   messageContent: 'Hello, I saw your profile...'
// });
