import { TemplateType } from '@/domains/core/netwoking/types';
import { http } from '@/services/api/http';

export const getCustomTemplates = async () => http.get('/templates/new');

export const saveCustomTemplate = async ({ title, subject = '', message }: Omit<TemplateType, 'id'>) => http.post('/templates/new', {
  title,
  subject,
  message,
});

export const updateCustomTemplate = async ({
  id, title, message, subject,
}: TemplateType) => http.patch(`/templates/new/${id}`, {
  title,
  subject,
  message,
});

export const deleteCustomTemplate = async (id: string) => http.delete(`/templates/new/${id}`);
