import { useQuery } from 'react-query';
import { useMemo } from 'react';
import {
  getAllStudents,
  getAllStudentsByCohortID,
  getAllStudentsResumes,
  getAllStudentsResumesByCohortID,
  getCohortsByUniversityID,
} from '../api/university';
import {
  getCombinedResume, getStudentAISummary, getStudentByID, getStudentContacts, getStudentProfile,
} from '../api/student';
import { getSelfComments } from '../api/user';
import { getStudents } from '@/domains/core/advisor/api/student';
import { CommentFromCA } from '@/domains/core/advisor/types';
import { getAllDocuments } from '../api/documents';
import { Document } from '@/domains/core/student/types';
import { Tile } from '@/domains/core/company/types';
import { getStudentApplications } from '../api/application';
import { getStudentComments } from '@/domains/core/advisor/api/comment';

export const useGetStudentsByCohort = (cohortID: string, options = {}) => useQuery(
  ['cohort', cohortID],
  async () => {
    const response = await getAllStudentsByCohortID(cohortID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetAllStudents = (universityID: string, options = {}) => useQuery(
  ['allStudents', universityID],
  async () => {
    const response = await getAllStudents(universityID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    enabled: !!universityID,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
    ...options,
  },
);

export const useGetStudentsByUniversity = (
  universityID: string,
  lastViewedAt: Date | null,
  options = {},
  companyID?: string,
) => {
  const memoizedLastViewedAt = useMemo(
    () => lastViewedAt?.toISOString(),
    [lastViewedAt],
  );

  return useQuery(
    ['allStudentsList', universityID, memoizedLastViewedAt, companyID],
    async () => {
      if (!universityID || !lastViewedAt) {
        return [];
      }

      const response = await getStudents(universityID, lastViewedAt, companyID);
      return response ?? [];
    },
    {
      enabled: !!universityID && !!lastViewedAt, // Prevent the query from running without valid inputs
      staleTime: 1000 * 60 * 60 * 8, // Cache stale for 8 hours
      retry: 1, // Optionally, retry the query once on failure
      ...options,
    },
  );
};

export const useGetAllStudentsResumes = (universityID: string, options = {}) => useQuery(
  ['allStudentsResume', universityID],
  async () => {
    const response = await getAllStudentsResumes(universityID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    enabled: !!universityID,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
    ...options,
  },
);

export const useAISummaryQuery = (studentID: string, options = {}) => useQuery(
  ['studentAISummary', studentID],
  async () => {
    const response = await getStudentAISummary(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetStudentContact = (studentID: string, options = {}) => useQuery(
  ['studentContacts', studentID],
  async () => {
    const response = await getStudentContacts(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetAllStudentResumesQueries = (studentID: string, options = {}) => useQuery(
  ['combinedResume', studentID],
  async () => {
    const response = await getCombinedResume(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetStudentProfileData = (studentID: string, options = {}) => useQuery(
  ['studentProfile', studentID],
  async () => {
    const response = await getStudentProfile(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetStudentsResumesByCohort = (cohortID: string, options = {}) => useQuery(
  ['cohort-resumes', cohortID],
  async () => {
    const response = await getAllStudentsResumesByCohortID(cohortID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetCohortsByUniversityID = (universityID: string, options = {}) => useQuery(
  ['universityCohorts', universityID], // Use an array with 'cohort' and the actual cohortID as the query key
  () => getCohortsByUniversityID(universityID), // Use an arrow function to call your actual function with the cohortID
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useStudentByID = (studentID: string, options = {}) => useQuery(
  ['studentData', studentID], // Use an array with 'cohort' and the actual cohortID as the query key
  () => getStudentByID(studentID), // Use an arrow function to call your actual function with the cohortID
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useStudentComments = (studentID: string | undefined, options = {}) => useQuery(
  ['studentComments', studentID],
  async () => {
    let commentsResponse: CommentFromCA[] = [];

    if (studentID) {
      commentsResponse = await getStudentComments(studentID);
    } else {
      commentsResponse = await getSelfComments();
    }

    return Array.isArray(commentsResponse) ? commentsResponse : [];
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);

export const useStudentDocuments = (studentID: string, options = {}) => useQuery(
  ['studentDocuments', studentID],
  async () => {
    const documentsResponse: Document[] = await getAllDocuments(studentID);

    return Array.isArray(documentsResponse) ? documentsResponse : [];
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);

export const useStudentApplications = (studentID: string, options = {}) => useQuery(
  ['studentApplications', studentID],
  async () => {
    const applicationsResponse: Tile[] = await getStudentApplications(studentID);

    return Array.isArray(applicationsResponse) ? applicationsResponse : [];
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);
