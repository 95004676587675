import { Button } from '@careeros/coco';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { DefaultLogo } from '@/components/default-logo/default-logo';
import { SearchBar } from '@/domains/generic/search';

import './topbar.scss';

type Props = {
  title: string | ReactElement;
  withSearch?: boolean;
  withProfile?: boolean;
  profileImage?: string;
  userName?: string;
  isLoggedIn?: boolean;
  handleLoginClick?: () => void;
  isTransparent?: boolean;
};

export const TopbarDumb = ({
  title,
  withSearch = false,
  withProfile = false,
  profileImage,
  userName,
  isLoggedIn = true,
  handleLoginClick,
  isTransparent,
}: Props) => (
  <div className={`topbar ${isTransparent ? 'topbar--transparent' : ''}`}>
    <div className="topbar__title">
      {title}
    </div>
    <div className="topbar__search">{withSearch && <SearchBar />}</div>
    <div className="topbar__menu">
      {/* <div className="topbar__menu__notifications">
          <img src={NotificationsIcon} alt="" />
        </div> */}
      {withProfile && (
        <div id="topbar-profile" className="topbar__profile">
          <NavLink to="profile">
            <DefaultLogo
              size={32}
              type="contact"
              source={profileImage || ''}
              className=""
              name={userName || 'U'}
              icon="bi bi-gear"
            />
            <i className="topbar__profile-settings bi bi-gear-fill" />
          </NavLink>
        </div>
      )}
      {!isLoggedIn && (
        <Button
          onClick={handleLoginClick}
          mode="primary"
          iconPosition="left"
          icon="bi bi-box-arrow-in-right"
          size="small"
          label="Login"
        />
      )}
    </div>
  </div>
);
