import { useNavigate } from 'react-router-dom';
import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import './dashboard-actions.scss';
import { IconButton } from '@careeros/coco';
import { ActionCard } from '@/components/action-card/action-card';
import { DasboardActionTileType, DashboardActionTile } from '../../types';
import { parseApiActionTiles } from './parse-data';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useScroll } from '@/services/hooks/use-scroll';
import { ActionCardSkeleton } from '../action-card-skeleton/action-card-skeleton';

type Props = {
  tiles: DashboardActionTile[];
  hiddenTileTypes?: DasboardActionTileType[];
  mostProgressedApplicationId: string | null;
  highlightResuorcesBox: () => void;
  firstName?: string;
  isLoading?: boolean;
};

export const DashboardActions = ({
  tiles,
  highlightResuorcesBox,
  mostProgressedApplicationId,
  hiddenTileTypes = [],
  firstName,
  isLoading = false,
}: Props) => {
  const [scrollContainerRef, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const parsedData = useMemo(() => parseApiActionTiles(tiles).filter(t => !hiddenTileTypes.includes(t.type)), [tiles, hiddenTileTypes]);
  const navigate = useNavigate();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { trackEvent } = useAnalytics();

  const handleActionClick = useCallback((actionType: DasboardActionTileType, name: string, order: number, link?: string) => {
    trackEvent('Dashboard Action Clicked', { actionType, name, order });

    if (link) {
      navigate(link);
      return;
    }

    switch (actionType) {
      case DasboardActionTileType.Resources: {
        highlightResuorcesBox();
        break;
      }
      case DasboardActionTileType.AITools: {
        const action = mostProgressedApplicationId
          ? () => openTracker(mostProgressedApplicationId, 'AI Tools')
          : () => navigate('/app/overview');
        action();
        break;
      }
      default: {
        break;
      }
    }
  }, [navigate, openTracker, mostProgressedApplicationId, highlightResuorcesBox]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = scrollContainerRef.current.scrollWidth;
    const columnsInViewWidth = scrollContainerRef.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  useEffect(() => {
    handleScroll({ currentTarget: scrollContainerRef.current } as React.UIEvent<HTMLDivElement, UIEvent>);
  }, [scrollContainerRef.current]);

  return (
    <div className="dashboard-actions">
      <div className="dashboard-actions__header">
        <h2 className="dashboard-actions__title">
          {`Hey${firstName ? ` ${firstName}` : ''}, what do you want to do today?`}
        </h2>
        {(showLeftScrollArrow || showRightScrollArrow) && !isLoading && (
          <div className="dashboard-actions__controls">
            <IconButton
              icon="bi bi-chevron-left"
              mode="rounded"
              size="medium"
              data-testid="button-left"
              outlined
              handleClick={() => handleVerticalScroll('left')}
              disabled={!showLeftScrollArrow}
            />
            <IconButton
              icon="bi bi-chevron-right"
              mode="rounded"
              size="medium"
              data-testid="button-right"
              outlined
              handleClick={() => handleVerticalScroll('right')}
              disabled={!showRightScrollArrow}
            />
          </div>
        )}
      </div>
      <div className="dashboard-actions__content-wrapper">
        <div
          className="dashboard-actions__content"
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          {isLoading && (
            <>
              <ActionCardSkeleton accentColor="primary30" />
              <ActionCardSkeleton accentColor="primary50" />
              <ActionCardSkeleton accentColor="warning-light" />
              <ActionCardSkeleton accentColor="error20" />
              <ActionCardSkeleton accentColor="information" />
              <ActionCardSkeleton accentColor="blue-green70" />
              <ActionCardSkeleton accentColor="secondary40" />
            </>
          )}
          {!isLoading && parsedData.map((tile, index) => (
            <ActionCard
              key={tile.title}
              title={tile.title}
              imageStack={tile.imageStack}
              backgroundIcons={tile.backgroundIcons}
              accentColor={tile.accentColor}
              handleClick={() => handleActionClick(tile.type, tile.title, index, tile.link)}
              isBlurredImageStack={tile.isBlurredImageStack}
              withImageStackHover={tile.withImageStackHover}
              withImageStackTooltip={tile.withImageStackTooltip}
            />
          ))}
        </div>
        <div
          onMouseEnter={() => handleVerticalScroll('left')}
          className="dashboard-actions__scroller dashboard-actions__scroller--left"
        />
        <div
          onMouseEnter={() => handleVerticalScroll('right')}
          className="dashboard-actions__scroller dashboard-actions__scroller--right"
        />
      </div>
    </div>
  );
};
