import { useState } from 'react';
import { Button, Tooltip } from '@careeros/coco';
import { DropdownList } from '@/components/dropdown-list/dropdown-list';

import './templates-button.scss';

type Props = {
  isIconButton?: boolean;
  showOutreachButton?: boolean;
  triggerOutreachTemplateButton?: () => void;
  triggerCustomTemplateButton: () => void;
};

export const TemplatesButton = ({
  showOutreachButton = false,
  triggerOutreachTemplateButton,
  triggerCustomTemplateButton,
  isIconButton = false,
}: Props) => {
  const [isListOpened, setIsListOpened] = useState(false);
  const handleDropdownButtonClick = () => {
    setIsListOpened(!isListOpened);
  };

  const handleCustomTemplateOptionClick = () => {
    triggerCustomTemplateButton();
    setIsListOpened(false);
  };

  const handleOutreachTemplateOptionClick = () => {
    if (triggerOutreachTemplateButton) {
      triggerOutreachTemplateButton();
    }
    setIsListOpened(false);
  };

  return (
    <div className="tempaltes-button" data-testid="templates-button">
      {isListOpened && (
        <div className="tempaltes-button__dropdown" data-testid="templates-button-dropdown">
          <DropdownList
            handleClose={() => setIsListOpened(false)}
            options={[
              {
                label: 'AI Generated First Outreach',
                onClick: handleOutreachTemplateOptionClick,
              },
              {
                label: 'Custom Template',
                onClick: handleCustomTemplateOptionClick,
              },
            ]}
          />
        </div>
      )}
      {showOutreachButton && (
        <Tooltip
          label="Change Template"
          disableHoverListener={!isIconButton || isListOpened}
          position="top"
        >
          <Button
            mode="blue"
            outlined
            label={isIconButton ? '' : 'Change Template'}
            handleClick={handleDropdownButtonClick}
            size="medium"
            icon={isIconButton ? 'bi bi-pencil-square' : 'bi bi-chevron-up'}
            iconPosition="right"
            data-testid="templates-button-outreach"
          />
        </Tooltip>
      )}

      {!showOutreachButton && (
        <Tooltip label="Custom Templates" disableHoverListener={!isIconButton} position="top">
          <Button
            mode="blue"
            outlined
            label={isIconButton ? '' : 'Custom Templates'}
            handleClick={triggerCustomTemplateButton}
            size="medium"
            icon="bi bi-tools"
            data-testid="templates-button-custom"
          />
        </Tooltip>
      )}
    </div>
  );
};
